import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Switch, Slider, Button, FormControlLabel, Box, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Mobilitaet from '../verbraucher/Mobilitaet';
import InfoButton from "../../GUI/InfoButton";

const ElektroFahrzeug = () => {
  const { setFahrzeugInfo, mobilitaetsprofilSafed } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [kapazitaet, setKapazitaet] = useState(50);
  const [verbrauch, setVerbrauch] = useState(0.2);
  const [fahrzeugVorhanden, setFahrzeugVorhanden] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFahrzeugInfo({
        kapazitaet: fahrzeugVorhanden ? parseFloat(kapazitaet) : 0,
        verbrauch: fahrzeugVorhanden ? parseFloat(verbrauch) : 0,
        fahrzeugVorhanden: fahrzeugVorhanden,
    });
  }, [kapazitaet, verbrauch, fahrzeugVorhanden]);

  const handleSwitchChange = (e) => {
    if(e.target.checked) {
        alert(
          "1. Konfigurieren Sie Ihr Fahrzeug\n" +
          "2. Erstellen Sie ein Mobilitätsprofil"
        );
    }
    setFahrzeugVorhanden(e.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (mobilitaetsprofilSafed) {
      setOpen(false);
    } else {
      alert('Bitte speichern Sie das Mobilitätsprofil, bevor Sie fortfahren.');
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
          Elektrofahrzeug
        </Typography>
        <FormControlLabel
          label="vorhanden"
          labelPlacement="start"
          control={
            <Switch
              checked={fahrzeugVorhanden}
              onChange={handleSwitchChange}
              color="thirdly"
            />
          }
        />
      </Box>
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: '80vh', height: '80vh', maxWidth: 'none', maxHeight: 'none', minWidth: '550px' } }}>
        <Mobilitaet />
      </Dialog>
      <>
      {fahrzeugVorhanden ?
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={fahrzeugVorhanden ? colors.grey[100] : colors.grey[500]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Kapazität
          </Typography>
          <InfoButton textKey="kapEV" />
        </Box>
        <Slider
          value={kapazitaet}
          min={0}
          max={100}
          step={1}
          disabled={!fahrzeugVorhanden}
          onChange={(e, newValue) => {
            setKapazitaet(newValue);
          }}
          style={{ color: fahrzeugVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kWh"
          type="number"
          value={kapazitaet}
          disabled={!fahrzeugVorhanden}
          onChange={(e) => {
            setKapazitaet(Number(e.target.value));
          }}
        />
        <br/>
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Verbrauch
          </Typography>
          <InfoButton textKey="verbrauchEV" />
        </Box>
        <Slider
          value={verbrauch}
          min={0}
          max={1}
          step={0.01}
          disabled={!fahrzeugVorhanden}
          onChange={(e, newValue) => {
            setVerbrauch(newValue);
          }}
          style={{ color: fahrzeugVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kWh/km"
          type="number"
          value={verbrauch}
          disabled={!fahrzeugVorhanden}
          onChange={(e) => {
            setVerbrauch(Number(e.target.value));
          }}
        />
        <br/>
        <Box>
          {fahrzeugVorhanden &&
            <Box display="flex" justifyContent="center">
              <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              }}
              onClick={handleOpen}>
                Mobilitätsprofil erstellen
              </Button>
            </Box>
          }
        </Box>
      </Box>
      : null}
      </>
    </Box>
  );
};

export default ElektroFahrzeug;
