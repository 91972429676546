import React from "react";
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { INFO_TEXTS } from '../global/library/infotexts';
import { tokens } from "../global/library/theme";

const InfoButton = ({ textKey }) => {
  const info = INFO_TEXTS[textKey];  // Abrufen des entsprechenden Info-Objekts
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Tooltip 
      title={
        <React.Fragment>
          <Typography variant="h5" fontWeight="600" component="div" color="white">
            {info.title}
          </Typography>
          {info.description.map((desc, index) => (
            <Typography key={index} variant="body1" component="div" color="white" sx={{mt: 1}}>
              {desc}
            </Typography>
          ))}
          <Typography variant="body2" component="div" color="white" fontStyle="italic" sx={{mt: 1}}>
              <Typography variant="body2" component="span" fontWeight="bold" color="white" fontStyle="italic">
                  Einfluss:
              </Typography>
              {" " + info.impact}
          </Typography>
          {info.cases.map((item, index) => (
            <Typography key={index} variant="body1" component="div" color="white" sx={{mt: 1}}>
                {item}
            </Typography>
          ))}
        </React.Fragment>
      }
    >
      <IconButton color="thirdly" size="small">
        <HelpIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default InfoButton;
