import React, { useContext, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
} from "@react-pdf/renderer";
import { AppContext } from "../tools/Kontext";
import { Button, Box, useTheme } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../library/theme";
import { useComponents, useKPIs } from "../library/berichtWerte";

const Bericht = () => {
  const context = useContext(AppContext); // Zugriff auf den Kontext
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log("Kotext:", context);

  // Definieren Sie eine Liste von Komponenten, die Sie in Ihrem Bericht anzeigen möchten
  const components = useComponents();
  const KPIs = useKPIs();

  const styles = StyleSheet.create({
    page: { padding: 30 },
    section: { margin: 10, padding: 8, flexGrow: 1 },
    block: { flexDirection: "row", marginBottom: 5 },
    column: { flex: 1, padding: 10 },
    labelColumn: { flex: 3 },
    refColumn: { flex: 2, backgroundColor: "#f0f0f0" },
    currentColumn: { flex: 2, backgroundColor: "#ddd" },
    unitColumn: { flex: 1, backgroundColor: "#f0f0f0" },
    prioColumn: { flex: 1 },
    title: { fontSize: 16, color: "#333", marginBottom: 18 },
    header: { fontSize: 14, marginBottom: 8 },
    text: { fontSize: 12, color: "#333", marginBottom: 9 },
    diffText: { fontSize: 14, color: "red", marginTop: 5 },
    alertColumn: { flex: 0.1 },
    alerttext: {
      fontSize: 12,
      marginBottom: 9,
      fontWeight: "extrabold",
      color: "red",
    },
    centerBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 5,
      margin: 8,
      backgroundColor: "#f0f0f0",
    },
    rowBox: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    h1: {
      fontSize: 28,
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
    },
    h4: {
      fontSize: 20,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 16,
      fontWeight: "bold",
    },
  });

  function getTextColor(currentValue, refValue, improvement) {
    if (currentValue - refValue > 0 && improvement === "+") {
      return "green";
    } else if (currentValue - refValue < 0 && improvement === "+") {
      return "red";
    } else if (currentValue - refValue < 0 && improvement === "-") {
      return "green";
    } else if (currentValue - refValue > 0 && improvement === "-") {
      return "red";
    } else {
      return "#333";
    }
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Eigenverbrauch-Box */}
        <View style={styles.centerBox}>
          <Text style={styles.h4}>Eigenverbrauch</Text>
          <View style={styles.rowBox}>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Referenz</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(
                  context?.differenzWerte?.eigenverbrauchReference * 100
                ).toFixed(1) + "%"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Differenz</Text>
              <Text
                style={{
                  ...styles.h5,
                  marginTop: 15,
                  color:
                    context?.differenzWerte?.eigenverbrauchDifferenz >= 0
                      ? "green"
                      : "red",
                }}
              >
                {context?.differenzWerte?.eigenverbrauchDifferenz >= 0
                  ? "+" +
                    (
                      context?.differenzWerte?.eigenverbrauchDifferenz * 100
                    ).toFixed(1) +
                    "%"
                  : (
                      context?.differenzWerte?.eigenverbrauchDifferenz * 100
                    ).toFixed(1) + "%"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Aktuell</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(context?.differenzWerte?.eigenverbrauch * 100).toFixed(1) +
                  "%" || "0%"}
              </Text>
            </View>
          </View>
        </View>

        {/* Autarkie Box */}
        <View style={styles.centerBox}>
          <Text style={styles.h4}>Autarkie</Text>
          <View style={styles.rowBox}>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Referenz</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(context?.differenzWerte?.autarkieReference * 100).toFixed(1) +
                  "%"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Differenz</Text>
              <Text
                style={{
                  ...styles.h5,
                  marginTop: 15,
                  color:
                    context?.differenzWerte?.autarkieDifferenz >= 0
                      ? "green"
                      : "red",
                }}
              >
                {context?.differenzWerte?.autarkieDifferenz >= 0
                  ? "+" +
                    (context?.differenzWerte?.autarkieDifferenz * 100).toFixed(
                      1
                    ) +
                    "%"
                  : (context?.differenzWerte?.autarkieDifferenz * 100).toFixed(
                      1
                    ) + "%"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Aktuell</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(context?.differenzWerte?.autarkie * 100).toFixed(1) + "%" ||
                  "0%"}
              </Text>
            </View>
          </View>
        </View>
        {/* Energiekosten Box */}
        <View style={styles.centerBox}>
          <Text style={styles.h4}>Energiekosten</Text>
          <View style={styles.rowBox}>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Referenz</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(
                  Number(context?.differenzWerte?.stromkostenSummeRef) +
                  Number(context?.differenzWerte?.brennstoffKostenRef)
                ).toLocaleString('de-CH') + " CHF" || "0 CHF"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Differenz</Text>
              <Text
                style={{
                  ...styles.h5,
                  marginTop: 15,
                  color:
                    Number(context?.differenzWerte?.stromkostenDiff) +
                      Number(
                        context?.differenzWerte?.brennstoffKostenDifferenz
                      ) >=
                    0
                      ? "red"
                      : "green",
                }}
              >
                {Number(context?.differenzWerte?.stromkostenDiff) +
                  Number(context?.differenzWerte?.brennstoffKostenDifferenz) >=
                0
                  ? "+" +
                    ((Number(context?.differenzWerte?.stromkostenDiff) +
                      Number(
                        context?.differenzWerte?.brennstoffKostenDifferenz
                      ))).toLocaleString('de-CH') +
                    " CHF"
                  : (Number(context?.differenzWerte?.stromkostenDiff) +
                    Number(context?.differenzWerte?.brennstoffKostenDifferenz)).toLocaleString('de-CH') +
                    " CHF"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Aktuell</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(Number(context?.differenzWerte?.stromkostenSumme) +
                  Number(context?.differenzWerte?.brennstoffKosten)).toLocaleString('de-CH') +
                  " CHF" || "0 CHF"}
              </Text>
            </View>
          </View>
        </View>
        {/* CO2-Bilanz Box */}
        <View style={styles.centerBox}>
          <Text style={styles.h4}>CO2-Bilanz</Text>
          <View style={styles.rowBox}>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Referenz</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(Number(context?.differenzWerte?.CO2Ref)).toLocaleString('de-CH') + " kg"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Differenz</Text>
              <Text
                style={{
                  ...styles.h5,
                  marginTop: 15,
                  color:
                    context?.differenzWerte?.CO2Differenz >= 0
                      ? "red"
                      : "green",
                }}
              >
                {context?.differenzWerte?.CO2Differenz >= 0
                  ? "+" + (Number(context?.differenzWerte?.CO2Differenz)).toLocaleString('de-CH') + " kg"
                  : (Number(context?.differenzWerte?.CO2Differenz)).toLocaleString('de-CH') + " kg"}
              </Text>
            </View>
            <View style={styles.centerBox}>
              <Text style={styles.h5}>Aktuell</Text>
              <Text style={{ ...styles.h5, marginTop: 10 }}>
                {(Number(context?.differenzWerte?.CO2)).toLocaleString('de-CH') + " kg" || "0 CHF"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {/* KPIs */}
        <Text style={styles.h1}>Key permormance indicators (KPIs)</Text>
        {KPIs.map((KPI, index) => (
          <View style={styles.section} key={index}>
            {/* <Text style={styles.title}>{KPI.title}</Text> */}
            <View style={styles.block}>
              <View style={styles.labelColumn}>
                <Text style={styles.header}>Bezeichnung</Text>
                {KPI.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.label}
                  </Text>
                ))}
              </View>

              <View style={styles.refColumn}>
                <Text style={styles.header}>Referenz</Text>
                {KPI.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                  {field.refValue !== null && field.refValue !== undefined
                    ? Number(field.refValue).toLocaleString('de-CH')
                    : "-"}
                </Text>
                ))}
              </View>

              <View style={styles.currentColumn}>
                <Text style={styles.header}>Aktuell</Text>
                {KPI.fields.map((field, index) => (
                  <Text
                    style={{
                      ...styles.text,
                      color: getTextColor(
                        field.currentValue,
                        field.refValue,
                        field.improvement
                      ),
                    }}
                    key={index}
                  >
                    {field.currentValue !== null && field.currentValue !== undefined
                    ? Number(field.currentValue).toLocaleString('de-CH')
                    : "-"}
                  </Text>
                ))}
              </View>

              <View style={styles.unitColumn}>
                <Text style={styles.header}>Einheit</Text>
                {KPI.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.unit}
                  </Text>
                ))}
              </View>
            </View>

            {KPI.differenzWert && (
              <Text style={styles.diffText}>
                Differenzwert: {KPI.differenzWert}
              </Text>
            )}
          </View>
        ))}
      </Page>
      <Page size="A4" style={styles.page}>
        {/* Restlicher Bericht */}
        <Text style={styles.h1}>Systemkonfiguration</Text>
              
              
        {components.map((component, index) => (
          <View style={styles.section} key={index}>
            <View style={styles.block}>
              <View style={styles.labelColumn}>
                <Text style={styles.header}>{component.title}</Text>
                {component.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.label}
                  </Text>
                ))}
              </View>

              <View style={styles.refColumn}>
                <Text style={styles.header}>Referenz</Text>
                {component.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.refValue !== null && field.refValue !== undefined
                      ? field.refValue.toString()
                      : "-"}
                  </Text>
                ))}
              </View>

              <View style={styles.currentColumn}>
                <Text style={styles.header}>Aktuell</Text>
                {component.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.currentValue !== null &&
                    field.currentValue !== undefined
                      ? field.currentValue.toString()
                      : "-"}
                  </Text>
                ))}
              </View>

              <View style={styles.unitColumn}>
                <Text style={styles.header}>Einheit</Text>
                {component.fields.map((field, index) => (
                  <Text style={styles.text} key={index}>
                    {field.unit}
                  </Text>
                ))}
              </View>

              <View style={styles.alertColumn}>
                <Text style={styles.header}> </Text>
                {component.fields.map((field, index) => (
                  <Text style={styles.alerttext} key={index}>
                    {field.refValue !== field.currentValue &&
                    field.refValue !== null &&
                    field.currentValue !== null ? (
                      <Text>
                        <Text> </Text>!
                      </Text>
                    ) : (
                      <Text> </Text>
                    )}
                  </Text>
                ))}
              </View>
            </View>

            {/* {component.differenzWert && (
                <Text style={styles.diffText}>
                  Differenzwert: {component.differenzWert}
                </Text>
              )} */}
          </View>
        ))}
        {/* Prioritäten */}
        <View style={styles.section}>
          <View style={styles.block}>
            <View style={styles.prioColumn}>
              <Text style={styles.header}>Prioritäten</Text>
              {context?.referenceKomponentenInfo?.verbraucherPrioritaeten?.map((_, index) => (
                <Text style={styles.text} key={index}>
                  {index+1}
                </Text>
              ))}
            </View>
              
            <View style={styles.refColumn}>
              <Text style={styles.header}>Referenz</Text>
              {context?.referenceKomponentenInfo?.verbraucherPrioritaeten?.map((verbraucher, index) => (
                <Text style={styles.text} key={index}>
                  {verbraucher.name !== null && verbraucher.name !== undefined
                    ? verbraucher.name.toString()
                    : "-"}
                </Text>
              ))}
            </View>
            <View style={styles.currentColumn}>       
              <Text style={styles.header}>Aktuell</Text>
              {context?.verbraucherPrioritaeten?.map((verbraucher, index) => (
                <Text style={styles.text} key={index}>
                  {verbraucher.name !== null && verbraucher.name !== undefined
                    ? verbraucher.name.toString()
                    : "-"}
                </Text>
              ))}
            </View>
            <View style={styles.alertColumn}>
              <Text style={styles.header}> </Text>
              {context?.referenceKomponentenInfo?.verbraucherPrioritaeten?.map((refVerbraucher, index) => {
                const currentVerbraucher = context?.verbraucherPrioritaeten[index];
                
                return (
                  <Text style={styles.alerttext} key={index}>
                    {currentVerbraucher?.name !== refVerbraucher.name &&
                    refVerbraucher.name !== null &&
                    currentVerbraucher?.name !== null ? (
                      <Text>
                        <Text> </Text>!
                      </Text>
                    ) : (
                      <Text> </Text>
                    )}
                  </Text>
                );
              })}
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <Text style={styles.h1}>Mobilitätsprofil</Text>
        {/* Events Referenz */}
        <Text style={styles.h4}>Referenz Einträge</Text>
        <View style={styles.section}>
          <View style={styles.block}>
            <View style={styles.prioColumn}>
              <Text style={styles.header}>Ereignis</Text>
              {context?.referenceKomponentenInfo?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((_, index) => (
                <Text style={styles.text} key={index}>
                  {index+1}
                </Text>
              ))}
            </View>

            <View style={styles.refColumn}>       
              <Text style={styles.header}>Startzeit</Text>
              {context?.referenceKomponentenInfo?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => {
                const startDate = new Date(event.start);
                const day = startDate.toLocaleDateString('de-DE', { weekday: 'short' });
                const time = startDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

                return (
                  <Text style={styles.text} key={index}>
                    {event.start !== null && event.start !== undefined
                      ? `${day}, ${time}`
                      : "-"}
                  </Text>
                )
              })}
            </View>

            <View style={styles.currentColumn}>       
              <Text style={styles.header}>Endzeit</Text>
              {context?.referenceKomponentenInfo?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => {
                const endDate = new Date(event.end);
                const day = endDate.toLocaleDateString('de-DE', { weekday: 'short' });
                const time = endDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

                return (
                  <Text style={styles.text} key={index}>
                    {event.end !== null && event.end !== undefined
                      ? `${day}, ${time}`
                      : "-"}
                  </Text>
                )
              })}
            </View>

            <View style={styles.unitColumn}>       
              <Text style={styles.header}>Distanz</Text>
              {context?.referenceKomponentenInfo?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => (
                <Text style={styles.text} key={index}>
                  {event.distance !== null && event.distance !== undefined
                    ? event.distance.toString() + " km"
                    : "-"}
                </Text>
              ))}
            </View>
          </View>
        </View>
        {/* Events Aktuell */}
        <Text style={styles.h4}>Aktuelle Einträge</Text>
        <View style={styles.section}>
          <View style={styles.block}>
            <View style={styles.prioColumn}>
              <Text style={styles.header}>Ereignis</Text>
              {context?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((_, index) => (
                <Text style={styles.text} key={index}>
                  {index+1}
                </Text>
              ))}
            </View>

            <View style={styles.refColumn}>       
              <Text style={styles.header}>Startzeit</Text>
              {context?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => {
                const startDate = new Date(event.start);
                const day = startDate.toLocaleDateString('de-DE', { weekday: 'short' });
                const time = startDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

                return (
                  <Text style={styles.text} key={index}>
                    {event.start !== null && event.start !== undefined
                      ? `${day}, ${time}`
                      : "-"}
                  </Text>
                )
              })}
            </View>

            <View style={styles.currentColumn}>       
              <Text style={styles.header}>Endzeit</Text>
              {context?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => {
                const endDate = new Date(event.end);
                const day = endDate.toLocaleDateString('de-DE', { weekday: 'short' });
                const time = endDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

                return (
                  <Text style={styles.text} key={index}>
                    {event.end !== null && event.end !== undefined
                      ? `${day}, ${time}`
                      : "-"}
                  </Text>
                )
              })}
            </View>

            <View style={styles.unitColumn}>       
              <Text style={styles.header}>Distanz</Text>
              {context?.events?.slice().sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => (
                <Text style={styles.text} key={index}>
                  {event.distance !== null && event.distance !== undefined
                    ? event.distance.toString() + " km"
                    : "-"}
                </Text>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const downloadPDF = async () => {
    const blob = await pdf(MyDoc).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "bericht.pdf";
    link.click();

    // Der Link wird nicht benötigt, sobald das Herunterladen abgeschlossen ist
    URL.revokeObjectURL(url);
  };

  return (
    <Box>
      <Button
        onClick={downloadPDF}
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
        Bericht erstellen
      </Button>
    </Box>
  );
};

export default Bericht;
