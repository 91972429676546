import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { tokens } from "../../global/library/theme";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { AppContext } from '../../global/tools/Kontext';

const Mobilitaet = () => {
  const { mobilitaetsprofil, setMobilitaetsprofil, setMobilitaetsprofilSafed, parsedCsv, fahrzeugInfo, events, setEvents } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [year, setYear] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [mobilitaetsprofilGespeichert, setMobilitaetsprofilGespeichert] = useState(false);
  const Zeitintervall = parsedCsv.length / 8760;
  console.log(mobilitaetsprofil);


  useEffect(() => {
    if (parsedCsv && parsedCsv.length > 0) {
      const yearFound = moment(parsedCsv.find(row => row['Datum']).Datum, "YYYY-MM-DD").year();
      setYear(yearFound);
      // Beim Öffnen des Dialogs, laden Sie die Ereignisse aus dem Kontext
      loadEventsFromContext();
    }
  }, [parsedCsv]);
  
  const loadEventsFromContext = () => {
    // Laden Sie die Ereignisse aus dem Kontext
    setEvents(events);
  };

  const onSave = async () => {
    setIsCalculating(true);
    await erstelleMobilitaetsprofil();
    setIsCalculating(false);
    setMobilitaetsprofilGespeichert(true);
    setMobilitaetsprofilSafed(true);
    // Wenn das Profil gespeichert wird, speichern Sie auch die Ereignisse im Kontext
    saveEventsToContext();
  };
  
  const saveEventsToContext = () => {
    // Speichern Sie die Ereignisse im Kontext
    setEvents(events);
  };
  
  const erstelleMobilitaetsprofil = () => {
    return new Promise((resolve) => {
      setTimeout(() => {

    let dayOfWeek;
    let bedarfLadestand;
    let nextDayDrivingRequirement;
    
    if (parsedCsv && parsedCsv.length > 0) {
      dayOfWeek = moment(parsedCsv[0]['Datum'], "YYYY-MM-DD").day();
    }
  
    const calculateDrivingRequirement = (dayOfWeek) => {
      let day = dayOfWeek;
      let nextEvent = events.filter(event => moment(event.start).day() === day);
      let drivingRequirement = nextEvent.reduce((totalDistance, event) => totalDistance + event.distance, 0);
      return drivingRequirement;
    }
  
    const calculateNextDayDrivingRequirement = (currentDayOfWeek) => {
        let nextDay = currentDayOfWeek < 6 ? currentDayOfWeek + 1 : 0;
        let nextDayEvents = events.filter(event => moment(event.start).day() === nextDay);
        let nextDayDrivingRequirement = nextDayEvents.reduce((totalDistance, event) => totalDistance + event.distance, 0);
        return nextDayDrivingRequirement;
    }
    const getDaysBetweenDates = (start, end) => {
        let dates = [];
        const startDate = moment(start).startOf('day');
        const endDate = moment(end).startOf('day');

        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
            dates.push(moment(startDate));
            startDate.add(1, 'days');
        }

        return dates;
    };

    const getTimeUntilNextEvent = (currentDayOfWeek, currentHour, currentMinute) => {
      let daysChecked = 0;
      let closestEvent = null;
      let closestEventTime = 60 * 24 * 7;
  
      while (daysChecked < 7) {
          const dayToCheck = (currentDayOfWeek + daysChecked) % 7;
          const eventsOnDayToCheck = events.filter(event => new Date(event.start).getDay() === dayToCheck);
  
          if (eventsOnDayToCheck.length > 0) {
              const sortedEvents = eventsOnDayToCheck.sort((a, b) => new Date(a.start) - new Date(b.start));
              const firstEventOnDay = sortedEvents[0];
  
              let eventTimeInMinutes = daysChecked * 24 * 60;
  
              const eventHour = new Date(firstEventOnDay.start).getHours();
              const eventMinute = new Date(firstEventOnDay.start).getMinutes();
  
              eventTimeInMinutes += (eventHour - currentHour) * 60 + (eventMinute - currentMinute);
  
              // If event time is negative, means event is in the past, so add one week
              if (eventTimeInMinutes < 0) {
                  eventTimeInMinutes += 7 * 24 * 60;
              }
  
              if (eventTimeInMinutes < closestEventTime) {
                  closestEvent = firstEventOnDay;
                  closestEventTime = eventTimeInMinutes;
              }
          }
  
          daysChecked++;
      }
  
      return closestEventTime;
  };

    const tempMobilitaetsprofil = new Array(parsedCsv.length);

    for (let i = 0; i < parsedCsv.length; i++) {
      const Time = parsedCsv[i]['Zeit'];
      const timeParts = Time.split(':');
      const stunde = parseInt(timeParts[0]);
      const minute = parseInt(timeParts[1]);
      const Datum = parsedCsv[i]['Datum'];
      const currentDayOfWeek = moment(Datum, "YYYY-MM-DD").day();
      const currentHour = stunde;
      const currentMinute = minute;

      if (currentDayOfWeek !== dayOfWeek) {                  
          nextDayDrivingRequirement = calculateNextDayDrivingRequirement(currentDayOfWeek);
          dayOfWeek = currentDayOfWeek;
          bedarfLadestand = calculateDrivingRequirement(dayOfWeek);
      }


      let isEvent = false;
      
      for (let event of events) {
        const eventStart = moment(event.start);
        const eventEnd = moment(event.end);
        const eventDays = getDaysBetweenDates(eventStart, eventEnd);

        for (let eventDay of eventDays) {
          const eventDayOfWeek = eventDay.day();
          const isStartDay = eventDay.isSame(eventStart, 'day');
          const isEndDay = eventDay.isSame(eventEnd, 'day');
          const eventBeginHour = isStartDay ? eventStart.hour() : 0;
          const eventBeginMinute = isStartDay ? eventStart.minute() : 0;
          const eventEndHour = isEndDay ? eventEnd.hour() : 23;
          const eventEndMinute = isEndDay ? eventEnd.minute() : 59;
          const eventDuration = eventEnd.diff(eventStart, 'hours', true);
          const fahrleistung = (event.distance / eventDuration) / Zeitintervall;
          const verbrauch = fahrzeugInfo.verbrauch * fahrleistung;

          if (currentDayOfWeek === eventDayOfWeek && 
            (currentHour > eventBeginHour || (currentHour === eventBeginHour && currentMinute >= eventBeginMinute)) && 
            (currentHour < eventEndHour || (currentHour === eventEndHour && currentMinute <= eventEndMinute))) {

            bedarfLadestand = nextDayDrivingRequirement;
            
            const timeUntilNextEvent = getTimeUntilNextEvent(currentDayOfWeek, currentHour, currentMinute);

            tempMobilitaetsprofil[i] = {
                anLadestation: false,
                fahrleistung: fahrleistung,
                verbrauch: verbrauch,
                bedarfLadestand: bedarfLadestand * fahrzeugInfo.verbrauch,
                wochentag: currentDayOfWeek,
                zielLadestand: nextDayDrivingRequirement * fahrzeugInfo.verbrauch,
                zeit:currentHour,
                zeitBisNaechstesEreignis: timeUntilNextEvent
            };
            isEvent = true;
          } 

          if (!isEvent) {
            const timeUntilNextEvent = getTimeUntilNextEvent(currentDayOfWeek, currentHour, currentMinute);

            tempMobilitaetsprofil[i] = {
                anLadestation: true,
                fahrleistung: 0,
                verbrauch: 0,
                bedarfLadestand: bedarfLadestand * fahrzeugInfo.verbrauch,
                wochentag: currentDayOfWeek,
                zielLadestand: nextDayDrivingRequirement * fahrzeugInfo.verbrauch,
                zeit:currentHour,
                zeitBisNaechstesEreignis: timeUntilNextEvent
            };
          }
        }
      } 
    }

    setMobilitaetsprofil(tempMobilitaetsprofil);
        resolve();
      }, 100); // Verzögerung von 100 Millisekunden (0.1 Sekunden)
    });
  };




  const handleSelect = (info) => {
    const distance = prompt("Bitte geben Sie die gefahrene Strecke ein:", "0");
    if (distance != null && !isNaN(distance)) {
      const newEvent = {
        id: `${info.startStr}-${info.endStr}`,
        start: info.startStr,
        end: info.endStr,
        title: 'Nicht an der Ladestation - Strecke: ' + distance + ' km',
        distance: Number(distance),
        allDay: false
      };
      const overlap = events.some(event => 
        (moment(newEvent.start).isSameOrBefore(moment(event.end)) && moment(newEvent.end).isSameOrAfter(moment(event.start)))
      );
      if (!overlap) {
        setEvents(currentEvents => [...currentEvents, newEvent]);
      } else {
        alert("Zeitüberschneidung ist nicht erlaubt!");
      }
    }
  };

  const handleEventChange = (changeInfo) => {
    const originalEvent = events.find(event => event.id === changeInfo.event.id);
    const updatedEvent = {
      id: changeInfo.event.id,
      start: changeInfo.event.start.toISOString(),
      end: changeInfo.event.end.toISOString(),
      title: changeInfo.event.title,
      distance: originalEvent.distance,  // use distance from original event
      allDay: false
    };
  
    const overlap = events.some((event) =>
      event.id !== updatedEvent.id &&
      (moment(updatedEvent.start).isSameOrBefore(moment(event.end)) && moment(updatedEvent.end).isSameOrAfter(moment(event.start)))
    );
  
    if (!overlap) {
      setEvents((currentEvents) =>
        currentEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
      );
    } else {
      alert("Zeitüberschneidung ist nicht erlaubt!");
      // Stellen Sie sicher, dass das geänderte Ereignis nicht gespeichert wird
      changeInfo.revert();
    }
  };

  const handleEventClick = (info) => {
    const confirm = window.confirm("Möchten Sie diesen Eintrag löschen?");
    if (confirm) {
      setEvents(newEvents => newEvents.filter(e => e.id !== info.event.id));
      info.event.remove();
    }
  };

  const midYearDate = `${year}-06-15`;

  useEffect(() => {
    setMobilitaetsprofilGespeichert(false);
    setMobilitaetsprofilSafed(false);
  }, [parsedCsv, events]);

  return (
    <div>
      <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" p="50px" width="100%">
      <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        Mobilitätsprofil
      </Typography>
      <Typography color={colors.grey[100]} variant="h6">
        Bitte tragen Sie die Abwesenheiten und Fahrleistungen in km ein (Kalendereintrag):
      </Typography>
      <div>
      <LoadingButton
        disabled={mobilitaetsprofilGespeichert}
            sx={{
              backgroundColor: mobilitaetsprofilGespeichert ? colors.grey[500] : colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              margin: '10px'
            }}
        loading={isCalculating}
        onClick={onSave}
        variant='outlined'
      >
        {mobilitaetsprofilGespeichert ? 'Mobilitätsprofil gespeichert' : 'Mobilitätsprofil speichern'}
      </LoadingButton>
      {isCalculating && <p>Mobilitätsprofil wird erstellt...</p>}
    </div>
      <br />
        {year && (
          <FullCalendar 
            plugins={[timeGridPlugin, interactionPlugin]}
            contentHeight= "auto"
            locale={"locale"}
            initialDate={midYearDate}
            firstDay={1}
            initialView='timeGridWeek'
            dayHeaderFormat={{
              weekday: 'long'
            }}
            headerToolbar={false}
            allDaySlot={false}
            nowIndicator={true}
            events={events}
            editable={true}
            selectable={true}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            
            select={handleSelect}
            eventClick={handleEventClick}
            eventChange={handleEventChange}
            slotMinTime={'00:00:00'}
            slotMaxTime={'24:00:00'}
            validRange={{
              start: `${year}-01-01`,
              end: `${year}-12-31`
            }}
            weekNumbers={false}
            dayMaxEvents={true}
          />
        )}
      </Box>
    </div>
  );
};

export default Mobilitaet;