import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../global/tools/Kontext";

const SteuerungHeizstab = () => {
  const {
    setBerechneHeizstabLeistung,
    parsedCsv,
    heizstabInfo,
    pufferspeicherInfo,
    brauchwarmwasserInfo,
    gebaeudeInfo,
  } = useContext(AppContext);

  const Zeitintervall = parsedCsv.length / 8760;
  const spezSpeicherKapWasser = 0.001163889; // kWh/(kg*K)
  
  const maxLeistungHS = heizstabInfo.leistung / Zeitintervall;
  const volumenBWW = brauchwarmwasserInfo.volumen;
  const legionellenSchutz = brauchwarmwasserInfo.legionellenSchutz;
  const legionellenSchutzBWWKap = volumenBWW * spezSpeicherKapWasser * (273 + 61);
  const schwellenwertBWW = 10; //Wert unter min Temp bevor HS einschaltet

  // Zustand für den Legionellenschutz
  let legionellenSchutzAktiv = false;

  // Zustand für die letzten 24 Stunden der BWW-Temperaturen
  const temperaturAufzeichnung = useRef([]);


  function berechneHeizstabLeistung(dependencies) {
    const Time = dependencies['Zeit'];
    const timeParts = Time.split(":");
    const stunde = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    const bedarfBWW = parseFloat(dependencies["Wärmebedarf Brauchwarmwasser"]);
    const waermeVonWP = parseFloat(dependencies["WP Wärme für Brauchwarmwasser"]);
    const istBWWKap = parseFloat(dependencies["Kapazität Brauchwarmwasser"]);
    const minBWWTemp = parseFloat(dependencies["Minimale Warmwassertemperatur"]);
    const istBWWTemp = istBWWKap / (volumenBWW * spezSpeicherKapWasser) - 273;
    const bedarfHS = bedarfBWW - waermeVonWP;

    let waermeBWW = 0;

    if (istBWWTemp >= 60) {
      // Wenn 60°C erreicht sind, Legionellenschutz deaktivieren
      waermeBWW = 0;
      legionellenSchutzAktiv = false;
    }

    // Aktuelle BWW-Temperatur zur Liste hinzufügen
    temperaturAufzeichnung.current.push(istBWWTemp);

    if (stunde === 3 && minute === 0 && legionellenSchutz) {
      //console.log("3 Uhr morgens, Überprüfung des Legionellenschutzes");
      const eintraegeProStunde = Zeitintervall;
      const maxTempInLetzten23h = Math.max(...temperaturAufzeichnung.current.slice(eintraegeProStunde * 4));
      //console.log("temperaturAufzeichnung:", temperaturAufzeichnung);
      //console.log("maxTempInLetzten23h:", maxTempInLetzten23h);
    
      if (maxTempInLetzten23h < 60) {
        //console.log("Aktiviere Legionellenschutz");
        legionellenSchutzAktiv = true;
        //console.log("legionellenSchutzAktiv:", legionellenSchutzAktiv);
      } else {
        //console.log("Legionellenschutz nicht notwendig");
      }

      // Temperaturarray leeren
      temperaturAufzeichnung.current = [];
    }

    if (legionellenSchutzAktiv && legionellenSchutz) {
      // Wenn der Legionellenschutz aktiv ist, weiter heizen, bis 60°C erreicht sind
      waermeBWW = legionellenSchutzBWWKap - istBWWKap;
    } else if (bedarfHS > 0 && istBWWTemp < (minBWWTemp - schwellenwertBWW)) {
      waermeBWW = bedarfHS;
      }

    if (!legionellenSchutz) {
      // Temperaturarray leeren
      temperaturAufzeichnung.current = [];
    }

    let waermeBWW_eff = Math.min(waermeBWW, maxLeistungHS)
    let elektrischeLeistung = waermeBWW_eff;

    return { elektrischeLeistung, waermeBWW: waermeBWW_eff };
  }

  useEffect(() => {
    setBerechneHeizstabLeistung(() => berechneHeizstabLeistung);
  }, [
    parsedCsv,
    heizstabInfo,
    pufferspeicherInfo,
    brauchwarmwasserInfo,
    gebaeudeInfo,
  ]);

  return null;
};

export default SteuerungHeizstab;







/* console.log("stunde:", stunde);
console.log("minute:", minute);
console.log("istBWWTemp:", istBWWTemp);
console.log("legionellenSchutz:", legionellenSchutz);
console.log("legionellenSchutzAktiv:", legionellenSchutzAktiv);
console.log("elektrischeLeistung:", elektrischeLeistung); */