import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const Wasserverbrauch = () => {
  const { setBerechneWasserverbrauch, gebaeudeInfo, parsedCsv } = useContext(AppContext);
  const Zeitintervall = parsedCsv.length / 8760;
    
  const anzahlPersonen = gebaeudeInfo.personen;
  const wasserverbrauchProPerson = gebaeudeInfo.wasserverbrauchProPerson;

  function berechneWasserverbrauch(dependencies) {
        const morgenStunden = [6, 7, 8, 9];
        const abendStunden = [18, 19, 20, 21];
        const tagesStunden = [12, 13];
    
        const Time = dependencies['Zeit'];
        const timeParts = Time.split(':');
        const stunde = parseInt(timeParts[0]);
        const minute = parseInt(timeParts[1]);
    
        let verbrauch = 0;
    
        const morgenAnteil = 0.35;
        const abendAnteil = 0.35;
        const tagAnteil = 0.30;
    
        const duschsequenzDauer = 10;
        const duschAnteil = 0.30;
    
        for (let i = 0; i < anzahlPersonen; i++) {
          const duschsequenzStart = duschsequenzDauer * i;
          const duschStunde = (i % 2 === 0) ? morgenStunden[i / 2] : abendStunden[(i - 1) / 2];
    
          const verbrauchOhneDuschenProPerson = wasserverbrauchProPerson * (1 - duschAnteil);
          const verbrauchMorgenProPerson = verbrauchOhneDuschenProPerson * morgenAnteil / morgenStunden.length;
          const verbrauchAbendProPerson = verbrauchOhneDuschenProPerson * abendAnteil / abendStunden.length;
          const verbrauchTagProPerson = verbrauchOhneDuschenProPerson * tagAnteil / tagesStunden.length;
    
          if (stunde === duschStunde) {
            const duschsequenzEnde = duschsequenzStart + duschsequenzDauer;
            const duschVerbrauchProPerson = wasserverbrauchProPerson * duschAnteil;
            if (minute >= duschsequenzStart && minute < duschsequenzEnde) {
              verbrauch += duschVerbrauchProPerson / (duschsequenzEnde - duschsequenzStart) * (60 / Zeitintervall);
            } else {
              verbrauch += (morgenStunden.includes(stunde) ? verbrauchMorgenProPerson : verbrauchAbendProPerson) / Zeitintervall;
            }
          } else if (morgenStunden.includes(stunde)) {
            verbrauch += verbrauchMorgenProPerson / Zeitintervall;
          } else if (tagesStunden.includes(stunde)) {
            verbrauch += verbrauchTagProPerson / Zeitintervall;
          } else if (abendStunden.includes(stunde)) {
            verbrauch += verbrauchAbendProPerson / Zeitintervall;
          }
        }
        return verbrauch;
  }

  useEffect(() => {
    setBerechneWasserverbrauch(() => berechneWasserverbrauch);
  }, [gebaeudeInfo, setBerechneWasserverbrauch, parsedCsv]);

  return null;
};

export default Wasserverbrauch;