import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Switch, Slider, FormControlLabel, Box, Typography, useTheme } from '@mui/material';
import InfoButton from "../../GUI/InfoButton";

const Brauchwarmwasser = () => {
  const { setBrauchwarmwasserInfo, gebaeudeInfo, waermepumpeInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [volumen, setVolumen] = useState(300);
  const [legionellenSchutz, setLegionellenSchutz] = useState(false);
  const [maxTemperatur, setMaxTemperatur] = useState(65);
  const [waermepumpeBereitgestellt, setWaermepumpeBereitgestellt] = useState(false);
  /* const [personen, setPersonen] = useState(4); */
  const uWert = 0.0002;
  const minTemperatur = 60;
  const grundwasserTemperatur = 10;
  /* const wasserverbrauchProPerson = 50; */

  const oberflaeche = (volumen) => {
    const V_m3 = volumen * 0.001; // Umrechnung von Litern in Kubikmeter
    const s = Math.pow(V_m3, 1 / 3);
    const A_wuerfel = 6 * Math.pow(s, 2);

    const r = Math.pow((3 * V_m3) / (4 * Math.PI), 1 / 3);
    const A_kugel = 4 * Math.PI * Math.pow(r, 2);

    const A_mittel = (A_wuerfel + A_kugel) / 2;
    return A_mittel;
  };

  const speicherVerlust = uWert * oberflaeche(volumen);

  useEffect(() => {
    setBrauchwarmwasserInfo({
      volumen: parseFloat(volumen),
      legionellenSchutz: legionellenSchutz,
      maxTemperatur: parseFloat(maxTemperatur),
      minTemperatur: parseFloat(minTemperatur),
      waermepumpeBereitgestellt: gebaeudeInfo.heizungTyp === 'Waermepumpe' ? true : waermepumpeBereitgestellt,
      grundwasserTemperatur: grundwasserTemperatur,
      speicherVerlust: speicherVerlust,
      /* personen: personen, */
      /* wasserverbrauchProPerson: wasserverbrauchProPerson, */
    });
  }, [volumen, legionellenSchutz, maxTemperatur, waermepumpeBereitgestellt, /* personen */]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
          Brauchwarmwasser
        </Typography>
        {gebaeudeInfo.heizungTyp !== 'Waermepumpe' ?
          <>
          <FormControlLabel
            label="Wärmepumpe für Warmwasser"
            labelPlacement="start"
            control={
              <Switch
                checked={waermepumpeBereitgestellt}
                onChange={(e) => setWaermepumpeBereitgestellt(e.target.checked)}
                color="thirdly"
              />
            }
          />
          </>
        : null
        }
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Volumen
          </Typography>
          <InfoButton textKey="volumenBWW" />
        </Box>
        <Slider
          value={volumen}
          min={10}
          max={5000}
          step={10}
          onChange={(e, newValue) => {
            setVolumen(newValue);
          }}
          style={{ color: 'blue' }} 
        />
        <TextField
          size="small"
          type="number"
          label="Liter"
          value={volumen}
          onChange={(e) => {
            setVolumen(Number(e.target.value));
          }}
        /> 
        <br />
        <Box display="flex" justifyContent="flex-end" width="100%" gap="5px">
          <FormControlLabel
            label="Legionellenschutz"
            labelPlacement="start"
            control={
              <Switch
                color="thirdly"
                size="small"
                checked={legionellenSchutz}
                onChange={(e) => {
                  setLegionellenSchutz(e.target.checked);
                }}
              />
            }
          />
          <InfoButton textKey="legionellenSchutz" />
        </Box>
      </Box>
    </Box>
  );
};

export default Brauchwarmwasser;