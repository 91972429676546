import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const Mobilitaetsprofil = () => {
  const { setBerechneMobilitaetsProfil, parsedCsv, mobilitaetsprofil, events } = useContext(AppContext);
  console.log(mobilitaetsprofil);



  const berechneMobilitaetsProfil = (datum, zeit, counter) => {
    if (counter < mobilitaetsprofil.length && events.length > 0) {
      let result = mobilitaetsprofil[counter];
      return result;
    } else {
        return { 
          verbrauch: 0,
          anLadestation: true,
          fahrleistung: 0,
          bedarfLadestand: 0,
          zeitBisNaechstesEreignis: 0 
        };
      }
  };

  useEffect(() => {
    setBerechneMobilitaetsProfil(() => berechneMobilitaetsProfil);
  }, [parsedCsv, mobilitaetsprofil]);




  return null
};

export default Mobilitaetsprofil;