import React, { useContext, useState, useEffect } from "react";
import { AppContext } from '../../global/tools/Kontext';
import { Box, Slider, TextField, Typography, Switch, FormControlLabel, useTheme } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { tokens } from "../../global/library/theme";
import InfoButton from "../../GUI/InfoButton";

const Waermepumpe = () => {
  const { setWaermepumpeInfo, gebaeudeInfo, systemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [maxElektrischeLeistung, setMaxElektrischeLeistung] = useState(5);
  const [minBBWTempUeberschuss, setMinBBWTempUeberschuss] = useState(65);
  const [minBWWTempNormal, setMinBWWTempNormal] = useState(60);
  const [minBWWTempReduziert, setMinBWWTempReduziert] = useState(60);
  const [minEinschaltLeistung, setMinEinschaltLeistung] = useState(0); // Leistung in Prozent
  const [quellenTyp, setQuellenTyp] = useState("Luft");
  const [quellenTemperatur, setQuellenTemperatur] = useState(10);
  const [inverterFunktion, setInverterFunktion] = useState(true);
  const [intelligentesKuehlen, setIntelligentesKuehlen] = useState('automatisch');
  const [kuehlenaktiv, setKuehlenaktiv] = useState(false);
  const gueteZahlWP = 0.5;

  
  const quellenTemp = (quellenTyp) => {
    switch (quellenTyp) {
      case "Luft":
        return "Aussentemperatur";
      case "Grundwasser":
        return systemkonfigurationInfo === "erweitert" ? quellenTemperatur : 10;
      case "Erdsonde":
        return systemkonfigurationInfo === "erweitert" ? quellenTemperatur : 15;
      default:
        return "Aussentemperatur";
    }
  };

  useEffect(() => {
    setWaermepumpeInfo({
      maxElektrischeLeistung: parseFloat(maxElektrischeLeistung),
      quellenTyp: quellenTyp,
      inverterFunktion: inverterFunktion,
      kuehlenaktiv: kuehlenaktiv,
      intelligentesKuehlen: intelligentesKuehlen,
      quellenTemp: quellenTemp(quellenTyp),
      gueteZahlWP: gueteZahlWP,
      minEinschaltLeistung: maxElektrischeLeistung * (minEinschaltLeistung / 100),
      maxBBWTempUeberschuss: minBBWTempUeberschuss,
      minBWWTempNormal: minBWWTempNormal,
      minBWWTempReduziert: minBWWTempReduziert
    });
  }, [maxElektrischeLeistung, quellenTyp, quellenTemperatur, kuehlenaktiv, intelligentesKuehlen, inverterFunktion, gueteZahlWP, minEinschaltLeistung, minBBWTempUeberschuss, minBWWTempNormal, minBWWTempReduziert]);

  return (
    <Box>
        <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        {gebaeudeInfo.heizungTyp === 'Waermepumpe' ? "Wärmepumpe" : "Brauchwarmwasser-Wärmepumpe"}
        </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
      <br />
      <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
        <Typography color={colors.grey[100]} variant='h6' style={{ marginBottom: '7px', textAlign: 'center', flex: 1, marginLeft: '30px' }}>
          Quellentyp
        </Typography>
        <InfoButton textKey="quellenTyp" />
      </Box>
      <Select
        fullWidth
        value={quellenTyp}
        onChange={(e) => {
          setQuellenTyp(e.target.value);
        }}
        style={{ boxShadow: "inset 0 0 0 2px blue", color: colors.grey[100] }}
      >
        <MenuItem value="Luft">Luft</MenuItem>
        <MenuItem value="Grundwasser">Grundwasser</MenuItem>
        <MenuItem value="Erdsonde">Erdsonde</MenuItem>
      </Select>
      <br />
      {systemkonfigurationInfo === "erweitert" ?
      <>
        {quellenTyp !== "Luft" ?
        <>
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Quellentemperatur
          </Typography>
          <InfoButton textKey="quellenTemp" />
        </Box>
        <Slider
          value={quellenTemperatur}
          min={0}
          max={20}
          step={0.1}
          onChange={(e, newValue) => {
            setQuellenTemperatur(newValue);
          }}
          style={{ color: 'blue' }} 
        />
        <TextField
          size="small"
          label="°C"
          type="number"
          value={quellenTemperatur}
          onChange={(e) => {
            setQuellenTemperatur(Number(e.target.value));
          }}
        />
        </>
        : null }
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Maximale elektrische Leistung
          </Typography>
          <InfoButton textKey="WPMaxPel" />
        </Box>
        <Slider
          value={maxElektrischeLeistung}
          min={0.1}
          max={10}
          step={0.1}
          onChange={(e, newValue) => {
            setMaxElektrischeLeistung(newValue);
          }}
          style={{ color: 'blue' }} 
        />
        <TextField
          size="small"
          label="kW"
          type="number"
          value={maxElektrischeLeistung}
          onChange={(e) => {
            setMaxElektrischeLeistung(Number(e.target.value));
          }}
        />
        <br />
        </>
        : null }
        <Box display="flex" justifyContent="flex-end" width="100%" gap="5px">
          <FormControlLabel
            label="Inverter Wärmepumpe"
            labelPlacement="start"
            control={
              <Switch
                color="thirdly"
                size="small"
                checked={inverterFunktion}
                onChange={(e) => {
                  setInverterFunktion(e.target.checked);
                }}
              />
            }
          />
          <InfoButton textKey="inverterFunktion" />
        </Box>
      {systemkonfigurationInfo === "erweitert" && inverterFunktion ?
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Minimale Einschaltleistung
          </Typography>
          <InfoButton textKey="WPMinPel" />
        </Box>
        <Slider
          value={minEinschaltLeistung}
          min={0}
          max={100}
          step={1}
          onChange={(e, newValue) => {
            setMinEinschaltLeistung(newValue);
          }}
          style={{ color: 'green' }} 
        />
        <TextField
          value={minEinschaltLeistung}
          size="small"
          label="%"
          type="number"
          onChange={(e) => {
            setMinEinschaltLeistung(Number(e.target.value));
          }}
        />
        </>
        : null }
        <br />
        {gebaeudeInfo.heizungTyp === 'Waermepumpe' ?
          <>
            <Box display="flex" justifyContent="flex-end" width="100%" gap="5px">
                <FormControlLabel
                  label="Aktive Kühlung"
                  labelPlacement="start"
                  control={
                    <Switch
                      color="thirdly"
                      size="small"
                      checked={kuehlenaktiv}
                      onChange={(e) => {
                        setKuehlenaktiv(e.target.checked);
                      }}
                    />
                  }
                />
                <InfoButton textKey="kuehlFunktion" />
            </Box>
            {systemkonfigurationInfo === "erweitert" && kuehlenaktiv ? 
              <>
                <Typography color={colors.grey[100]} variant="h6" style={{ margin: '7px' }}>
                  Intelligentes Kühlen
                </Typography>
                <Select
                  fullWidth
                  value={intelligentesKuehlen}
                  onChange={(e) => setIntelligentesKuehlen(e.target.value)}
                  style={{ boxShadow: "inset 0 0 0 2px green", color: colors.grey[100] }}
                >
                  <MenuItem value='automatisch'>Automatisch (Kühlung bei Bedarf und PV-Überschuss)</MenuItem>
                  <MenuItem value='maxTemp'>Nur bei PV-Überschuss bis maximaler Raumtemperatur</MenuItem>
                  <MenuItem value='minTemp'>Nur bei PV-Überschuss bis minimaler Raumtemperatur</MenuItem>
                </Select>
              </>
              : null
            }
          </>
        : null
        }
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wasserboiler-Temperatur bei PV-Überschuss
          </Typography>
          <InfoButton textKey="tempBWWueWP" />
        </Box>
        <Slider
          value={minBBWTempUeberschuss}
          min={minBWWTempNormal}
          max={95}
          step={1}
          onChange={(e, newValue) => {
            setMinBBWTempUeberschuss(newValue);
          }}
          style={{ color: 'green' }} 
        />
        <TextField
          value={minBBWTempUeberschuss}
          size="small"
          label="°C"
          type="number"
          onChange={(e) => {
            setMinBBWTempUeberschuss(Number(e.target.value));
          }}
        />
      {systemkonfigurationInfo === "erweitert" ?
      <>
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wasserboiler-Temperatur Normal
          </Typography>
          <InfoButton textKey="tempBWWn" />
        </Box>
        <Slider
          value={minBWWTempNormal}
          min={0}
          max={95}
          step={1}
          onChange={(e, newValue) => {
            setMinBWWTempNormal(newValue);
          }}
          style={{ color: 'green' }} 
        />
        <TextField
          value={minBWWTempNormal}
          size="small"
          label="°C"
          type="number"
          onChange={(e) => {
            setMinBWWTempNormal(Number(e.target.value));
          }}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wasserboiler-Temperatur Reduziert
          </Typography>
          <InfoButton textKey="tempBWWr" />
        </Box>
        <Slider
          value={minBWWTempReduziert}
          min={0}
          max={minBWWTempNormal}
          step={1}
          onChange={(e, newValue) => {
            setMinBWWTempReduziert(newValue);
          }}
          style={{ color: 'green' }}
        />
        <TextField
          value={minBWWTempReduziert}
          size="small"
          label="°C"
          type="number"
          onChange={(e) => {
            setMinBWWTempReduziert(Number(e.target.value));
          }}
        />
        </>
        : null }
      </Box>
    </Box>
  );
};

export default Waermepumpe;
