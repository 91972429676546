import React, { useContext, useEffect } from "react";
import { AppContext } from "./Kontext";
import Papa from "papaparse";
import csvDaten from "../data/Daten15min.csv";

const Parser = () => {
  const { setParsedCsv } = useContext(AppContext);

  useEffect(() => {
    const parseCsvData = async () => {
      const response = await fetch(csvDaten);
      const csvText = await response.text();
      const parsedData = Papa.parse(csvText, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      }).data;
      setParsedCsv(parsedData);
    };

    parseCsvData();
  }, [setParsedCsv]);

  return; /* (
    <div>
      <h2>CSV-Datei wurde geladen und geparst.</h2>
    </div>
  ); */
};

export default Parser;
