import React, { useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js/auto';
import { AppContext } from '../tools/Kontext';

const DiagrammJahr = () => {
  const { csvBerechnet, pvAnlageInfo } = useContext(AppContext);
  const chartRef = useRef();
  const chartInstanceRef = useRef();

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    function fetchData() {

      if (!csvBerechnet || csvBerechnet.length === 0) return;

      // Hier können Sie die Spalten auswählen, die Sie in Ihrem Diagramm anzeigen möchten
      const columnNames = [
        'PV-Produktion',
        'Gesamtverbrauch',
        'Einspeisung',
        'Netzbezug',
        /* 'Raumwärme Wärmepumpe',
        'Brauchwasser Wärme Wärmepumpe',
        'Verbrauch Heizstab',
        'Verbrauch LadestationBatt',
        'Verbrauch LadestationEV',
        'Elektrische Verbaucher',
        'Personen Wärmeeintrag',
        'Externer Wärmeeintrag',
        'EV an Ladestation',
        'Fahrleistung E-Mobilität',
        'Verbrauch E-Mobilität',
        'Wärmebedarf Brauchwarmwasser',
        'Wärmeeintrag',
        'Verbrauch Wärmepumpe' */
      ];

      const sumsByMonth = Array.from({ length: 12 }, () => Array(columnNames.length).fill(0));

      for (let i = 1; i < csvBerechnet.length; i++) {
        const row = csvBerechnet[i];
        if (!row) continue;
        const dateParts = row.Datum?.split('-');
        if (!dateParts || dateParts.length < 3) continue;
        const month = parseInt(dateParts[1], 10) - 1;
        const year = parseInt(dateParts[0], 10);
        const day = parseInt(dateParts[2], 10);
        const parsedDate = new Date(year, month, day);
      
        for (let j = 0; j < columnNames.length; j++) {
          sumsByMonth[month][j] += parseFloat(row[columnNames[j]]);
        }
      }

      console.log('Summen nach Monaten:', sumsByMonth);

      const chartData = {
        labels: [
          'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
          'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
        ],
        datasets: columnNames.map((name, index) => ({
          label: name,
          data: sumsByMonth.map(monthData => monthData[index].toFixed(2)),
          backgroundColor: `rgba(${index * 60}, ${255 - index * 60}, ${index * 30 + 100}, 0.6)`,
          borderWidth: 1,
        })),
      };

      console.log('Diagrammdaten:', chartData);

      const chartConfig = {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false, // Optional, um das Seitenverhältnis des Diagramms nicht beizubehalten
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value, index, values) {
                  return value + ' kWh';
                }
              }
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Monatswerte'
            }
          }
        }
      };

      if (chartRef.current) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }
        chartInstanceRef.current = new Chart(chartRef.current, chartConfig);
      }
    }

    fetchData();
  }, [csvBerechnet]);

  return (
    <div style={{height: '100%'}}>
      <canvas ref={chartRef} />
    </div>
  );
}

export default DiagrammJahr;