import React, { useRef, useCallback, useContext, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Switch from '@mui/material/Switch';
import { AppContext } from './Kontext';
import update from 'immutability-helper';
import { tokens } from "../../global/library/theme";
import { Box, FormControlLabel, Typography, useTheme } from "@mui/material";
import InfoButton from '../../GUI/InfoButton';


const verbraucherListe = [
    { id: '1', name: 'Batteriespeicher' },
    { id: '2', name: 'Elektrofahrzeug' },
    { id: '3', name: 'Wärmepumpe - Pufferspeicher' },
    { id: '4', name: 'Wärmepumpe - Wasserboiler' },
    { id: '5', name: 'Wärmepumpe - Gebäude' },
    { id: '6', name: 'Heizstab - Brauchwarmwasser' },
];

const ItemTypes = {
  VERBRAUCHER: 'verbraucher',
};

const Verbraucher = ({ id, name, index, moveVerbraucher }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.VERBRAUCHER,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveVerbraucher(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.VERBRAUCHER,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }}>
      {name}
    </div>
  );
};

const PrioritaetenUeberschuss = () => {
  const { systemkonfigurationInfo, setVerbraucherPrioritaeten, setHemsVorhanden, parsedCsv } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [verbraucher, setVerbraucher] = useState(verbraucherListe);
  const [hems, setHems] = useState(false);

  const moveVerbraucher = useCallback((dragIndex, hoverIndex) => {
    const newVerbraucher = update(verbraucher, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, verbraucher[dragIndex]],
        ],
      });
    setVerbraucher(newVerbraucher);
    setVerbraucherPrioritaeten(newVerbraucher);
  }, [verbraucher, setVerbraucherPrioritaeten]);


  useEffect(() => {
    setHemsVorhanden({
      HEMS: hems
    });
    setVerbraucher(verbraucher);
    setVerbraucherPrioritaeten(verbraucher);
  }, [hems, parsedCsv]);


  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
            Überschuss-Steuerung
          </Typography>
          <InfoButton textKey="ueberschussSeuerung" />
        </Box>
        <FormControlLabel
          label="aktiv"
          labelPlacement="start"
          control={
            <Switch
              checked={hems}
              onChange={(e) => setHems(e.target.checked)}
              color='thirdly'
            />
          }
        />
      </Box>
      {systemkonfigurationInfo === "erweitert" ?
      <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="left"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        {verbraucher.map((item, index) => (
          <Box key={item.id} display="flex" alignItems="center" 
          justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="row"
              style={{ cursor: "grab" }}>
              <DragIndicatorIcon style={{ color: "green" }} />
              <Verbraucher
                id={item.id}
                name={item.name}
                index={index}
                moveVerbraucher={moveVerbraucher}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight="10px"
              paddingBottom="5px"
            >
              {(index + 1) + ". Priorität"}
            </Box>
          </Box>
        ))}
      </Box>
        </>
        : null }
    </Box>
  );
};

export default PrioritaetenUeberschuss;
