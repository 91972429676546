import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../global/library/theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const formattedTitle = Number(title).toLocaleString('de-CH');

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          {icon}
          <Box ml={2}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {formattedTitle}
            </Typography>
            <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.greenAccent[600] }}
        >
          {increase}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
