import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [parsedCsv, setParsedCsv] = useState([]);
  const [csvBerechnet, setCsvBerechnet] = useState([]);
  const [verbraucherPrioritaeten, setVerbraucherPrioritaeten] = useState([]);
  const [differenzWerte, setDifferenzWerte] = useState([]);
  const [referenceValues, setReferenceValues] = useState([]);
  const [referenceKomponentenInfo, setReferenceKomponentenInfo] = useState([]);
  const [events, setEvents] = useState([]);
  const [mobilitaetsprofil, setMobilitaetsprofil] = useState([]);
  const [mobilitaetsprofilSafed, setMobilitaetsprofilSafed] = useState([]);
  const [systemkonfigurationInfo, setSystemkonfigurationInfo,] = useState({});
  const [pvAnlageInfo, setPvAnlageInfo] = useState({});
  const [stromnetzInfo, setStromnetzInfo] =useState({});
  const [gebaeudeInfo, setGebaeudeInfo] = useState({});
  const [batterieInfo, setBatterieInfo] = useState({});
  const [ladestationBattInfo, setLadestationBattInfo] = useState({});
  const [fahrzeugInfo, setFahrzeugInfo] = useState({});
  const [ladestationEVInfo, setLadestationEVInfo] = useState({});
  const [waermepumpeInfo, setWaermepumpeInfo] = useState({});
  const [pufferspeicherInfo, setPufferspeicherInfo] = useState({});
  const [brennstoffheizungInfo, setBrennstoffheizungInfo] = useState({});
  const [brauchwarmwasserInfo, setBrauchwarmwasserInfo] = useState({});
  const [heizstabInfo, setHeizstabInfo] = useState({});
  const [hemsVorhanden, setHemsVorhanden] = useState({});
  const [berechneCOP, setBerechneCOP] = useState(null);
  const [berechneEffizienzLadestation, setBerechneEffizienzLadestation] = useState(null);
  const [berechneWasserverbrauch, setBerechneWasserverbrauch] = useState(null);
  const [berechneElektrischenVerbrauch, setBerechneElektrischenVerbrauch] = useState(null);
  const [berechneWaermeEintragPersonen, setBerechneWaermeEintragPersonen] = useState(null);
  const [berechneExternerWaermeeintrag, setBerechneExternerWaermeeintrag] = useState(null);
  const [berechneWaermepumpeLeistung, setBerechneWaermepumpeLeistung] = useState(null);
  const [berechneHeizstabLeistung, setBerechneHeizstabLeistung] = useState(null);
  const [berechneBatterieLeistung, setBerechneBatterieLeistung] = useState(null);
  const [berechneLadenEV, setBerechneLadenEV] = useState(null);
  const [berechneEntladenEV, setBerechneEntladenEV] = useState(null);
  const [berechneMobilitaetsProfil, setBerechneMobilitaetsProfil] = useState(null);
  const [berechneStrompreis, setBerechneStrompreis] = useState(null);
  const [berechneMinRaumTemp, setBerechneMinRaumTemp] = useState(null);
  const [berechneUeberschussVerbrauch, setBerechneUeberschussVerbrauch] = useState(null);
  console.log("Events;",events)


  return (
    <AppContext.Provider
      value={{
        parsedCsv,
        setParsedCsv,
        csvBerechnet,
        setCsvBerechnet,
        systemkonfigurationInfo,
        setSystemkonfigurationInfo,
        verbraucherPrioritaeten,
        setVerbraucherPrioritaeten,
        differenzWerte, 
        setDifferenzWerte,
        referenceValues,
        setReferenceValues,
        referenceKomponentenInfo,
        setReferenceKomponentenInfo,
        events,
        setEvents,
        mobilitaetsprofil,
        setMobilitaetsprofil,
        mobilitaetsprofilSafed,
        setMobilitaetsprofilSafed,
        pvAnlageInfo,
        setPvAnlageInfo,
        stromnetzInfo,
        setStromnetzInfo,
        gebaeudeInfo,
        setGebaeudeInfo,
        batterieInfo,
        setBatterieInfo,
        ladestationBattInfo,
        setLadestationBattInfo,
        fahrzeugInfo,
        setFahrzeugInfo,
        ladestationEVInfo,
        setLadestationEVInfo,
        waermepumpeInfo,
        setWaermepumpeInfo,
        pufferspeicherInfo,
        setPufferspeicherInfo,
        brennstoffheizungInfo,
        setBrennstoffheizungInfo,
        brauchwarmwasserInfo,
        setBrauchwarmwasserInfo,
        heizstabInfo,
        setHeizstabInfo,
        hemsVorhanden,
        setHemsVorhanden,
        berechneCOP,
        setBerechneCOP,
        berechneEffizienzLadestation,
        setBerechneEffizienzLadestation,
        berechneWasserverbrauch,
        setBerechneWasserverbrauch,
        berechneElektrischenVerbrauch,
        setBerechneElektrischenVerbrauch,
        berechneWaermeEintragPersonen,
        setBerechneWaermeEintragPersonen,
        berechneExternerWaermeeintrag,
        setBerechneExternerWaermeeintrag,
        berechneWaermepumpeLeistung,
        setBerechneWaermepumpeLeistung,
        berechneHeizstabLeistung,
        setBerechneHeizstabLeistung,
        berechneBatterieLeistung,
        setBerechneBatterieLeistung,
        berechneLadenEV,
        setBerechneLadenEV,
        berechneEntladenEV,
        setBerechneEntladenEV,
        berechneMobilitaetsProfil,
        setBerechneMobilitaetsProfil,
        berechneStrompreis,
        setBerechneStrompreis,
        berechneMinRaumTemp,
        setBerechneMinRaumTemp,
        berechneUeberschussVerbrauch,
        setBerechneUeberschussVerbrauch,

      }}
    >
      {children}
    </AppContext.Provider>
  );
};
