import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const ExternerWaermeeintrag = () => {
  const { setBerechneExternerWaermeeintrag, parsedCsv, gebaeudeInfo } = useContext(AppContext);
  
  const jahresPvProduktion = parsedCsv.slice(1).reduce((acc, curr) => acc + parseFloat(curr['Produktion']), 0);
  
  const U_Wert = gebaeudeInfo.U_Wert;
  const korrekturFaktor = 1 - (0.5 - (U_Wert * 1000));                  // 1 - (0.5 - (U_Wert * 1000)) , 0.5 / (U_Wert * 1000) 
  const wohnflaeche = gebaeudeInfo.wohnflaeche;

  function berechneExternerWaermeeintrag(dependencies) {
    const aktuellePVProduktion = dependencies['Produktion'];
    const externeWaermeeintraegeProJahr = (((17 * korrekturFaktor) / 1000) * 470) * wohnflaeche; // Jährliche externe Wärmeeinträge: 17 W/m2 * 470 Volllaststudnen

    // Berechne den Wärmeeintrag im Verhältnis zur aktuellen PV-Produktion
    const waermeeintrag =
      (externeWaermeeintraegeProJahr / jahresPvProduktion) * aktuellePVProduktion;

    return waermeeintrag || 0;
  }

  useEffect(() => {
    setBerechneExternerWaermeeintrag(() => berechneExternerWaermeeintrag);
  }, [parsedCsv, gebaeudeInfo]);

  return null;
};

export default ExternerWaermeeintrag;
