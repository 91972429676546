import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const SteuerungUeberschuss = () => {
    const {
        parsedCsv,
        setBerechneUeberschussVerbrauch,
        berechneCOP,
        verbraucherPrioritaeten,
        batterieInfo,
        ladestationBattInfo,
        fahrzeugInfo,
        ladestationEVInfo,
        waermepumpeInfo,
        pufferspeicherInfo,
        brauchwarmwasserInfo,
        gebaeudeInfo,
        heizstabInfo,
        hemsVorhanden,
        berechneEffizienzLadestation
    } = useContext(AppContext);

    const Zeitintervall = parsedCsv.length / 8760;
    const spezSpeicherKapWasser = 0.001163889; // kWh/(kg*K)

    const defaultPrioritaeten = ['Batteriespeicher', 'Elektrofahrzeug', 'Wärmepumpe - Pufferspeicher', 'Wärmepumpe - Wasserboiler', 'Wärmepumpe - Gebäude', 'Heizstab - Brauchwarmwasser']
    const prioritaeten = verbraucherPrioritaeten.length > 0 ? verbraucherPrioritaeten.map((verbraucher) => verbraucher.name) : defaultPrioritaeten;
    const HEMS = hemsVorhanden.HEMS;
    const batterieVorhanden = batterieInfo.batterieVorhanden;
    console.log("ÜS-verbraucherPrioritaeten:", verbraucherPrioritaeten);
    console.log("ÜS-Prioritäten:", prioritaeten);

    const prioBattLadestation = prioritaeten.findIndex(name => name === 'Batteriespeicher');
    const prioEVLadestation = prioritaeten.findIndex(name => name === 'Elektrofahrzeug');
    const prioPufferspeicher = prioritaeten.findIndex(name => name === 'Wärmepumpe - Pufferspeicher');
    const prioBrauchwarmwasser = prioritaeten.findIndex(name => name === 'Wärmepumpe - Wasserboiler');
    const prioGebaeude = prioritaeten.findIndex(name => name === 'Wärmepumpe - Gebäude');
    const prioHeizstab = prioritaeten.findIndex(name => name === 'Heizstab - Brauchwarmwasser');

    
    const maxPSKap = pufferspeicherInfo.volumen * spezSpeicherKapWasser * (pufferspeicherInfo.maxTemperatur + 273);
    const maxBWWKapHS = brauchwarmwasserInfo.volumen * spezSpeicherKapWasser * (heizstabInfo.maxBWWTempUeberschuss + 273);
    const minBBWKapHS = brauchwarmwasserInfo.volumen * spezSpeicherKapWasser * (heizstabInfo.einschalttemperatur + 273);
    const maxBWWKapWP = brauchwarmwasserInfo.volumen * spezSpeicherKapWasser * (waermepumpeInfo.maxBBWTempUeberschuss + 273);
    const BWWvonWP = brauchwarmwasserInfo.waermepumpeBereitgestellt || gebaeudeInfo.heizungTyp === "Waermepumpe" ? true : false;
    const maxGebKap = gebaeudeInfo.speicherKapazitaet * (gebaeudeInfo.maxRaumtemperatur + 273);
    const minGebKap = gebaeudeInfo.speicherKapazitaet * (gebaeudeInfo.minRaumtemperatur + 273);
    const heizgrenze = gebaeudeInfo.heizgrenze;
    console.log(BWWvonWP);
    console.log("pufferspeicherInfo.maxTemperatur:", pufferspeicherInfo.maxTemperatur);
    console.log("maxPSKap:", maxPSKap);

    const WPvorhanden = gebaeudeInfo.heizungTyp === 'Waermepumpe' ? true : false;
    const maxLeistungWP = waermepumpeInfo.maxElektrischeLeistung / Zeitintervall;
    const gueteZahlWP = waermepumpeInfo.gueteZahlWP;
    const kuehlenaktiv = waermepumpeInfo.kuehlenaktiv;
    const minKapKuehlen = waermepumpeInfo.intelligentesKuehlen === 'maxTemp' ? maxGebKap : minGebKap;
    const kuehlKapRaum = minKapKuehlen;

    const ueberschussHS = heizstabInfo.heizstabUeberschuss;
    const maxLeistungHS = heizstabInfo.leistung / Zeitintervall;

    const berechneUeberschussVerbrauch = (dependencies) => {
        const pvProduktion = parseFloat(dependencies['PV-Produktion']);
        const verbrauchGrundversorgung = parseFloat(dependencies['Verbrauch Grundversorgung']);
        const mittlereAussenTemp = parseFloat(dependencies['Mittlere Aussentemperatur']);

        const verbrauchGVWP = parseFloat(dependencies['Verbrauch Wärmepumpe']);
        const quellenTemp = waermepumpeInfo.quellenTemp === "Aussentemperatur" ? parseFloat(dependencies['Aussentemperatur']) : waermepumpeInfo.quellenTemp;

        const istBattKap = parseFloat(dependencies['Kapazität Batteriespeicher']);

        const istEVKap = parseFloat(dependencies['Kapazität E-Fahrzeug']);
        const verbrauchGVEVL = parseFloat(dependencies['Verbrauch LadestationEV']);
        const ladungGVEV = parseFloat(dependencies['Ladung E-Fahrzeug']);
        const anLadestation = dependencies['EV an Ladestation'];

        const istGebKap = parseFloat(dependencies['Kapazität Gebäude']);
        const istPSKap = parseFloat(dependencies['Kapazität Pufferspeicher']);
        const istBWWKap = parseFloat(dependencies['Kapazität Brauchwarmwasser']);


        const ladungGVGeb = parseFloat(dependencies['PS Wärme für Gebäude']);
        const ladungGVPS = parseFloat(dependencies['WP Wärme für Pufferspeicher']);
        const ladungGVBWW = parseFloat(dependencies['WP Wärme für Brauchwarmwasser']);

        const verbrauchGVHS = parseFloat(dependencies['Verbrauch Heizstab']);
        const ladungGVHS = verbrauchGVHS;

        const istPSTemp = ((istPSKap + ladungGVPS) / (pufferspeicherInfo.volumen * spezSpeicherKapWasser)) - 273;
        const istBWWTemp = ((istBWWKap + ladungGVBWW) / (brauchwarmwasserInfo.volumen * spezSpeicherKapWasser)) - 273;
        const { COPPS, COPBWW, COPKuehlen } = berechneCOP(istPSTemp, istBWWTemp, quellenTemp);

        let pvUeberschuss = pvProduktion - verbrauchGrundversorgung;
        let verbrauchBattL = 0;
        let ladenBatt = 0;
        let verbrauchEVL = 0;
        let ladenEV = 0;
        let verbrauchWP = 0;
        let ladenPuffer = 0;
        let ladenBoiler = 0;
        let ladenGebaeude = 0;
        let kuehlenGebaeude = 0;
        let verbrauchHS = 0;
        let ladenWarmwasser = 0;

        if (!HEMS && batterieVorhanden && pvUeberschuss > 0) {
            const maxLeistung = ladestationBattInfo.leistung / Zeitintervall;
                const battKap = batterieInfo.kapazitaet;
                verbrauchBattL = battKap > istBattKap ? Math.min(pvUeberschuss, battKap - istBattKap, maxLeistung) : 0;
                const ladeEffizienz = berechneEffizienzLadestation.berechneLadeEffizenz(verbrauchBattL, maxLeistung).ladeEffizient;
                ladenBatt = verbrauchBattL * ladeEffizienz;
                pvUeberschuss -= verbrauchBattL;
        } else

        for (let prioCounter = 0; prioCounter < prioritaeten.length; prioCounter++) {
            if (pvUeberschuss <= 0 || !HEMS) break;

            if (prioCounter === prioBattLadestation) {
                const maxLeistung = ladestationBattInfo.leistung / Zeitintervall;
                const battKap = batterieInfo.kapazitaet;
                verbrauchBattL = battKap > istBattKap ? Math.min(pvUeberschuss, battKap - istBattKap, maxLeistung) : 0;
                const ladeEffizienz = berechneEffizienzLadestation.berechneLadeEffizenz(verbrauchBattL, maxLeistung).ladeEffizient;
                ladenBatt = verbrauchBattL * ladeEffizienz;
                pvUeberschuss -= verbrauchBattL;
                
            }

            if (prioCounter === prioEVLadestation && anLadestation) {
                const maxLeistung = (ladestationEVInfo.leistung - verbrauchGVEVL) / Zeitintervall;
                const minLeistung = ladestationEVInfo.minEinschaltleistung / Zeitintervall;
                const EVKap = fahrzeugInfo.kapazitaet;
                verbrauchEVL = EVKap > (istEVKap + ladungGVEV) && pvUeberschuss >= minLeistung ? Math.min(pvUeberschuss, EVKap - ladungGVEV - istEVKap, maxLeistung) : 0;
                const ladeEffizienz = berechneEffizienzLadestation.berechneLadeEffizenz(verbrauchEVL, ladestationEVInfo.leistung / Zeitintervall).ladeEffizient;
                ladenEV = verbrauchEVL * ladeEffizienz;
                pvUeberschuss -= verbrauchEVL;
            }

            if (prioCounter === prioPufferspeicher && WPvorhanden && mittlereAussenTemp < heizgrenze /*  && istPSKap < maxPSKap */) {
                const maxLeistung = maxLeistungWP - verbrauchGVWP - verbrauchWP;
                const COP = COPPS; //5 - ((0.05 * kapDiff) ** 1.1) || 2.5;
                const verbrauchPS = waermepumpeInfo.inverterFunktion ? 
                                    maxPSKap > (istPSKap + ladungGVPS) ? Math.min(pvUeberschuss, (maxPSKap - ladungGVPS - istPSKap) / COP, maxLeistung) : 0 : 
                                    maxPSKap > (istPSKap + ladungGVPS) && pvUeberschuss >= maxLeistungWP && maxLeistungWP === maxLeistung ? maxLeistungWP : 0;
                verbrauchWP += verbrauchPS;
                ladenPuffer = verbrauchPS * COP;
                pvUeberschuss -= verbrauchPS;
            }

            if (prioCounter === prioGebaeude && WPvorhanden && mittlereAussenTemp < heizgrenze /*  && istGebKap < maxGebKap */) {
                const maxLeistung = maxLeistungWP - verbrauchGVWP - verbrauchWP;
                const COP = COPPS; //5 - ((0.05 * kapDiff) ** 1.1) || 5;
                const verbrauchGeb = waermepumpeInfo.inverterFunktion ?
                                     maxGebKap > (istGebKap + ladungGVGeb) ? Math.min(pvUeberschuss, (maxGebKap - ladungGVGeb - istGebKap) / COP, maxLeistung) : 0 :
                                     maxGebKap > (istGebKap + ladungGVGeb) && pvUeberschuss >= maxLeistungWP && maxLeistungWP === maxLeistung ? maxLeistungWP : 0;
                verbrauchWP += verbrauchGeb;
                ladenGebaeude = verbrauchGeb * COP;
                pvUeberschuss -= verbrauchGeb;
            }

            if (prioCounter === prioGebaeude && kuehlenaktiv && mittlereAussenTemp > heizgrenze) {
                const maxLeistung = maxLeistungWP - verbrauchGVWP - verbrauchWP;
                const COP = COPKuehlen; //2.5; // 5 - ((0.028 * kapDiff) ** 1.5) || 5
                const verbrauchGebKuehlen = waermepumpeInfo.inverterFunktion ?
                                     kuehlKapRaum < (istGebKap + ladungGVGeb) ? Math.min(pvUeberschuss, (istGebKap + ladungGVGeb - kuehlKapRaum) / COP, maxLeistung) : 0 :
                                     kuehlKapRaum < (istGebKap + ladungGVGeb) && pvUeberschuss >= maxLeistungWP && maxLeistungWP === maxLeistung ? maxLeistungWP : 0;
                verbrauchWP += verbrauchGebKuehlen;
                kuehlenGebaeude = verbrauchGebKuehlen * COP;
                pvUeberschuss -= verbrauchGebKuehlen;
            }

            if (prioCounter === prioBrauchwarmwasser && BWWvonWP) {
                const maxLeistung = maxLeistungWP - verbrauchGVWP - verbrauchWP;
                const COP = COPBWW; //5 - ((0.05 * kapDiff) ** 1.1) || 2.5;
                const verbrauchBWW = waermepumpeInfo.inverterFunktion ?
                                     maxBWWKapWP > (istBWWKap + ladungGVBWW + ladenWarmwasser) ? Math.min(pvUeberschuss, (maxBWWKapWP - ladungGVBWW - ladenWarmwasser - istBWWKap) / COP, maxLeistung) : 0 :
                                     maxBWWKapWP > (istBWWKap + ladungGVBWW + ladenWarmwasser) && pvUeberschuss >= maxLeistungWP && maxLeistungWP === maxLeistung ? maxLeistungWP : 0;
                verbrauchWP += verbrauchBWW;
                ladenBoiler = verbrauchBWW * COP;
                pvUeberschuss -= verbrauchBWW;
            }

            if (prioCounter === prioHeizstab && ueberschussHS) {
                const maxLeistung = maxLeistungHS - verbrauchGVHS;
                //const BWWKap = brauchwarmwasserInfo.volumen * spezSpeicherKapWasser * (brauchwarmwasserInfo.minTemperatur + 273);
                verbrauchHS = maxBWWKapHS > (istBWWKap + ladungGVBWW + ladungGVHS + ladenBoiler) && minBBWKapHS <= (istBWWKap + ladungGVBWW + ladungGVHS + ladenBoiler) ? Math.min(pvUeberschuss, (maxBWWKapHS - ladungGVBWW - ladungGVHS - ladenBoiler - istBWWKap), maxLeistung) : 0;
                ladenWarmwasser = verbrauchHS;
                pvUeberschuss -= verbrauchHS;
            }
        };

        return {
            verbrauchBattL, ladenBatt,
            verbrauchEVL, ladenEV,
            verbrauchWP, ladenPuffer, ladenBoiler, ladenGebaeude, kuehlenGebaeude,
            verbrauchHS, ladenWarmwasser
        };
    };

    useEffect(() => {
        setBerechneUeberschussVerbrauch(() => berechneUeberschussVerbrauch);
    }, [parsedCsv,
        berechneCOP,
        verbraucherPrioritaeten,
        batterieInfo,
        ladestationBattInfo,
        fahrzeugInfo,
        ladestationEVInfo,
        waermepumpeInfo,
        pufferspeicherInfo,
        brauchwarmwasserInfo,
        gebaeudeInfo,
        heizstabInfo,
        hemsVorhanden,
        berechneEffizienzLadestation
    ]);

    return null;
};

export default SteuerungUeberschuss;

