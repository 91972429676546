import React, { useState, useRef, useEffect, useContext } from "react";
import Chart from "chart.js/auto";
import { AppContext } from "../tools/Kontext";
import { Box } from "@mui/material";

const DiagrammTag = () => {
  const {
    csvBerechnet,
    pvAnlageInfo,
    gebaeudeInfo,
    brauchwarmwasserInfo,
    heizstabInfo,
    waermepumpeInfo,
    brennstoffheizungInfo,
    pufferspeicherInfo,
    ladestationEVInfo,
    fahrzeugInfo,
    batterieInfo,
  } = useContext(AppContext);
  const chartRef = useRef();
  const chartInstanceRef = useRef();
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().slice(5, 10)
  );

  
  const Zeitintervall = csvBerechnet.length / 8760;

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [csvBerechnet, selectedDate]);

  function fetchData() {
    console.log("Daten:", csvBerechnet);
    console.log("PV Info:", pvAnlageInfo);
    console.log("Geb Info:", gebaeudeInfo);
    console.log("BWW Info:", brauchwarmwasserInfo);
    console.log("HS Info:", heizstabInfo);
    console.log("WP Info:", waermepumpeInfo);
    console.log("BRH Info:", brennstoffheizungInfo);
    console.log("PS Info:", pufferspeicherInfo);
    console.log("LadestationEV Info:", ladestationEVInfo);
    console.log("fahrzeug Info:", fahrzeugInfo);
    console.log("batterie Info:", batterieInfo);

    if (!csvBerechnet || csvBerechnet.length === 0) return;

    const columnNames = [
      "PV-Produktion",
      "Einspeisung",
      "Netzbezug",
      "Aussentemperatur",
      "Raumtemperatur",
      "Temperatur Pufferspeicher",
      "Temperatur Brauchwarmwasser",
      "SOC Batteriespeicher",
      "SOC E-Fahrzeug",
      "EV an Ladestation",
      "Gesamtverbrauch Wärmepumpe",
      "Gesamtverbrauch Heizstab",
      "Wärmelast Gebäude",
      "Mittlere Aussentemperatur",
    ];

    const year = 2022; // Festgelegtes Jahr für die CSV-Daten
    const date = `${year}-${selectedDate}`;

    // Finden Sie die Anzahl der eindeutigen Zeitstempel für den ausgewählten Tag
    const uniqueTimestamps = new Set();
    for (let i = 1; i < csvBerechnet.length; i++) {
      const row = csvBerechnet[i];
      if (row.Datum === date) {
        uniqueTimestamps.add(row.Zeit);
      }
    }

    // Berechnen Sie das Intervall basierend auf der Anzahl der eindeutigen Zeitstempel
    const interval = uniqueTimestamps.size;

    const dataPoints = Array.from({ length: interval }, () =>
      Array(columnNames.length).fill(0)
    );

    for (let i = 1; i < csvBerechnet.length; i++) {
      const row = csvBerechnet[i];
      if (!row) continue;

      if (row.Datum === date) {
        const dataIndex = [...uniqueTimestamps].indexOf(row.Zeit);
        for (let j = 0; j < columnNames.length; j++) {
          dataPoints[dataIndex][j] = parseFloat(row[columnNames[j]]).toFixed(3);
        }
      }
    }

    function generateDatasets(fahrzeugInfo, batterieInfo) {
      let datasets = [
        {
          label: "PV-Produktion",
          data: dataPoints.map((pointData) => ((pointData[0] * 1000* Zeitintervall).toFixed(0))),
          borderColor: "rgba(0, 255, 0, 0.6)",
          borderWidth: 3,
          fill: false,
          yAxisID: "y1", // Datasets for this y-axis
        },
        {
          label: "Einspeisung",
          data: dataPoints.map((pointData) => ((pointData[1] * 1000* Zeitintervall).toFixed(0))),
          borderColor: "blue",
          borderWidth: 2,
          fill: false,
          yAxisID: "y1", // Datasets for this y-axis
        },
        {
          label: "Netzbezug",
          data: dataPoints.map((pointData) => ((pointData[2] * 1000* Zeitintervall).toFixed(0))),
          borderColor: "rgba(0, 255, 255, 0.6)",
          borderWidth: 1,
          fill: false,
          yAxisID: "y1", // Datasets for this y-axis
        },
        {
          label: "Aussentemperatur",
          data: dataPoints.map((pointData) => ((pointData[3] * 1).toFixed(1))),
          borderColor: "rgba(255, 255, 0, 0.6)",
          borderWidth: 1,
          fill: false,
          yAxisID: "y2", // Datasets for this y-axis
        },
        {
          label: "Raumtemperatur",
          data: dataPoints.map((pointData) => ((pointData[4] * 1).toFixed(1))),
          borderColor: "rgba(255, 0, 0, 0.6)",
          borderWidth: 1,
          fill: false,
          yAxisID: "y2", // Datasets for this y-axis
        },
        {
          label: "Temperatur Pufferspeicher",
          data: dataPoints.map((pointData) => ((pointData[5] * 1).toFixed(1))),
          borderColor: "rgba(0, 0, 255, 0.6)",
          borderWidth: 3,
          fill: false,
          yAxisID: "y2", // Datasets for this y-axis
        },
        {
          label: "Temperatur Brauchwarmwasser",
          data: dataPoints.map((pointData) => ((pointData[6] * 1).toFixed(1))),
          borderColor: "rgba(255, 0, 0, 0.6)",
          borderWidth: 1,
          fill: false,
          yAxisID: "y2", // Datasets for this y-axis
        },
        {
          label: "Gesamtverbrauch Wärmepumpe",
          data: dataPoints.map((pointData) => ((pointData[10] * 1000* Zeitintervall).toFixed(0))),
          borderColor: "orange",
          borderWidth: 2,
          fill: false,
          yAxisID: "y1", // Datasets for this y-axis
        },
        {
          label: "Gesamtverbrauch Heizstab",
          data: dataPoints.map((pointData) => ((pointData[11] * 1000* Zeitintervall).toFixed(0))),
          borderColor: "red",
          borderWidth: 2,
          fill: false,
          yAxisID: "y1", // Datasets for this y-axis
        },
        // restliche standard Datasets
      ];

      if (batterieInfo.batterieVorhanden) {
        datasets.push({
          label: "SOC Batteriespeicher",
          data: dataPoints.map((pointData) => (pointData[7] * 100).toFixed(1)),
          borderColor: "rgba(0, 255, 255, 0.6)",
          borderWidth: 3,
          fill: false,
          yAxisID: "y3", // Datasets for this y-axis
        });
      }
    
      if (fahrzeugInfo.fahrzeugVorhanden) {
        datasets.push({
          label: "SOC E-Fahrzeug",
          data: dataPoints.map((pointData) => (pointData[8] * 100).toFixed(1)),
          borderColor: "rgba(255, 0, 255, 0.6)",
          borderWidth: 1,
          fill: false,
          yAxisID: "y3", // Datasets for this y-axis
        });
      }
    
      return datasets;
    }
    
    const generateScales = (fahrzeugInfo, batterieInfo) => {
      let scales = {
        y1: {
          position: 'left',
          beginAtZero: true,
          ticks: {
            callback: function(value, index, values) {
              return value + ' W';
            }
          }
        },
        y2: {
          position: 'right',
          beginAtZero: true,
          ticks: {
            callback: function(value, index, values) {
              return value + ' °C';
            }
          }
        }
      };
    
      if (fahrzeugInfo.fahrzeugVorhanden || batterieInfo.batterieVorhanden) {
        scales.y3 = {
          position: 'right',
          beginAtZero: true,
          suggestedMin: 0,  // Empfohlener minimaler Wert
          suggestedMax: 100,  // Empfohlener maximaler Wert
          ticks: {
            callback: function(value, index, values) {
              return value + ' %';
            }
          }
        };
      }
    
      return scales;
    };
    
    const chartData = {
      labels: Array.from({ length: interval }, (_, i) =>
        i % (interval / 24) === 0 ? `${Math.floor(i / (interval / 24))}:00` : ""
      ),
      datasets: generateDatasets(fahrzeugInfo, batterieInfo),
    };
    
    const chartConfig = {
      type: "line",
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Tagesverlauf",
          },
        },
        scales: generateScales(fahrzeugInfo, batterieInfo),
        elements: {
          point: {
            radius: 0,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
      },
    };
    
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      chartInstanceRef.current = new Chart(chartRef.current, chartConfig);
    }
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" position="relative">
        <Box display="flex" justifyContent="flex-end" position="absolute" zIndex="1" right="0" top="0">
            <label htmlFor="dateSelect">Tag auswählen (MM-TT):</label>
            <input
                type="text"
                id="dateSelect"
                value={selectedDate}
                onChange={handleDateChange}
                pattern="(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])"
                maxLength="5"
                placeholder="MM-TT"
                style={{ width: "100px" }}
            />
        </Box>
        <Box  display="flex" flexDirection="column" height="100%" width="100%">
            <canvas ref={chartRef} />
        </Box>
    </Box>
  );
};

export default DiagrammTag;
