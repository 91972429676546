import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';
import { tokens } from "../global/library/theme";
import { TextField, Box, Slider, Typography, useTheme } from '@mui/material';

const StromNetz = () => {
  const { setStromnetzInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hochtarif, setHochtarif] = useState(25);
  const [niedertarif, setNiedertarif] = useState(18);
  const [einspeiseverguetung, setEinspeiseverguetung] = useState(12);
  const [start, setStart] = useState(6);
  const [end, setEnd] = useState(22);

  useEffect(() => {
    setStromnetzInfo({
        hochtarif: parseFloat(hochtarif) / 100,
        niedertarif: parseFloat(niedertarif) / 100,
        einspeiseverguetung: parseFloat(einspeiseverguetung) / 100,
        start: start,
        end: end
    });
  }, [hochtarif, niedertarif, einspeiseverguetung, start, end]);

  return (
    <Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        Stromnetz
      </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        <Typography color={colors.grey[100]} variant="h6" style={{ margin: '7px' }}>
          Hochtarif
        </Typography>
        <TextField
          size="small"
          label="Rp./kWh"
          type="number"
          value={hochtarif}
          onChange={(e) => {
            setHochtarif(Number(e.target.value));
          }}
        />
        <Typography color={colors.grey[100]} variant="h6" style={{ margin: '7px' }}>
          Niedertarif
        </Typography>
        <TextField
          size="small"
          label="Rp./kWh"
          type="number"
          value={niedertarif}
          onChange={(e) => {
            setNiedertarif(Number(e.target.value));
          }}
        />
        <Typography color={colors.grey[100]} variant="h6" style={{ margin: '7px' }}>
          Einspeisevergütung
        </Typography>
        <TextField
          size="small"
          label="Rp./kWh"
          type="number"
          value={einspeiseverguetung}
          onChange={(e) => {
            setEinspeiseverguetung(Number(e.target.value));
          }}
        />
        <br />
        <Typography color={colors.grey[100]} variant="h6">
          Zeitbereich für Hochtarif
        </Typography>
        <Slider
          value={[start, end]}
          min={0}
          max={24}
          step={0.5}
          onChange={(e, newValue) => {
            setStart(newValue[0]);
            setEnd(newValue[1]);
          }}
          style={{ color: 'blue' }} 
          getAriaValueText={valuetext}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
        />

        <Typography color={colors.grey[100]} variant="h6">
          Startzeit: {valuetext(start)}
        </Typography>

        <Typography color={colors.grey[100]} variant="h6">
          Endzeit: {valuetext(end)}
        </Typography>
      </Box>
    </Box>
  );
};
function valuetext(value) {
  const hours = Math.floor(value);
  const minutes = (value - hours) * 60;
  return `${hours.toString().padStart(2, '0')}:${minutes === 0 ? '00' : minutes}`;
}

export default StromNetz;