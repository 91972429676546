import React, { useContext, useEffect } from 'react';
import { AppContext } from '../tools/Kontext';

const Datenauswertung = () => {
  const { referenceValues, csvBerechnet, setDifferenzWerte, brennstoffheizungInfo, referenceKomponentenInfo, context } = useContext(AppContext);

  // Berechnung der Summe einer Spalte in einem Array von Objekten
  const calculateSum = (data, columnName) => {
    let sum = 0;
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      if (!row) continue;
      sum += parseFloat(row[columnName]);
    }
    return sum;
  };

  const calculateMax = (data, columnName) => {
    return Math.max(...data.map(row => parseFloat(row[columnName]) || 0));
  };

  const calculateMin = (data, columnName) => {
      return Math.min(...data.map(row => parseFloat(row[columnName]) || Infinity));
  };

  const calculateAverage = (data, columnName) => {
      return calculateSum(data, columnName) / data.length;
  };

  const calculateValues = (columnName) => {
      const sumReference = calculateSum(referenceValues, columnName).toFixed(0);
      const maxReference = calculateMax(referenceValues, columnName).toFixed(1);
      const minReference = calculateMin(referenceValues, columnName).toFixed(1);
      const mittelReference = calculateAverage(referenceValues, columnName).toFixed(1);

      const sumBerechnet = calculateSum(csvBerechnet, columnName).toFixed(0);
      const maxBerechnet = calculateMax(csvBerechnet, columnName).toFixed(1);
      const minBerechnet = calculateMin(csvBerechnet, columnName).toFixed(1);
      const mittelBerechnet = calculateAverage(csvBerechnet, columnName).toFixed(1);

      const differenz = sumReference > 0 || sumReference < 0 ? sumBerechnet - sumReference : 0;
      const anteil = sumReference > 0 ? (differenz / sumReference) * 100 : 0;
      const differenzMax = sumReference > 0 || sumReference < 0 ? maxBerechnet - maxReference : 0;
      const differenzMin = sumReference > 0 || sumReference < 0 ? minBerechnet - minReference : 0;
      const differenzMittel = sumReference > 0 || sumReference < 0 ? mittelBerechnet - mittelReference : 0;

      return {
          summe: sumBerechnet, 
          max: maxBerechnet, 
          min: minBerechnet, 
          mittel: mittelBerechnet,
          summeRef: sumReference, 
          maxRef: maxReference,
          minRef: minReference,
          mittelRef: mittelReference,
          differenz, 
          differenzMax,
          differenzMin,
          differenzMittel,
          anteil
      };
  };

  useEffect(() => {
    const pvProduktion = calculateValues('PV-Produktion');
    const gesamtverbrauch = calculateValues('Gesamtverbrauch');
    const verbrauchGeraete = calculateValues('Elektrische Verbaucher');
    const einspeisung = calculateValues('Einspeisung');
    const netzbezug = calculateValues('Netzbezug');
    const stromkosten = calculateValues('Stromkosten');

    const verbrauchWP = calculateValues('Gesamtverbrauch Wärmepumpe');
    const verbrauchHS = calculateValues('Gesamtverbrauch Heizstab');
    const waermeWP = calculateValues('Wärmeerzeugung Wärmepumpe');
    const waermeRaumWP = calculateValues('Raumwärme Wärmepumpe');
    const waermeWasserWP = calculateValues('Brauchwasser Wärme Wärmepumpe');
    const kaelteRaum = calculateValues('Raumkälte Wärmepumpe');

    const verbrauchFossil = calculateValues('Verbrauch fossiler Brennstoffe');
    const raumTemp = calculateValues('Raumtemperatur');
    const aussenTemp = calculateValues('Aussentemperatur');
    const BWWTemp = calculateValues('Temperatur Brauchwarmwasser');
    
    const verbrauchLadestationEV = calculateValues('Gesamtverbrauch LadestationEV');
    const ladungEV = calculateValues('Gesamtladung E-Fahrzeug');
    const ladungEVExtern = calculateValues('Ladung E-Fahrzeug extern');
    const streckeEV = calculateValues('Fahrleistung E-Mobilität');

    const wasserVerbrauch = calculateValues('Warmwasserverbrauch');

    const eigenverbrauchReference = pvProduktion.summeRef > 0 ? (pvProduktion.summeRef - einspeisung.summeRef) / pvProduktion.summeRef : 0;
    const autarkieReference = gesamtverbrauch.summeRef > 0 ? (pvProduktion.summeRef - einspeisung.summeRef) / gesamtverbrauch.summeRef : 0;
    const eigenverbrauch = pvProduktion.summe > 0 ? (pvProduktion.summe - einspeisung.summe) / pvProduktion.summe : 0;
    const autarkie = gesamtverbrauch.summe > 0 ? (pvProduktion.summe - einspeisung.summe) / gesamtverbrauch.summe : 0;
    const eigenverbrauchDifferenz = eigenverbrauchReference > 0 ? eigenverbrauch - eigenverbrauchReference : 0;
    const autarkieDifferenz = autarkieReference > 0 ? autarkie - autarkieReference : 0;
    const eigenverbrauchAnteil = eigenverbrauchReference > 0 ? (eigenverbrauchDifferenz / eigenverbrauchReference) * 100 : 0;
    const autarkieAnteil = autarkieReference > 0 ? (autarkieDifferenz / autarkieReference) * 100 : 0;

    const CO2Netz = 0.128; //kg/kWh
    const CO2PV = 0.05; //kg/kWh
    const heizwertFossil = brennstoffheizungInfo.heizwert; //kWh/Einheit
    const heizwertFossilRef = referenceKomponentenInfo?.brennstoffheizungInfo?.heizwert;
    const CO2Fossil = brennstoffheizungInfo.belastungCO2; //kg/kWh
    const CO2FossilRef = referenceKomponentenInfo?.brennstoffheizungInfo?.belastungCO2;
    const CO2Ref = gesamtverbrauch.summeRef !== 0 ? (eigenverbrauch * CO2PV) + (verbrauchFossil.summeRef * heizwertFossilRef * CO2FossilRef) + (netzbezug.summeRef * CO2Netz) - (pvProduktion.summeRef * ( CO2Netz - CO2PV)) : netzbezug.summeRef * CO2Netz;
    const CO2 = gesamtverbrauch.summeRef !== 0 ? (eigenverbrauch * CO2PV) + (verbrauchFossil.summe * heizwertFossil * CO2Fossil) + (netzbezug.summe * CO2Netz) - (pvProduktion.summe * ( CO2Netz - CO2PV)) : netzbezug.summeRef * CO2Netz;
    const CO2Differenz = CO2 !== 0 && CO2Ref ? CO2 - CO2Ref : 0;
    const CO2Anteil = CO2Ref !== 0 ? (CO2Differenz / CO2Ref) * 100 : 0;

    const kostenFossil = brennstoffheizungInfo.preisProEinheit; // kWh/kg / kWh/m3 / kWh/l
    const brennstoffKostenRef = gesamtverbrauch.summeRef !== 0 ?  verbrauchFossil.summeRef * kostenFossil : 0;
    const brennstoffKosten = gesamtverbrauch.summeRef !== 0 ?  verbrauchFossil.summe * kostenFossil : 0;
    const brennstoffKostenDifferenz = gesamtverbrauch.summeRef !== 0 ? brennstoffKosten - brennstoffKostenRef : 0;
    const brennstoffKostenAnteil = brennstoffKostenRef > 0 ? (brennstoffKostenDifferenz / brennstoffKostenRef) * 100 : 0;

    const JAZ = verbrauchWP.summe > 0 ? waermeWP.summe / verbrauchWP.summe : 0;
    const JAZRef = verbrauchWP.summeRef > 0 ? waermeWP.summeRef / verbrauchWP.summeRef: 0;
    const JAZDifferenz = JAZRef > 0 ? JAZ - JAZRef : 0;
    const JAZAnteil = JAZRef > 0 ? (JAZDifferenz / JAZRef) * 100 : 0;
    console.log('waermeRaumWP:', waermeRaumWP.summe)
    console.log('waermeWasserWP:', waermeWasserWP.summe)
    console.log('kaelteRaum:', kaelteRaum.summe)
    console.log('verbrauchWP:', verbrauchWP.summe)
    
    setDifferenzWerte({
      eigenverbrauch: eigenverbrauch,
      eigenverbrauchReference: eigenverbrauchReference,
      eigenverbrauchDifferenz: eigenverbrauchDifferenz,
      eigenverbrauchAnteil: eigenverbrauchAnteil.toFixed(0),

      autarkie: autarkie,
      autarkieReference: autarkieReference,
      autarkieDifferenz: autarkieDifferenz,
      autarkieAnteil: autarkieAnteil.toFixed(0),

      pvProduktionDiff: pvProduktion.differenz,
      pvProduktionAnteil: pvProduktion.anteil.toFixed(0),
      pvProduktionSumme: pvProduktion.summe,
      pvProduktionSummeRef: pvProduktion.summeRef,

      gesamtverbrauchDiff: gesamtverbrauch.differenz,
      gesamtverbrauchAnteil: gesamtverbrauch.anteil.toFixed(0),
      gesamtverbrauchSumme: gesamtverbrauch.summe,
      gesamtverbrauchSummeRef: gesamtverbrauch.summeRef,

      verbrauchHSDiff: verbrauchHS.differenz,
      verbrauchHSAnteil: verbrauchHS.anteil.toFixed(0),
      verbrauchHSSumme: verbrauchHS.summe,
      verbrauchHSSummeRef: verbrauchHS.summeRef,

      verbrauchGeraeteDiff: verbrauchGeraete.differenz,
      verbrauchGeraeteAnteil: verbrauchGeraete.anteil.toFixed(0),
      verbrauchGeraeteSumme: verbrauchGeraete.summe,
      verbrauchGeraeteSummeRef: verbrauchGeraete.summeRef,

      verbrauchWPDiff: verbrauchWP.differenz,
      verbrauchWPAnteil: verbrauchWP.anteil.toFixed(0),
      verbrauchWPSumme: verbrauchWP.summe,
      verbrauchWPSummeRef: verbrauchWP.summeRef,

      waermeRaumWP: waermeRaumWP.summe,
      waermeRaumWPRef: waermeRaumWP.summeRef,
      waermeRaumWPDiff: waermeRaumWP.differenz,
      waermeWasserWP: waermeWasserWP.summe,
      waermeWasserWPRef: waermeWasserWP.summeRef,
      waermeWasserWPDiff: waermeWasserWP.differenz,
      kaelteRaum: kaelteRaum.summe,
      kaelteRaumRef: kaelteRaum.summeRef,
      kaelteRaumDiff: kaelteRaum.differenz,

      einspeisungDiff: einspeisung.differenz,
      einspeisungAnteil: einspeisung.anteil.toFixed(0),
      einspeisungSumme: einspeisung.summe,
      einspeisungSummeRef: einspeisung.summeRef,

      netzbezugDiff: netzbezug.differenz,
      netzbezugAnteil: netzbezug.anteil.toFixed(0),
      netzbezugSumme: netzbezug.summe,
      netzbezugSummeRef: netzbezug.summeRef,

      stromkostenDiff: stromkosten.summeRef == 0 ? 0 : stromkosten.differenz,
      stromkostenAnteil: stromkosten.anteil.toFixed(0),
      stromkostenSumme: stromkosten.summe,
      stromkostenSummeRef: stromkosten.summeRef,

      CO2: CO2.toFixed(0),
      CO2Ref: CO2Ref.toFixed(0),
      CO2Differenz: CO2Differenz.toFixed(0),
      CO2Anteil: CO2Anteil.toFixed(0),

      JAZ: JAZ.toFixed(2),
      JAZRef: JAZRef.toFixed(2),
      JAZDifferenz: JAZDifferenz.toFixed(2),
      JAZAnteil: JAZAnteil.toFixed(0),

      wasserVerbrauch: wasserVerbrauch.summe,
      wasserVerbrauchRef: wasserVerbrauch.summeRef,
      wasserVerbrauchDifferenz: wasserVerbrauch.differenz,

      brennstoffVerbrauchSumme: verbrauchFossil.summe,
      brennstoffVerbrauchSummeRef: verbrauchFossil.summeRef,
      brennstoffDifferenz: verbrauchFossil.differenz.toFixed(0),
      brennstoffAnteil: verbrauchFossil.anteil.toFixed(0),
      
      brennstoffKosten: brennstoffKosten.toFixed(0),
      brennstoffKostenRef: brennstoffKostenRef.toFixed(0),
      brennstoffKostenDifferenz: brennstoffKostenDifferenz.toFixed(0),
      brennstoffKostenAnteil: brennstoffKostenAnteil.toFixed(0),

      raumTempMax: raumTemp.max,
      raumTempMin: raumTemp.min,
      raumTempMinRef: raumTemp.minRef,
      raumTempMax: raumTemp.max,
      raumTempMaxRef: raumTemp.maxRef,
      raumTempMinDifferenz: raumTemp.differenzMin.toFixed(1),
      raumTempMittel: raumTemp.mittel,
      raumTempMittelRef: raumTemp.mittelRef,
      raumTempMittelDifferenz: raumTemp.differenzMittel,

      aussenTempMax: aussenTemp.max,
      aussenTempMin: aussenTemp.min,
      aussenTempMittel: aussenTemp.mittel,

      minBBWTemp: BWWTemp.min,
      minBBWTempRef: BWWTemp.minRef,
      minBBWTempDifferenz: BWWTemp.differenzMin.toFixed(1),
      maxBBWTemp: BWWTemp.max,
      maxBBWTempRef: BWWTemp.maxRef,
      BWWTempMittel: BWWTemp.mittel,
      BWWMittelRef: BWWTemp.mittelRef,

      verbrauchLadestationEV: verbrauchLadestationEV.summe,
      verbrauchLadestationEVRef: verbrauchLadestationEV.summeRef,
      ladungEV: ladungEV.summe,
      ladungEVRef: ladungEV.summeRef,
      ladeEVExtern: ladungEVExtern.summe,
      ladeEVExternRef: ladungEVExtern.summeRef,
      ladeEVExternDiff: ladungEVExtern.differenz,
      ladeEffizienzEV: (ladungEV.summe /verbrauchLadestationEV.summe).toFixed(2),
      ladeEffizienzEVRef: (ladungEV.summeRef /verbrauchLadestationEV.summeRef).toFixed(2),
      streckeEV: streckeEV.summe,
      streckeEVRef: streckeEV.summeRef,

    });

    if (ladungEVExtern.summe > 0) {
    alert(`Achtung: Mit dem aktuellem Mobilitätsprofil und der gewählten Ladestrategie müssen Sie Ihr Elektrofahrzeug extern laden! Erforderliche externe Ladung: ${ladungEVExtern.summe + " kWh"}`);
}
    console.log('JAZ:', JAZ)
  }, [referenceValues, csvBerechnet, brennstoffheizungInfo, referenceKomponentenInfo, context]);

  return null; // Diese Komponente rendert nichts
};

export default Datenauswertung;
