import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const SteuerungEMobilitaet = () => {
  const {
    setBerechneLadenEV,
    setBerechneEntladenEV,
    parsedCsv,
    fahrzeugInfo,
    ladestationEVInfo,
    berechneEffizienzLadestation
  } = useContext(AppContext);
  
  const Zeitintervall = parsedCsv.length / 8760;

  function berechneLadenEV(dependencies) {
    const EVKap = fahrzeugInfo.kapazitaet;
    const maxLeistung = ladestationEVInfo.leistung / Zeitintervall;
    const minEinschaltleistung = ladestationEVInfo.minEinschaltleistung / Zeitintervall;
    const ladestrategie = ladestationEVInfo.ladestrategie;
    const hochtarif = dependencies['Hochtarif'];
    const istAnLadestation = dependencies['EV an Ladestation'];
    const istEVKap = parseFloat(dependencies['Kapazität E-Fahrzeug']);
    const sollEVKap = ladestrategie === 'Min vollladen' ? EVKap : parseFloat(dependencies['Min-Ladestand E-Mobilität']) + (EVKap * 0.1);
    const zeitBisEvent = parseFloat(dependencies['Zeit bis Event E-Mobilität']);
    const ladeZeit = zeitBisEvent > 0 ? zeitBisEvent : 1;
    const minLadungBisEvent = (sollEVKap - istEVKap) / (ladeZeit / (60 / Zeitintervall));
    const minLadung = minLadungBisEvent >= minEinschaltleistung ? minLadungBisEvent : minEinschaltleistung;

    let verbrauchEVL = 0;
    let ladeEffizienz = 1;

    // Ladeeffizienz für maximale und minimale Ladeleistung
    let maxLeistungEffizienz = berechneEffizienzLadestation.berechneLadeEffizenz(maxLeistung, maxLeistung).ladeEffizient;
    let minLadungEffizienz;
    if (minLadung < maxLeistung) {
        minLadungEffizienz = berechneEffizienzLadestation.berechneLadeEffizenz(minLadung, maxLeistung).ladeEffizient;
    } else {
        minLadungEffizienz = maxLeistungEffizienz;
    }


    if (istAnLadestation && istEVKap < EVKap) {
      if ((ladestrategie === 'Solar & minimale Ladeleistung' || ladestrategie === 'Min vollladen') && istEVKap < sollEVKap) {
          verbrauchEVL = Math.min(minLadung, maxLeistung) / minLadungEffizienz;
          ladeEffizienz = minLadungEffizienz;
      } else if (ladestrategie === 'Solar & maximale Ladeleistung' && istEVKap < sollEVKap) {
            verbrauchEVL = maxLeistung / maxLeistungEffizienz;
            ladeEffizienz = maxLeistungEffizienz;
      } else if (ladestrategie === 'Immer vollladen' || ladestrategie === 'Max vollladen') {
          if(istEVKap < EVKap) {
            verbrauchEVL = maxLeistung / maxLeistungEffizienz;
            ladeEffizienz = maxLeistungEffizienz;
          }
      } else if (ladestrategie === 'Solar & Niedertarif') {
          if(istEVKap < EVKap && !hochtarif) {
            verbrauchEVL = maxLeistung / maxLeistungEffizienz;
            ladeEffizienz = maxLeistungEffizienz;
          }
      } else if (ladestrategie === 'nur Solar') {
            verbrauchEVL = 0;
            ladeEffizienz = 1;
      }
  }

  // Begrenzung verbrauchEVL auf maxLeistung
  verbrauchEVL = Math.min(verbrauchEVL, maxLeistung);

  const ladenEV = verbrauchEVL * ladeEffizienz;

  return { ladenEV, verbrauchEVL };
}

  function berechneEntladenEV(dependencies) {
    const EVKap = fahrzeugInfo.kapazitaet;
    const maxLeistung = ladestationEVInfo.leistung / Zeitintervall;
    const istAnLadestation = dependencies['EV an Ladestation'];
    const istEVKap = parseFloat(dependencies['Kapazität E-Fahrzeug']);
    const sollEVKap = parseFloat(dependencies['Min-Ladestand E-Mobilität']) * 1.1;

    const pvProduktion = parseFloat(dependencies['PV-Produktion']);
    const stromverbrauch = parseFloat(dependencies['Elektrische Verbaucher']);
    const verbrauchWP = parseFloat(dependencies['Verbrauch Wärmepumpe']);
    const verbrauchHS = parseFloat(dependencies['Verbrauch Heizstab']);
    const battEntladen = parseFloat(dependencies['Rückspeisung LadestationBatt']);
    const verbrauchBattValue = parseFloat(dependencies['Verbrauch LadestationBatt']);
    const verbrauchBatt = isNaN(verbrauchBattValue) ? 0 : verbrauchBattValue;
    const verbrauchEV = parseFloat(dependencies['Verbrauch LadestationEV']);

    const differenz = pvProduktion + battEntladen - stromverbrauch - verbrauchWP - verbrauchHS;

    let lieferung = 0;

    if (ladestationEVInfo.bidirektional && istAnLadestation && istEVKap > sollEVKap && differenz < 0 && verbrauchBatt <= 0 && verbrauchEV <= 0) {
      lieferung = istEVKap > EVKap * 0.1 ? Math.min(-differenz, maxLeistung) : 0;
    }
    //console.log(ladestationEVInfo.bidirektional,istAnLadestation,istEVKap > sollEVKap,differenz < 0,verbrauchBatt <= 0,verbrauchEV <= 0);
    //console.log(verbrauchBatt);

    const entladeEffizienz = berechneEffizienzLadestation.berechneEntladeEffizenz(lieferung, maxLeistung).entladeEffizient;
    const entladenEV = lieferung / entladeEffizienz;
    const lieferungEVL = lieferung;

    return { entladenEV, lieferungEVL };
  }

  useEffect(() => {
    setBerechneLadenEV(() => berechneLadenEV);
    setBerechneEntladenEV(() => berechneEntladenEV);
  }, [parsedCsv,
    fahrzeugInfo,
    ladestationEVInfo,
    berechneEffizienzLadestation
  ]);

  return null;
};

export default SteuerungEMobilitaet;