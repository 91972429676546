import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Switch, Slider, FormControlLabel, Box, Typography, useTheme } from '@mui/material';
import InfoButton from "../../GUI/InfoButton";

const Heizstab = () => {
  const { setHeizstabInfo, waermepumpeInfo, systemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leistung, setLeistung] = useState(5);
  const [zuschaltTemperatur, setZuschaltTemperatur] = useState(40);
  const [maxBWWTempHS, setMaxBWWTempHS] = useState(65);
  const [heizstabUeberschuss, setHeizstabUeberschuss] = useState(false);

  useEffect(() => {
    setHeizstabInfo({
        leistung: parseFloat(leistung),
        maxBWWTempUeberschuss: parseFloat(maxBWWTempHS),
        einschalttemperatur: parseFloat(zuschaltTemperatur),
        heizstabUeberschuss: heizstabUeberschuss,
    });
  }, [leistung, maxBWWTempHS, zuschaltTemperatur, heizstabUeberschuss]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Heizstab
        </Typography>
        <FormControlLabel
          label="Heizstab  für Überschusssteuerung einsetzen"
          labelPlacement="start"
          control={
            <Switch
              checked={heizstabUeberschuss}
              onChange={(e) => setHeizstabUeberschuss(e.target.checked)}
              color="thirdly"
            />
          }
        />
      </Box>
      {heizstabUeberschuss ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
          color={heizstabUeberschuss ? colors.grey[100] : colors.grey[500]}
          p="15px"
        >
          {systemkonfigurationInfo === "erweitert" ? (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                  Leistung
                </Typography>
                <InfoButton textKey="heizstabMaxPel" />
              </Box>
              <Slider
                value={leistung}
                min={0}
                max={10}
                step={0.1}
                disabled={!heizstabUeberschuss}
                onChange={(e, newValue) => {
                  setLeistung(newValue);
                }}
                style={{ color: heizstabUeberschuss ? 'blue' : colors.grey[500] }} 
              />
              <TextField
                size="small"
                type="number"
                label="kW"
                value={leistung}
                disabled={!heizstabUeberschuss}
                onChange={(e) => {
                  setLeistung(Number(e.target.value));
                }}
              />
              <br />
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                  Zuschalttemperatur
                </Typography>
                <InfoButton textKey="tempHSzuschalt" />
              </Box>
              <Slider
                value={zuschaltTemperatur}
                min={0}
                max={waermepumpeInfo.maxBBWTempUeberschuss}
                step={1}
                disabled={!heizstabUeberschuss}
                onChange={(e, newValue) => {
                  setZuschaltTemperatur(newValue);
                }}
                style={{ color: heizstabUeberschuss ? 'green' : colors.grey[500] }}
              />
              <TextField
                size="small"
                type="number"
                label="°C"
                value={zuschaltTemperatur}
                disabled={!heizstabUeberschuss}
                onChange={(e) => {
                  setZuschaltTemperatur(Number(e.target.value));
                }}
              />
            </>
          ) : null}
          <br />
          <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
            <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Zieltemperatur bei PV-Überschuss
            </Typography>
            <InfoButton textKey="tempBWWueHS" />
              </Box>
          <Slider
            value={maxBWWTempHS}
            min={waermepumpeInfo.maxBBWTempUeberschuss}
            max={95}
            step={1}
            disabled={!heizstabUeberschuss}
            onChange={(e, newValue) => {
              setMaxBWWTempHS(newValue);
            }}
            style={{ color: heizstabUeberschuss ? 'green' : colors.grey[500] }} 
          />
          <TextField
            size="small"
            type="number"
            label="°C"
            value={maxBWWTempHS}
            disabled={!heizstabUeberschuss}
            onChange={(e) => {
              setMaxBWWTempHS(Number(e.target.value));
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default Heizstab;
