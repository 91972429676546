import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./GUI/scenes/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./global/library/theme";
import { AppProvider } from "./global/tools/Kontext";
import Parser from "./global/tools/Parser";
import COP from "./lastprofile/COP";
import EffizenzLadestation from "./lastprofile/EffizienzLadestation";
import SteuerungWaermepumpe from "./regelfunktionen/SteuerungWaermepumpe";
import SteuerungHeizstab from "./regelfunktionen/SteuerungHeizstab";
import SteuerungBatterie from "./regelfunktionen/SteuerungBatteriespeicher";
import SteuerungEMobilitaet from "./regelfunktionen/SteuerungEMobilitaet";
import Raumtemperatur from "./lastprofile/Raumtemperatur";
import ElektrischerVerbrauch from "./lastprofile/Elektroverbraucher";
import ExternerWaermeeintrag from "./lastprofile/ExterneWaermeeintraege";
import PersonenWaerme from "./lastprofile/Personenwaerme";
import Wasserverbrauch from "./lastprofile/Wasserverbrauch";
import Strompreis from "./lastprofile/Strompreis";
import Datenauswertung from "./global/auswertung/Datenauswertung";
import Mobilitaetsprofil from "./lastprofile/Mobilitaetsprofil";
import SteuerungUeberschuss from "./regelfunktionen/SteuerungUeberschuss";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <AppProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <main className="content">
              <Routes>
                <Route path="/" element={<Dashboard />} />
              </Routes>
              <COP />
              <EffizenzLadestation />
              <SteuerungWaermepumpe />
              <SteuerungHeizstab />
              <SteuerungBatterie />
              <SteuerungEMobilitaet />
              <Raumtemperatur />
              <ElektrischerVerbrauch />
              <ExternerWaermeeintrag />
              <PersonenWaerme />
              <Wasserverbrauch />
              <Mobilitaetsprofil />
              <Strompreis />
              <Parser />
              <Datenauswertung />
              <SteuerungUeberschuss />
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AppProvider>
  );
}

export default App;
