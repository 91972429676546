import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Switch, Slider, FormControlLabel, Box, Typography, useTheme } from '@mui/material';
import InfoButton from "../../GUI/InfoButton";

const Batterie = () => {
  const { setBatterieInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [kapazitaet, setKapazitaet] = useState(25);
  const [batterieVorhanden, setBatteireVorhanden] = useState(false);

  useEffect(() => {
    setBatterieInfo({
        kapazitaet: batterieVorhanden ? parseFloat(kapazitaet) : 0,
        batterieVorhanden: batterieVorhanden,
    });
  }, [kapazitaet, batterieVorhanden]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
          Batteriespeicher
        </Typography>
        <FormControlLabel
          label="vorhanden"
          labelPlacement="start"
          control={
            <Switch
              checked={batterieVorhanden}
              onChange={(e) => setBatteireVorhanden(e.target.checked)}
              color="thirdly"
            />
          }
        />
      </Box>
      <>
      {batterieVorhanden ?
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={batterieVorhanden ? colors.grey[100] : colors.grey[500]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
          Kapazität
          </Typography>
          <InfoButton textKey="kapBatt" />
        </Box>
        <Slider
          value={kapazitaet}
          min={0}
          max={50}
          step={1}
          disabled={!batterieVorhanden}
          onChange={(e, newValue) => {
            setKapazitaet(newValue);
          }}
          style={{ color: batterieVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kWh"
          type="number"
          value={kapazitaet}
          disabled={!batterieVorhanden}
          onChange={(e) => {
            setKapazitaet(Number(e.target.value));
          }}
        />
        <br />
      </Box>
      : null}
      </>
    </Box>
  );
};

export default Batterie;