import React, { useContext } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../global/library/theme";
import WorkflowBox from "../../GUI/WorkflowBox";
import { AppContext } from "./Kontext";

const Anleitung = () => {
  const {csvBerechnet} = useContext(AppContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" pt="15px">
      <Box flexGrow={1} flexBasis={0}>
        { csvBerechnet && csvBerechnet.length > 0 ? null :
        <>
        <Typography color={colors.grey[100]} variant="h2" fontWeight="600" align="center">
          Kurzanleitung
        </Typography>
        <Typography color={colors.grey[100]} variant="h5" align="center" pb="15px">
          Verwendung des Simulationstools
        </Typography>
        </>
        }
      </Box>
      <Box flexGrow={5} flexBasis={0} display="flex" flexDirection="row" gap="15px">
        <WorkflowBox 
          image="/Schritt_1.PNG"
          titel="1. Schritt"
          subtitel="Komponenten konfigurieren"
          description="Konfigurieren Sie auf der rechten Seite die Komponenten Ihres Gebäudesystems."
        />
        <WorkflowBox 
          image="/Schritt_2.PNG"
          titel="2. Schritt"
          subtitel="Simulation ausführen"
          description="Führen Sie die Simulation mit der aktuellen Konfiguration durch."
        />
        <WorkflowBox 
          image="/Schritt_3.PNG"
          titel="3. Schritt"
          subtitel="Werte anpassen (erweitert)"
          description="Verwenden Sie die Korrekturwerte um die effektiven Werte Ihres aktuellen Gebäudesystems zu erreichen."
        />
        <WorkflowBox 
          image="/Schritt_4.PNG"
          titel="4. Schritt"
          subtitel="Referenzwerte speichern"
          description="Sobald die Simulationswerte mit den effektiven Werten Ihres Gebäudesystems für Sie ausreichend übereinstimmen, speichern Sie die Ergebnisse als Referenz."
        />
        <WorkflowBox 
          image="/Schritt_5.PNG"
          titel="5. Schritt"
          subtitel="Einstellungen optimieren"
          description="Testen und simulieren Sie verschiedene Einstellungen (grüne Eingabefelder)."
        />
      </Box>
    </Box>
  );
};

export default Anleitung;
