import React, { useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js/auto';
import { AppContext } from '../tools/Kontext';
import { Box } from "@mui/material";

const DiagrammTotal = () => {
  const { csvBerechnet, pvAnlageInfo } = useContext(AppContext);
  const chartRef = useRef();
  const chartInstanceRef = useRef();

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    function fetchData() {
      if (!csvBerechnet || csvBerechnet.length === 0) return;

      const columnNames = [
        'Gesamtverbrauch',
        'Einspeisung',
        'Netzbezug',
        'Gesamtverbrauch Wärmepumpe',
        'Raumwärme Wärmepumpe',
        'Brauchwasser Wärme Wärmepumpe',
        'Raumkälte Wärmepumpe',
        'Gesamtverbrauch Heizstab',
        'Verbrauch Elektrische Verbaucher',
        'Verbrauch E-Mobilität',
        /* 'Wärmeeintrag',
        'Wärmebedarf Gebäude',
        'PS Wärme für Gebäude',
        'Wärmeverlust Brauchwarmwasser',
        'Warmwasserverbrauch',
        'Wärmebedarf Brauchwarmwasser',
        'WP Wärme für Brauchwarmwasser',
        'Wärmebedarf Pufferspeicher',
        'WP Wärme für Pufferspeicher',
        'Gesamtverbrauch Wärmepumpe',
        'Überschuss Verbrauch Wärmepumpe',
        'Wärmeerzeugung Wärmepumpe',
        'Verbrauch fossiler Brennstoffe',
        'Kältebedarf Gebäude',
        'WP Kälte für Raum',
        'Verbrauch Heizstab',
        'Überschuss Verbrauch Heizstab',
        'Ladung E-Fahrzeug',
        'Überschuss Ladung E-Fahrzeug',
        'Verbrauch LadestationEV',
        'Überschuss Verbrauch LadestationEV',
        'Rückspeisung LadestationEV',
        'Fahrleistung E-Mobilität',
        'Verbrauch Grundversorgung',
        'Gesamtverbrauch',
        'Überschuss Verbrauch LadestationBatt' */
      ];

      const sums = Array(columnNames.length).fill(0);

      for (let i = 1; i < csvBerechnet.length; i++) {
        const row = csvBerechnet[i];
        if (!row) continue;
        const dateParts = row.Datum?.split('-');
        if (!dateParts || dateParts.length < 3) continue;
        const month = parseInt(dateParts[1], 10) - 1;
        const year = parseInt(dateParts[0], 10);
        const day = parseInt(dateParts[2], 10);
        const parsedDate = new Date(year, month, day);

        for (let j = 0; j < columnNames.length; j++) {
          sums[j] += parseFloat(row[columnNames[j]]);
        }
      }

      console.log('Summen:', sums);

      const chartData = {
        labels: columnNames,
        datasets: [
          {
            label: 'Jahreswerte',
            data: sums.map(value => value.toFixed(0)),
            backgroundColor: columnNames.map((_, index) => `rgba(${index * 60}, ${255 - index * 60}, ${index * 30 + 100}, 0.6)`),
            borderWidth: 1,
          },
        ],
      };

      console.log('Diagrammdaten:', chartData);

      const chartConfig = {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true, // Set responsive to true
          maintainAspectRatio: false, // And optionally disable aspectRatio maintainance
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value, index, values) {
                  return value + ' kWh';
                }
              }
            },
          },
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: 'Jahreswerte',
              padding: {
                top: 10, // Increase padding at the top
                bottom: 50,
              },
            }
          }
        }
      };

      if (chartRef.current) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }
        chartInstanceRef.current = new Chart(chartRef.current, chartConfig);
      }
    }

    fetchData();
  }, [csvBerechnet]);

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <canvas style={{ width: '100%', height: '100%' }} ref={chartRef} />
    </Box>
  );
}

export default DiagrammTotal;