import React, { useContext, useEffect } from "react";
import { AppContext } from "../global/tools/Kontext";

const EffizenzLadestation = () => {
  const { setBerechneEffizienzLadestation, parsedCsv } = useContext(AppContext);

  function berechneLadeEffizenz(laden, maxLeistung) {
    const ladeEffizient = 0.95 - ( 1 - ((laden / maxLeistung)) ** (1/10));

    return { ladeEffizient };
  }

  function berechneEntladeEffizenz(lieferung, maxLeistung) {
    const entladeEffizient = 0.95 - ( 1 - ((lieferung / maxLeistung)) ** (1/10));

    return { entladeEffizient };
  }

  const effizienzFunktionen = {
    berechneLadeEffizenz,
    berechneEntladeEffizenz,
  }

  useEffect(() => {
    setBerechneEffizienzLadestation(effizienzFunktionen);
  }, [setBerechneEffizienzLadestation, parsedCsv]);

  return null;
};

export default EffizenzLadestation;
