import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const ElektrischerVerbrauch = () => {
  const { setBerechneElektrischenVerbrauch, parsedCsv, gebaeudeInfo } = useContext(AppContext);
  const Zeitintervall = parsedCsv.length / 8760;

  const korrekturWert = gebaeudeInfo.stromverbrauchKorrektur

  function berechneElektrischenVerbrauch(dependencies) {
    const Time = dependencies['Zeit'];
    const timeParts = Time.split(':');
    const stunde = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);

    // Definiere die prozentualen Anteile des Gesamtverbrauchs für jede Stunde
    const stundenProzente = [
      0.08, 0.08, 0.08, 0.08, 0.08, 0.16,
      0.64, 0.16, 0.08, 0.08, 0.08, 0.08,
      0.64, 0.16, 0.08, 0.08, 0.08, 0.16,
      0.64, 0.80, 0.16, 0.16, 0.16, 0.08,
    ];

    const wohnflaeche = gebaeudeInfo.wohnflaeche;
    const gesamtverbrauch = 0.008 * 8760 * wohnflaeche * korrekturWert; // 8 W/m2 = Maximaler Stundenwert
    const stundenverbrauch = ((gesamtverbrauch / 8760) * stundenProzente[stunde]) / Zeitintervall;

    // Zufällige Verteilung des Verbrauchs innerhalb der Stunde
    const zufallsfaktor = Math.random();
    const verbrauch = stundenverbrauch * zufallsfaktor * (Zeitintervall / 60);

    return stundenverbrauch || 0;
  }

  useEffect(() => {
    setBerechneElektrischenVerbrauch(() => berechneElektrischenVerbrauch);
  }, [setBerechneElektrischenVerbrauch, parsedCsv, gebaeudeInfo]);

  return null;
};

export default ElektrischerVerbrauch;
