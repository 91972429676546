import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { AppProvider } from "./global/tools/Kontext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DndProvider backend={HTML5Backend}>
    <React.StrictMode>
      <BrowserRouter>
        <AppProvider>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </AppProvider>
      </BrowserRouter>
    </React.StrictMode>
  </DndProvider>
);
