import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Switch, Slider, FormControlLabel, Box, Typography, useTheme, Select, MenuItem } from '@mui/material';
import InfoButton from '../../GUI/InfoButton';

const LadestationEV = () => {
  const { setLadestationEVInfo, fahrzeugInfo, hemsVorhanden } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leistung, setLeistung] = useState(5);
  const [minEinschaltleistung, setMinEinschaltleistung] = useState(0);
  const [bidirektional, setBidirektional] = useState(false);
  const [ladestrategie, setLadestrategie] = useState('Immer vollladen');

  const fahrzeugVorhanden = fahrzeugInfo && fahrzeugInfo.fahrzeugVorhanden;
  const HEMS = hemsVorhanden.HEMS;

  useEffect(() => {
    setLadestationEVInfo({
        leistung: parseFloat(leistung),
        minEinschaltleistung: leistung * (minEinschaltleistung / 100),
        bidirektional: bidirektional,
        ladestrategie: HEMS ? ladestrategie : 'Immer vollladen',
    });
  }, [leistung, minEinschaltleistung, bidirektional, ladestrategie, HEMS]);

  return (
    <>
    {fahrzeugVorhanden ?
    <Box>
      <Typography color={colors.grey[100]} variant='h5' fontWeight='600'>
        Ladestation Elektrofahrzeug
      </Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='column'
        color={fahrzeugVorhanden ? colors.grey[100] : colors.grey[500]}
        p='15px'
      >
        <Box display="flex" justifyContent="flex-end" width="100%" gap="5px">
          <FormControlLabel
            label="Bidirektionale Ladestation"
            labelPlacement="start"
            control={
              <Switch
                checked={bidirektional}
                onChange={(e) => setBidirektional(e.target.checked)}
                color='thirdly'
                size="small"
                disabled={!fahrzeugVorhanden}
              />
            }
          />
          <InfoButton textKey="bidirektionalEV" />
        </Box>
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Ladeleistung
          </Typography>
          <InfoButton textKey="ladestationEVMaxPel" />
        </Box>
        <Slider
          value={leistung}
          min={0}
          max={50}
          step={0.1}
          onChange={(e, newValue) => {
            setLeistung(newValue);
          }}
          style={{ color: fahrzeugVorhanden ? 'blue' : colors.grey[500] }}
          disabled={!fahrzeugVorhanden}
        />
        <TextField
          size="small"
          label="kW"
          type='number'
          value={leistung}
          onChange={(e) => {
            setLeistung(Number(e.target.value));
          }}
          disabled={!fahrzeugVorhanden}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Minimale Einschaltleistung
          </Typography>
          <InfoButton textKey="ladestationEVMinPel" />
        </Box>
        <Slider
          value={minEinschaltleistung}
          min={0}
          max={100}
          step={1}
          onChange={(e, newValue) => {
            setMinEinschaltleistung(newValue);
          }}
          style={{ color: fahrzeugVorhanden ? 'green' : colors.grey[500] }}
          disabled={!fahrzeugVorhanden}
        />
        <TextField
          size="small"
          label="%"
          type='number'
          value={minEinschaltleistung}
          onChange={(e) => {
            setMinEinschaltleistung(Number(e.target.value));
          }}
          disabled={!fahrzeugVorhanden}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "7px", width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Ladestrategie
          </Typography>
          <InfoButton textKey="ladestrategieEV" />
        </Box>
        <Select fullWidth
          value={ladestrategie}
          onChange={(e) => setLadestrategie(e.target.value)}
          style={{ boxShadow: "inset 0 0 0 2px green", color: fahrzeugVorhanden ? colors.grey[100] : colors.grey[500] }}
          disabled={!fahrzeugVorhanden}
        >
          <MenuItem value='Immer vollladen'>Immer vollladen</MenuItem>
          <MenuItem value='Min vollladen'>Solar & minimale Ladelseitung (vollladen)</MenuItem>
          <MenuItem value='Max vollladen'>Solar & maximale Ladelseitung (vollladen)</MenuItem>
          <MenuItem value='Solar & minimale Ladeleistung'>Solar & minimale Ladeleistung (Mindestladung für nächstes Event)</MenuItem>
          <MenuItem value='Solar & maximale Ladeleistung'>Solar & maximale Ladeleistung (Mindestladung für nächstes Event)</MenuItem>
          <MenuItem value='Solar & Niedertarif'>Solar & Niedertarif</MenuItem>
          <MenuItem value='nur Solar'>nur Solar</MenuItem>
        </Select>
      </Box>
    </Box>
    : null}
    </>
  );
};

export default LadestationEV;
