import React, { useContext, useState } from "react";
import { Box, Button, IconButton, Typography, useTheme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ColorModeContext, tokens } from "../../../global/library/theme";
import { AppContext } from "../../../global/tools/Kontext";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import HomeIcon from "@mui/icons-material/Home";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import Header from "../../Header";
import DiagrammTotal from "../../../global/auswertung/DiagrammTotal";
import DiagrammJahr from "../../../global/auswertung/DiagrammJahr";
import DiagrammMonat from "../../../global/auswertung/DiagramMonat";
import DiagrammTag from "../../../global/auswertung/DiagrammTag";
import StatBox from "../../StatBox";
import ProgressCircle from "../../ProgressCircle";
import Systemkonfigurator from "../../../global/tools/Systemkonfigurator";
import Brauchwarmwasser from "../../../komponenten/speicher/Brauchwarmwasser";
import Heizstab from "../../../komponenten/verbraucher/Heizstab";
import Gebaude from "../../../komponenten/speicher/Gebaude";
import Pufferspeicher from "../../../komponenten/speicher/Pufferspeicher";
import Waermepumpe from "../../../komponenten/verbraucher/Warmepumpe";
import PVAnlage from "../../../komponenten/PVAnlage";
import StromNetz from "../../../komponenten/StromNetz";
import Batterie from "../../../komponenten/speicher/Batterie";
import LadestationBatt from "../../../komponenten/verbraucher/LadestationBatt";
import ElektroFahrzeug from "../../../komponenten/speicher/ElektroFahrzeug";
import LadestationEV from "../../../komponenten/verbraucher/LadestationEV";
import PrioritaetenUeberschuss from "../../../global/tools/PrioritaetenUeberschuss";
import Brennstoffheizung from "../../../komponenten/verbraucher/BrennstoffHeizung";
import ConfigBox from "../../ConfigBox";
import { DateProfileGenerator } from "@fullcalendar/core/internal";
import Anleitung from "../../../global/tools/Anleitung";
import Bericht from "../../../global/auswertung/Bericht";
//import GeographyChart from "../../components/GeographyChart";

const Dashboard = () => {
  const {csvBerechnet} = useContext(AppContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const {
    differenzWerte,
    gebaeudeInfo,
    brauchwarmwasserInfo,
    brennstoffheizungInfo,
  } = useContext(AppContext);

  const [diagrammAuswahl, setDiagrammAuswahl] = useState("Jahr");

  const getEnergieTraegerInfo = (energieTraeger) => {
    switch (energieTraeger) {
      case "Holz":
        return { bezeichnung: "Holz", einheit: " kg" };
      case "Gas":
        return { bezeichnung: "Gas", einheit: " m³" };
      case "Fernwaerme":
      case "Fernwaerme_WP":
        return { bezeichnung: "Fernwärme", einheit: " kWh" };
      default:
        return { bezeichnung: "Heizöl", einheit: " Liter" };
    }
  };

  const energieTraegerInfo = getEnergieTraegerInfo(brennstoffheizungInfo.energieTraeger);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Solar Manager" subtitle="Simulationstool" />
        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
        <Box>
          <Bericht />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="minmax(140px, auto)"
        gap="20px"
        style={{height: '85vh'}}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="15px"
        >
          <StatBox
            title={differenzWerte.pvProduktionSumme}
            subtitle="PV-Produktion kWh"
            progress="0.75"
            increase={
              differenzWerte.pvProduktionAnteil >= 0
                ? "+" + differenzWerte.pvProduktionAnteil + "%"
                : differenzWerte.pvProduktionAnteil + "%"
            }
            icon={
              <SolarPowerIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title={differenzWerte.gesamtverbrauchSumme}
            subtitle="Gesamtverbrauch kWh"
            progress="0.50"
            increase={
              differenzWerte.gesamtverbrauchAnteil >= 0
                ? "+" + differenzWerte.gesamtverbrauchAnteil + "%"
                : differenzWerte.gesamtverbrauchAnteil + "%"
            }
            icon={
              <ElectricBoltIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="15px"
        >
          {gebaeudeInfo.heizungTyp === "Waermepumpe" ||
          brauchwarmwasserInfo.waermepumpeBereitgestellt ? (
            <>
              <StatBox
                title={differenzWerte.JAZ}
                subtitle="JAZ Wärmepumpe"
                progress="0.50"
                increase={
                  differenzWerte.JAZDifferenz >= 0
                    ? "+" + differenzWerte.JAZDifferenz
                    : differenzWerte.JAZDifferenz
                }
                icon={
                  <HeatPumpIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </>
          ) : null}
          {gebaeudeInfo.heizungTyp !== "Waermepumpe" ? (
            <>
              <StatBox
                title={differenzWerte.brennstoffVerbrauchSumme}
                subtitle={`${energieTraegerInfo.bezeichnung}verbrauch${energieTraegerInfo.einheit}`}
                progress="0.50"
                increase={
                  differenzWerte.brennstoffDifferenz >= 0
                    ? `+${differenzWerte.brennstoffDifferenz}${energieTraegerInfo.einheit}`
                    : `${differenzWerte.brennstoffDifferenz}${energieTraegerInfo.einheit}`
                }
                icon={
                  <LocalFireDepartmentIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
            />
            </>
          ) : null}
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="15px"
        >
          <StatBox
            title={differenzWerte.netzbezugSumme}
            subtitle="Netzbezug kWh"
            progress="0.50"
            increase={
              differenzWerte.netzbezugAnteil >= 0
                ? "+" + differenzWerte.netzbezugAnteil + "%"
                : differenzWerte.netzbezugAnteil + "%"
            }
            icon={
              <img 
              src={process.env.PUBLIC_URL + '/powerGridBezug.png'} 
              alt="Power Grid" 
              style={{ width: "26px", height: "26px" }} 
          />
            }
          />
          <StatBox
              title={differenzWerte.einspeisungSumme}
              subtitle="Einspeisung kWh"
              progress="0.50"
              increase={
                  differenzWerte.einspeisungAnteil >= 0
                  ? "+" + differenzWerte.einspeisungAnteil + "%"
                  : differenzWerte.einspeisungAnteil + "%"
              }
              icon={
                <img 
                src={process.env.PUBLIC_URL + '/powerGridEinspeisung.png'} 
                alt="Power Grid" 
                style={{ width: "26px", height: "26px" }} 
            />
              }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="15px"
        >
          <StatBox
            title={differenzWerte.stromkostenSumme}
            subtitle="Stromkosten CHF"
            progress="0.50"
            increase={
              differenzWerte.stromkostenDiff >= 0
                ? "+" + differenzWerte.stromkostenDiff + " CHF"
                : differenzWerte.stromkostenDiff + " CHF"
            }
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title={differenzWerte.CO2}
            subtitle="CO2 Bilanz kg"
            progress="0.50"
            increase={
              differenzWerte.CO2Differenz >= 0
                ? "+" + differenzWerte.CO2Differenz + " kg"
                : differenzWerte.CO2Differenz + " kg"
            }
            icon={
              <HomeIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Systemkonfigurator />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          justifyContent="space-between"
          style={{position: 'relative'}}
        >
        { csvBerechnet && csvBerechnet.length > 0 ?
        <>
          <ToggleButtonGroup
            value={diagrammAuswahl}
            size="small"
            exclusive
            onChange={(e, newDiagramm) => { setDiagrammAuswahl(newDiagramm); }}
            aria-label="Konfigurationsauswahl"
            style={{position: 'absolute', zIndex: 2, top: 0, left: 0}}
          >
            <ToggleButton value="Total">Jahreswert</ToggleButton>
            <ToggleButton value="Jahr">Monatswerte</ToggleButton>
            <ToggleButton value="Monat">Tageswerte</ToggleButton>
            <ToggleButton value="Tag">Tagesverlauf</ToggleButton>
            <ToggleButton value="Anleitung">Anleitung</ToggleButton>
          </ToggleButtonGroup>
          <Box style={{position: 'absolute', zIndex: 1, top: 0, right: 0, bottom: 0, left: 0}}>
            {diagrammAuswahl === "Total" ? <DiagrammTotal /> : null}
            {diagrammAuswahl === "Jahr" ? <DiagrammJahr /> : null}
            {diagrammAuswahl === "Monat" ? <DiagrammMonat /> : null}
            {diagrammAuswahl === "Tag" ? <DiagrammTag /> : null}
            {diagrammAuswahl === "Anleitung" ? <Anleitung /> : null}
          </Box>
        </>
        : <Anleitung />
        }
        </Box>
        <Box gridColumn="span 4" gridRow="span 4" overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="left"
            borderBottom={`4px solid ${colors.primary[500]}`}
            pr="15px"
          >
            <ConfigBox>
              <PrioritaetenUeberschuss />
            </ConfigBox>
            <ConfigBox>
              <PVAnlage />
            </ConfigBox>
            <ConfigBox>
              <StromNetz />
            </ConfigBox>
            <ConfigBox>
              <Gebaude />
            </ConfigBox>
            {gebaeudeInfo.heizungTyp === "Waermepumpe" ? (
              <>
                <ConfigBox>
                  <Waermepumpe />
                </ConfigBox>
                <ConfigBox>
                  <Pufferspeicher />
                </ConfigBox>
              </>
            ) : (
              <>
                <ConfigBox>
                  <Brennstoffheizung />
                </ConfigBox>
              </>
            )}
            <ConfigBox>
              <Brauchwarmwasser />
              <Heizstab />
            </ConfigBox>
            {gebaeudeInfo.heizungTyp !== "Waermepumpe" &&
            brauchwarmwasserInfo.waermepumpeBereitgestellt ? (
              <>
                <ConfigBox>
                  <Waermepumpe />
                </ConfigBox>
              </>
            ) : null}
            <ConfigBox>
              <Batterie />
              <LadestationBatt />
            </ConfigBox>
            <ConfigBox>
              <ElektroFahrzeug />
              <LadestationEV />
            </ConfigBox>
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          p="5px"
        >
          <Box
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
            flex="1" // Hinzugefügt
            p="5px"
          >
            <StatBox
              title={differenzWerte.waermeRaumWP}
              subtitle="Raumwärme WP"
              progress="0.50"
              increase={
                differenzWerte.waermeRaumWPDiff >= 0
                  ? "+" + differenzWerte.waermeRaumWPDiff + " kWh"
                  : differenzWerte.waermeRaumWPDiff + " kWh"
              }
            />
            <StatBox
              title={differenzWerte.waermeWasserWP}
              subtitle="BWW Wärme WP"
              progress="0.50"
              increase={
                differenzWerte.waermeWasserWPDiff >= 0
                  ? "+" + differenzWerte.waermeWasserWPDiff + " kWh"
                  : differenzWerte.waermeWasserWPDiff + " kWh"
              }
            />
            <StatBox
              title={differenzWerte.ladeEVExtern}
              subtitle="Externes Laden EV"
              progress="0.50"
              increase={
                differenzWerte.ladeEVExternDiff >= 0
                  ? "+" + differenzWerte.ladeEVExternDiff + " kWh"
                  : differenzWerte.ladeEVExternDiff + " kWh"
              }
            />
          </Box>
          <Box
            backgroundColor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
            flex="1" // Hinzugefügt
            p="5px"
          >
            <StatBox
              title={differenzWerte.raumTempMin}
              subtitle="Minimale Raumtemp °C"
              progress="0.50"
              increase={
                differenzWerte.raumTempMinDifferenz >= 0
                  ? "+" + differenzWerte.raumTempMinDifferenz + " °C"
                  : differenzWerte.raumTempMinDifferenz + " °C"
              }
            />
            <StatBox
              title={differenzWerte.minBBWTemp}
              subtitle="Minimale BWW-Temp °C"
              progress="0.50"
              increase={
                differenzWerte.minBBWTempDifferenz >= 0
                  ? "+" + differenzWerte.minBBWTempDifferenz + " °C"
                  : differenzWerte.minBBWTempDifferenz + " °C"
              }
            />
            <StatBox
              title={differenzWerte.brennstoffKosten}
              subtitle="Brennstoffkosten CHF"
              progress="0.50"
              increase={
                differenzWerte.brennstoffKostenDifferenz >= 0
                  ? "+" + differenzWerte.brennstoffKostenDifferenz + " CHF"
                  : differenzWerte.brennstoffKostenDifferenz + " CHF"
              }
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flex="1" // Hinzugefügt
            gap="15px"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h4" fontWeight="600">
                Eigenverbrauch
              </Typography>
              <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
              >
                {differenzWerte.eigenverbrauchDifferenz >= 0
                  ? "+" +
                    Math.round(differenzWerte.eigenverbrauchDifferenz * 100) +
                    "%"
                  : Math.round(differenzWerte.eigenverbrauchDifferenz * 100) +
                    "%"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="5px"
            >
              <Box position="relative" width="80px" height="80px">
                <ProgressCircle
                  size="80"
                  progress={differenzWerte.eigenverbrauch || 0}
                />
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colors.grey[100],
                  }}
                >
                  {Math.round(differenzWerte.eigenverbrauch * 100) + "%" ||
                    0 + "%"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Autarkie box starts here */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flex="1" // Hinzugefügt
            gap="15px"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h4" fontWeight="600">
                Autarkie
              </Typography>
              <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
              >
                {differenzWerte.autarkieDifferenz >= 0
                  ? "+" +
                    Math.round(differenzWerte.autarkieDifferenz * 100) +
                    "%"
                  : Math.round(differenzWerte.autarkieDifferenz * 100) + "%"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt="5px"
            >
              <Box position="relative" width="80px" height="80px">
                <ProgressCircle
                  size="80"
                  progress={differenzWerte.autarkie || 0}
                />
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colors.grey[100],
                  }}
                >
                  {Math.round(differenzWerte.autarkie * 100) + "%" || 0 + "%"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
