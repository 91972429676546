import React, { useState, useRef, useEffect, useContext } from 'react';
import Chart from 'chart.js/auto';
import { AppContext } from '../tools/Kontext';
import { Box } from "@mui/material";

const DiagrammMonat = () => {
  const { csvBerechnet, pvAnlageInfo } = useContext(AppContext);
  const chartRef = useRef();
  const chartInstanceRef = useRef();
  const [selectedMonth, setSelectedMonth] = useState(0);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [csvBerechnet, selectedMonth]);

  function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  function fetchData() {

    if (!csvBerechnet || csvBerechnet.length === 0) return;

    const year = new Date().getFullYear();
    const daysInSelectedMonth = daysInMonth(selectedMonth, year);

    // Hier können Sie die Spalten auswählen, die Sie in Ihrem Diagramm anzeigen möchten
    const columnNames = [
      'PV-Produktion',
      'Gesamtverbrauch',
      'Einspeisung',
      'Netzbezug',
      'Gesamtverbrauch Wärmepumpe',
      'Raumwärme Wärmepumpe'
    ];

    const sumsByDay = Array.from({ length: daysInSelectedMonth }, () => Array(columnNames.length).fill(0));

    for (let i = 1; i < csvBerechnet.length; i++) {
      const row = csvBerechnet[i];
      if (!row) continue;
      const dateParts = row.Datum?.split('-');
      if (!dateParts || dateParts.length < 3) continue;
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);

      if (month === selectedMonth) {
        for (let j = 0; j < columnNames.length; j++) {
          sumsByDay[day - 1][j] += parseFloat(row[columnNames[j]]);
        }
      }
    }

    console.log('Summen nach Tagen:', sumsByDay);

    const chartData = {
      labels: Array.from({ length: daysInSelectedMonth }, (_, i) => i + 1),
      datasets: columnNames.map((name, index) => ({
        label: name,
        data: sumsByDay.map(dayData => dayData[index].toFixed(2)),
        backgroundColor: `rgba(${index * 60}, ${255 - index * 60}, ${index * 30 + 100}, 0.6)`,
        borderWidth: 1,
      })),
    };

    console.log('Diagrammdaten:', chartData);

    const chartConfig = {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(value, index, values) {
                return value + ' kWh';
              }
            }
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Tageswerte',
          },
        },
      }
    };

    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      chartInstanceRef.current = new Chart(chartRef.current, chartConfig);
    }
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" position="relative">
      <Box display="flex" justifyContent="flex-end" position="absolute" zIndex="1" right="0" top="0">
          <label htmlFor="monthSelect">Monat auswählen:</label>
          <select id="monthSelect" value={selectedMonth} onChange={handleMonthChange}>
              <option value={0}>Januar</option>
              <option value={1}>Februar</option>
              <option value={2}>März</option>
              <option value={3}>April</option>
              <option value={4}>Mai</option>
              <option value={5}>Juni</option>
              <option value={6}>Juli</option>
              <option value={7}>August</option>
              <option value={8}>September</option>
              <option value={9}>Oktober</option>
              <option value={10}>November</option>
              <option value={11}>Dezember</option>
          </select>
      </Box>
      <Box display="flex" flexDirection="column" height="100%" width="100%">
          <canvas style={{ width: '100%', height: '100%' }} ref={chartRef} />
      </Box>
    </Box>
  );
};

export default DiagrammMonat;