import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { Box, Slider, TextField, Typography, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../global/library/theme";
import InfoButton from "../../GUI/InfoButton";

const Pufferspeicher = () => {
  const { setPufferspeicherInfo, systemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [volumen, setVolumen] = useState(500);
  const [offsetMax, setOffsetMax] = useState(0);
  const [offsetMin, setOffsetmin] = useState(0);
  const [heizungssystem, setHeizungssystem] = useState("Fussbodenheizung");
  const uWert = 0.0002;

  const vorlaufTemp = (heizungssystem) => {
    switch (heizungssystem) {
      case "Fussbodenheizung":
        return 35;
      case "Heizkörper":
        return 55;
      default:
        return null;
    }
  };

  const oberflaeche = (volumen) => {
    const V_m3 = volumen * 0.001; // Umrechnung von Litern in Kubikmeter
    const s = Math.pow(V_m3, 1 / 3);
    const A_wuerfel = 6 * Math.pow(s, 2);

    const r = Math.pow((3 * V_m3) / (4 * Math.PI), 1 / 3);
    const A_kugel = 4 * Math.PI * Math.pow(r, 2);

    const A_mittel = (A_wuerfel + A_kugel) / 2;
    return A_mittel;
  };

  const speicherVerlust = uWert * oberflaeche(volumen);

  useEffect(() => {
    setPufferspeicherInfo({
      volumen: parseFloat(volumen),
      maxTemperatur: vorlaufTemp(heizungssystem) + offsetMax,
      heizungssystem: heizungssystem,
      vorlaufTemp: vorlaufTemp(heizungssystem) - offsetMin,
      speicherVerlust: speicherVerlust,
    });
  }, [volumen, offsetMax, offsetMin, heizungssystem]);

  return (
    <Box>
      <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        Pufferspeicher
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "7px", width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wärmeabgabe
          </Typography>
          <InfoButton textKey="waermeabgabe" />
        </Box>
        <Select
          fullWidth
          value={heizungssystem}
          onChange={(e) => {
            setHeizungssystem(e.target.value);
          }}
          style={{ boxShadow: "inset 0 0 0 2px blue", color: colors.grey[100] }}
        >
          <MenuItem value="Fussbodenheizung">Fußbodenheizung</MenuItem>
          <MenuItem value="Heizkörper">Heizkörper</MenuItem>
        </Select>
        <br />
      {systemkonfigurationInfo === "erweitert" ?
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Volumen
          </Typography>
          <InfoButton textKey="volumenPS" />
        </Box>
        <Slider
          value={volumen}
          min={0}
          max={5000}
          step={10}
          onChange={(e, newValue) => {
            setVolumen(newValue);
          }}
          style={{ color: 'blue' }} 
        />
        <TextField
          label='Liter'
          size="small"
          type="number"
          value={volumen}
          onChange={(e) => {
            setVolumen(Number(e.target.value));
          }}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Maximale Temperatur-Reduktion (Offset)
          </Typography>
          <InfoButton textKey="tempPSr" />
        </Box>
        <Slider
          value={offsetMin}
          min={0}
          max={20}
          step={1}
          onChange={(e, newValue) => {
            setOffsetmin(newValue);
          }}
          style={{ color: 'green' }} 
        />
        <TextField
          label='°C'
          size="small"
          type="number"
          value={offsetMin}
          onChange={(e) => {
            setOffsetmin(Number(e.target.value));
          }}
        />
        <br />
        </>
        : null }
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Maximale Temperatur-Erhöhung (Offset)
          </Typography>
          <InfoButton textKey="tempPSue" />
        </Box>
        <Slider
          value={offsetMax}
          min={0}
          max={20}
          step={1}
          onChange={(e, newValue) => {
            setOffsetMax(newValue);
          }}
          style={{ color: 'green' }} 
        />
        <TextField
          label='°C'
          size="small"
          type="number"
          value={offsetMax}
          onChange={(e) => {
            setOffsetMax(Number(e.target.value));
          }}
        />
      </Box>
    </Box>
  );
};

export default Pufferspeicher;