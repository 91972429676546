import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../global/library/theme";

const WorkflowBox = ({ image, titel, subtitel, description }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box flexGrow={1} flexBasis={0}>
      <Box backgroundColor={colors.blueAccent[700]} display="flex" flexDirection="column" justifyContent='space-between' borderRadius="15px" p="15px"  sx={{ height: '95%' }}>
        <Box>
          {titel && <Typography color={colors.grey[100]} align="center" variant="h4" fontWeight="600">{titel}</Typography>}
          {subtitel && <Typography color={colors.grey[100]} variant="h6" align="center" /* fontWeight="bold" */ fontStyle="italic">{subtitel}</Typography>}
          <br/>
          {description && <Typography color={colors.grey[200]} variant="body1" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{description}</Typography>}
        </Box>
        <Box>
          {image && <img src={image} alt="" style={{ borderRadius:'15px', width: '100%', height: 'auto' }} />}
        </Box>
      </Box>
      <br/>
    </Box>
  );
};

export default WorkflowBox;
