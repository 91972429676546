import React, { useEffect, useState, useContext } from "react";
import {Typography, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { tokens } from "../../global/library/theme";
import { useTheme } from "@mui/material";
import Rechner from "./Rechner";
import ReferenzwertManager from "./ReferenzwertManager";
import { AppContext } from "./Kontext";

const Systemkonfigurator = () => {
  const { setSystemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [konfiguration, setKonfiguration] = useState("verkuerzt");

  useEffect(() => {
    setSystemkonfigurationInfo(konfiguration);
  }, [konfiguration, setSystemkonfigurationInfo]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      color={colors.grey[100]}
      position="relative"
      p="15px"
      width="100%" // Stellen Sie sicher, dass die innere Box die gesamte Breite der äußeren Box einnimmt
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        flex={4}
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Systemkonfiguration
        </Typography>
        <Rechner />
        <ReferenzwertManager />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="end"
        flex={1}
      >
        <ToggleButtonGroup
          value={konfiguration}
          size="small"
          exclusive
          onChange={(e, newkonfiguration) => setKonfiguration(newkonfiguration)}
          aria-label="Konfigurationsauswahl"
          orientation="vertical"
        >
          <ToggleButton value="verkuerzt">standard</ToggleButton>
          <ToggleButton value="erweitert">erweitert</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default Systemkonfigurator;
