import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const SteuerungWaermepumpe = () => {
  const {
    parsedCsv,
    setBerechneWaermepumpeLeistung,
    berechneCOP,
    waermepumpeInfo,
    pufferspeicherInfo,
    brauchwarmwasserInfo,
    gebaeudeInfo,
    brennstoffheizungInfo
  } = useContext(AppContext);
  
  const Zeitintervall = parsedCsv.length / 8760;
  const spezSpeicherKapWasser = 0.001163889; // kWh/(kg*K)
  
  const minPSTemp = pufferspeicherInfo.vorlaufTemp;
  const volumenPS = pufferspeicherInfo.volumen;

  const volumenBWW = brauchwarmwasserInfo.volumen;
  const BWWvonWP = brauchwarmwasserInfo.waermepumpeBereitgestellt;

  
  const speicherKapGeb = gebaeudeInfo.speicherKapazitaet;
  const maxRaumTemp = gebaeudeInfo.maxRaumtemperatur;
  const heizgrenze = gebaeudeInfo.heizgrenze;

  const WPvorhanden = gebaeudeInfo.heizungTyp === 'Waermepumpe' ? true : false;
  const heizwertFossil = brennstoffheizungInfo.heizwert;

  const maxLeistungWP = waermepumpeInfo.maxElektrischeLeistung / Zeitintervall;
  const gueteZahlWP = waermepumpeInfo.gueteZahlWP;
  const maxTempWP = waermepumpeInfo.maxTempWP;
  const minEinschaltLeistung = waermepumpeInfo.minEinschaltLeistung / Zeitintervall;
  const kuehlenaktiv = waermepumpeInfo.kuehlenaktiv;
  const kuehlenNachBedarf = waermepumpeInfo.intelligentesKuehlen === 'automatisch' ? true : false;

  function berechneWaermepumpeLeistung(dependencies) {
    const istPSKap = parseFloat(dependencies['Kapazität Pufferspeicher']);
    const istBWWKap = parseFloat(dependencies['Kapazität Brauchwarmwasser']);
    const istRaumKap = parseFloat(dependencies['Kapazität Gebäude']);
    const minRaumTemp = parseFloat(dependencies['Minimale Raumtemperatur']);
    const minBWWTemp = parseFloat(dependencies['Minimale Warmwassertemperatur']);
    const mittlereAussenTemp = parseFloat(dependencies['Mittlere Aussentemperatur']);
    const istPSTemp = (istPSKap / (volumenPS * spezSpeicherKapWasser)) - 273;
    const istBWWTemp =(istBWWKap / (volumenBWW * spezSpeicherKapWasser)) - 273;
    const istRaumTemp =(istRaumKap / (speicherKapGeb)) - 273;
    const bedarfPS = mittlereAussenTemp < heizgrenze && istRaumTemp <= maxRaumTemp ? parseFloat(dependencies['Wärmebedarf Pufferspeicher']) : 0;
    const kuehlbedarfRaum = kuehlenaktiv && kuehlenNachBedarf ? parseFloat(dependencies['Kältebedarf Gebäude']) : 0;
    const bedarfBWW = parseFloat(dependencies['Wärmebedarf Brauchwarmwasser']);
    const diffRaum = kuehlenaktiv && kuehlenNachBedarf ? istRaumTemp - maxRaumTemp : 0;
    const diffBWW = minBWWTemp - istBWWTemp;
    const diffPS = bedarfPS > 0 ? minPSTemp - istPSTemp : 0;

    const quellenTemp = waermepumpeInfo.quellenTemp === "Aussentemperatur"
      ? parseFloat(dependencies["Aussentemperatur"])
      : waermepumpeInfo.quellenTemp;

    const { COPPS, COPBWW, COPKuehlen } = berechneCOP(istPSTemp, istBWWTemp, quellenTemp);

    if (bedarfBWW > 0 || bedarfPS > 0 || kuehlbedarfRaum > 0) {

      let kaelteRaum = 0;
      let waermeBWW = 0;
      let waermePS = 0;
      let elektrischeLeistung = 0;
      let verbrauchFossil = 0;
      let kapDiff = 0;
      let COP = 5;

        if (diffBWW > diffPS && diffBWW > diffRaum) {
          waermeBWW = bedarfBWW;
          //kapDiff = istBWWTemp - quellenTemp;
          COP = COPBWW;  //  5 - ((0.05 * kapDiff) ** 1.1) || 2.5; 5 - ((0.028 * kapDiff) ** 1.5) || 5;
        } else if (diffPS > diffBWW && diffPS > diffRaum) {
          waermePS = bedarfPS;
          //kapDiff = istPSTemp - quellenTemp;
          COP = COPPS;  //  5 - ((0.05 * kapDiff) ** 1.1) || 2.5; 5 - ((0.028 * kapDiff) ** 1.5) || 5;
        } else if (diffRaum > diffBWW && diffRaum > diffPS) {
          kaelteRaum = kuehlbedarfRaum;
          //kapDiff = 18 - quellenTemp;
          COP = COPKuehlen;  //  5 - ((0.05 * kapDiff) ** 1.1) || 2.5; 5 - ((0.028 * kapDiff) ** 1.5) || 5;
        }
  
      let benoetigteElektrischeLeistung;
      if (waermeBWW <= 0 && waermePS <= 0 && kaelteRaum <= 0) {
        benoetigteElektrischeLeistung = 0;
      } else {
        benoetigteElektrischeLeistung = (waermeBWW + waermePS + kaelteRaum) / COP > minEinschaltLeistung ? (waermeBWW + waermePS + kaelteRaum) / COP : minEinschaltLeistung;
      }
      
      elektrischeLeistung = waermepumpeInfo.inverterFunktion
        ? Math.min(benoetigteElektrischeLeistung, maxLeistungWP)
        : benoetigteElektrischeLeistung > 0 ? maxLeistungWP : 0;
  
      let bereitgestellteEnergie = elektrischeLeistung * COP;
      let waermeBWW_eff = waermeBWW > 0 ? bereitgestellteEnergie : 0;
      let waermePS_eff = waermePS > 0 ? bereitgestellteEnergie : 0;
      let kaelteRaum_eff = kaelteRaum > 0 ? bereitgestellteEnergie: 0;


      if (!WPvorhanden && !BWWvonWP){
        verbrauchFossil = (waermeBWW_eff + waermePS_eff) / heizwertFossil;
        elektrischeLeistung -= (waermeBWW_eff + waermePS_eff) / COP;
      } else if (!WPvorhanden && BWWvonWP){
        verbrauchFossil = waermePS_eff / heizwertFossil;
        elektrischeLeistung -= waermePS_eff / COP;
      }

      /* console.log('diffRaum:', diffRaum);
      console.log('diffBWW:', diffBWW);
      console.log('diffPS:', diffPS);
      console.log('waermePS_eff:', waermePS_eff);
      console.log('waermeBWW_eff:', waermeBWW_eff);
      console.log('kaelteRaum_eff:', kaelteRaum_eff);
      console.log('elektrischeLeistung:', elektrischeLeistung); */

      return { elektrischeLeistung, waermePS: waermePS_eff, waermeBWW: waermeBWW_eff, kaelteRaum: kaelteRaum_eff, verbrauchFossil: verbrauchFossil };
    }

    return  {elektrischeLeistung: 0, waermePS: 0, waermeBWW: 0, kaelteRaum: 0, verbrauchFossil: 0 };
}

  useEffect(() => {
    setBerechneWaermepumpeLeistung(() => berechneWaermepumpeLeistung);
  }, [parsedCsv,
      berechneCOP,
      waermepumpeInfo,
      pufferspeicherInfo,
      brauchwarmwasserInfo,
      gebaeudeInfo,
      brennstoffheizungInfo
  ]);

  return null;
};

export default SteuerungWaermepumpe;
