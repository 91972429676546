import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../global/tools/Kontext";
import {
  Box,
  Slider,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { tokens } from "../../global/library/theme";
import { Margin, Pattern } from "@mui/icons-material";
import InfoButton from "../../GUI/InfoButton";

const Gebaude = () => {
  const { setGebaeudeInfo, brennstoffheizungInfo, systemkonfigurationInfo } =
    useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [wohnflaeche, setWohnflaeche] = useState(140);
  const [gebaeudehuellTyp, setGebaeudehuellTyp] = useState(2000);
  const [aussenTemp, setAussenTemp] = useState(10);
  const [uWertKorrektur, setUWertKorrektur] = useState(0);
  const [stromverbrauchKorrektur, setStromverbrauchKorrektur] = useState(0);
  const [wasserverbrauchKorrektur, setWasserverbrauchKorrektur] = useState(0);
  const [heizungTyp, setHeizungTyp] = useState("Waermepumpe");
  const [minRaumtemperatur, setMinRaumtemperatur] = useState(21);
  const [heizgrenze, setHeizgrenze] = useState(20);
  const [maxRaumtemperatur, setMaxRaumtemperatur] = useState(21);
  const [nachtabsenkungStart, setNachtabsenkungStart] = useState(22);
  const [nachtabsenkungEnd, setNachtabsenkungEnd] = useState(6);
  const [nachtabsenkungAktiv, setNachtabsenkungAktiv] = useState(false);
  const [nachtabsenkungTemperatur, setNachtabsenkungTemperatur] = useState(18);
  const [personen, setPersonen] = useState(4);

  const speicherKapazitaet = 0.05 * wohnflaeche;
  const U_WertKorrekturfaktor = 1 + uWertKorrektur / 100;
  const stromverbrauchKorrekturfaktor = 1 + stromverbrauchKorrektur / 100;
  const wasserverbrauchProPersonSIA = 50;
  const wasserverbrauchKorrekturfaktor = 1 + wasserverbrauchKorrektur / 100;
  const wasserverbrauchProPerson = wasserverbrauchProPersonSIA * wasserverbrauchKorrekturfaktor;

  const U_Wert = (gebaeudehuellTyp) => {
    switch (gebaeudehuellTyp) {
      case 1980:
        return 0.001;
      case 1990:
        return 0.0008;
      case 2000:
        return 0.0005;
      case 2010:
        return 0.00035;
      case 2020:
        return 0.00015;
      default:
        return null;
    }
  };

  useEffect(() => {
    setGebaeudeInfo({
      wohnflaeche: parseFloat(wohnflaeche),
      uWert: (U_Wert(gebaeudehuellTyp) * U_WertKorrekturfaktor).toFixed(6),
      aussenTemp: aussenTemp,
      stromverbrauchKorrekturProzent: stromverbrauchKorrektur,
      stromverbrauchKorrektur: stromverbrauchKorrekturfaktor,
      heizungTyp: heizungTyp,
      heizgrenze: heizgrenze,
      minRaumtemperatur: parseFloat(minRaumtemperatur),
      maxRaumtemperatur: parseFloat(maxRaumtemperatur),
      speicherKapazitaet: speicherKapazitaet,
      speicherVerlust:
        U_Wert(gebaeudehuellTyp) * U_WertKorrekturfaktor * wohnflaeche,
      personen: personen,
      wasserverbrauchKorrekturProzent: wasserverbrauchKorrektur,
      wasserverbrauchProPerson: wasserverbrauchProPerson,
      nachtabsenkungAktiv: nachtabsenkungAktiv,
      nachtabsenkungStart: nachtabsenkungStart,
      nachtabsenkungEnd: nachtabsenkungEnd,
      nachtabsenkungTemperatur: nachtabsenkungAktiv
        ? nachtabsenkungTemperatur
        : minRaumtemperatur,
    });
  }, [
    wohnflaeche,
    gebaeudehuellTyp,
    uWertKorrektur,
    aussenTemp,
    stromverbrauchKorrekturfaktor,
    heizgrenze,
    heizungTyp,
    minRaumtemperatur,
    maxRaumtemperatur,
    personen,
    wasserverbrauchProPerson,
    nachtabsenkungAktiv,
    nachtabsenkungStart,
    nachtabsenkungEnd,
    nachtabsenkungTemperatur,
    brennstoffheizungInfo,
  ]);

  return (
    <Box>
      <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        Gebäude
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ marginBottom: "7px", textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Personen im Haushalt
          </Typography>
          <InfoButton textKey="gebaeudePersonen" />
        </Box>
        <TextField
          size="small"
          type="number"
          label="Anzahl"
          value={personen}
          onChange={(e) => setPersonen(Number(e.target.value))}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wohnfläche
          </Typography>
          <InfoButton textKey="gebaeudeWohnflaeche" />
        </Box>
        <Slider
          value={wohnflaeche}
          min={0}
          max={500}
          step={5}
          onChange={(e, newValue) => {
            setWohnflaeche(newValue);
          }}
          style={{ color: "blue" }}
        />
        <TextField
          size="small"
          label="m²"
          type="number"
          value={wohnflaeche}
          onChange={(e) => {
            setWohnflaeche(Number(e.target.value));
          }}
        />
        <br />
        {systemkonfigurationInfo === "erweitert" ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "7px", width: '100%' }}>
              <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                Sanierungsjahr Gebäudehülle
              </Typography>
              <InfoButton textKey="gebaeudeHuelle" />
            </Box>
            <Slider
              value={gebaeudehuellTyp}
              min={1980}
              max={2020}
              step={10}
              marks={[
                { value: 1980, label: "1980" },
                { value: 1990, label: "1990" },
                { value: 2000, label: "2000" },
                { value: 2010, label: "2010" },
                { value: 2020, label: "2020" },
              ]}
              onChange={(e, newValue) => {
                setGebaeudehuellTyp(newValue);
              }}
              style={{ color: "blue" }}
            />
            <br />
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
              <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                U-Wert Korrektur
              </Typography>
              <InfoButton textKey="uWertKorrektur" />
            </Box>
            <Slider
              value={uWertKorrektur}
              min={-50}
              max={50}
              step={1}
              onChange={(e, newValue) => {
                setUWertKorrektur(newValue);
              }}
              style={{ color: "blue" }}
            />
            <TextField
              size="small"
              label="W/(m²*K)"
              type="number"
              value={(
                U_Wert(gebaeudehuellTyp) *
                U_WertKorrekturfaktor *
                1000
              ).toFixed(3)}
            />
            <br />
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
              <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                Mittlere Aussentemperatur
              </Typography>
              <InfoButton textKey="temperaturAussenMittel" />
            </Box>
            <Slider
              value={aussenTemp}
              min={-5}
              max={25}
              step={0.1}
              onChange={(e, newValue) => {
                setAussenTemp(newValue);
              }}
              style={{ color: "blue" }}
            />
            <TextField
              size="small"
              label="°C"
              type="number"
              value={aussenTemp}
              onChange={(e) => {
                setAussenTemp(Number(e.target.value));
              }}
            />
            <br />
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
              <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                Stromverbrauch Korrektur
              </Typography>
              <InfoButton textKey="stromVerbrauchKorrektur" />
            </Box>
            <Slider
              value={stromverbrauchKorrektur}
              min={-50}
              max={50}
              step={1}
              onChange={(e, newValue) => {
                setStromverbrauchKorrektur(newValue);
              }}
              style={{ color: "blue" }}
            />
            <TextField
              size="small"
              label="%"
              type="number"
              value={stromverbrauchKorrektur}
              onChange={(e) => {
                setStromverbrauchKorrektur(Number(e.target.value));
              }}
            />
            <br />
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
              <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
                Wasserverbrauch Korrektur
              </Typography>
              <InfoButton textKey="wasserVerbrauchKorrektur" />
            </Box>
            <Slider
              value={wasserverbrauchKorrektur}
              min={-50}
              max={50}
              step={1}
              onChange={(e, newValue) => {
                setWasserverbrauchKorrektur(newValue);
              }}
              style={{ color: "blue" }}
            />
            <TextField
              size="small"
              label="%"
              type="number"
              value={wasserverbrauchKorrektur}
              onChange={(e) => {
                setWasserverbrauchKorrektur(Number(e.target.value));
              }}
            />
            <br />
          </>
        ) : null}
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "7px", width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Wärmeerzeugung
          </Typography>
          <InfoButton textKey="waermeerzeugung" />
        </Box>
        <Select
          fullWidth
          value={heizungTyp}
          onChange={(e) => {
            setHeizungTyp(e.target.value);
          }}
          style={{ boxShadow: "inset 0 0 0 2px blue", color: colors.grey[100] }}
        >
          <MenuItem value="Waermepumpe">Wärmepumpe</MenuItem>
          <MenuItem value="Brennstoffheizung">Brennstoffheizung</MenuItem>
        </Select>
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Raumtemperatur
          </Typography>
          <InfoButton textKey="temperaturRaum" />
        </Box>
        <Slider
          value={[minRaumtemperatur, maxRaumtemperatur]}
          min={15}
          max={30}
          step={0.5}
          onChange={(e, newValue) => {
            setMinRaumtemperatur(newValue[0]);
            setMaxRaumtemperatur(newValue[1]);
          }}
          style={{ color: "green" }}
        />
        <br />
        <TextField
          size="small"
          label="min °C"
          type="number"
          value={minRaumtemperatur}
          onChange={(e) => {
            setMinRaumtemperatur(Number(e.target.value));
          }}
        />
        <TextField
          size="small"
          label="max °C"
          type="number"
          value={maxRaumtemperatur}
          onChange={(e) => {
            setMaxRaumtemperatur(Number(e.target.value));
          }}
        />
        <br />
        {systemkonfigurationInfo === "erweitert" ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '75px' }}>
                  Nachtabsenkung
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={nachtabsenkungAktiv}
                    onChange={(e) => {
                      setNachtabsenkungAktiv(e.target.checked);
                    }}
                    color="thirdly"
                    size="small"
                  />
                }
              />
              <InfoButton textKey="nachtabsenkung" />
            </Box>
            <Slider
              value={nachtabsenkungTemperatur}
              disabled={!nachtabsenkungAktiv}
              min={10}
              max={30}
              step={1}
              onChange={(e, newValue) => {
                setNachtabsenkungTemperatur(newValue);
              }}
              style={{
                color: nachtabsenkungAktiv ? "green" : colors.grey[500],
              }}
            />
            <br />
            <TextField
              size="small"
              label="°C"
              type="number"
              disabled={!nachtabsenkungAktiv}
              value={nachtabsenkungTemperatur}
              onChange={(e) => {
                setNachtabsenkungTemperatur(Number(e.target.value));
              }}
            />
            <br />
            <Slider
              value={[nachtabsenkungStart, nachtabsenkungEnd]}
              track="inverted"
              min={0}
              max={24}
              step={0.5}
              disabled={!nachtabsenkungAktiv}
              onChange={(e, newValue) => {
                setNachtabsenkungEnd(newValue[0]);
                setNachtabsenkungStart(newValue[1]);
              }}
              style={{
                color: nachtabsenkungAktiv ? "green" : colors.grey[500],
              }}
              getAriaValueText={valuetext}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
            />

            <Typography
              color={nachtabsenkungAktiv ? colors.grey[100] : colors.grey[500]}
              variant="h6"
            >
              Beginn: {valuetext(nachtabsenkungStart) + " Uhr"}
            </Typography>

            <Typography
              color={nachtabsenkungAktiv ? colors.grey[100] : colors.grey[500]}
              variant="h6"
            >
              Ende: {valuetext(nachtabsenkungEnd) + " Uhr"}
            </Typography>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

function valuetext(value) {
  const hours = Math.floor(value);
  const minutes = (value - hours) * 60;
  return `${hours.toString().padStart(2, "0")}:${
    minutes === 0 ? "00" : minutes
  }`;
}

export default Gebaude;
