import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const SteuerungBatterie = () => {
  const {
    setBerechneBatterieLeistung,
    parsedCsv,
    batterieInfo,
    ladestationBattInfo
  } = useContext(AppContext);
  const Zeitintervall = parsedCsv.length / 8760;

  function berechneBatterieLeistung(dependencies) {
    const battKap = batterieInfo.kapazitaet;
    const maxLeistung = ladestationBattInfo.leistung / Zeitintervall;
    const istBattKap = parseFloat(dependencies['Kapazität Batteriespeicher']);
    const pvProduktion = parseFloat(dependencies['PV-Produktion']);
    const stromverbrauch = parseFloat(dependencies['Elektrische Verbaucher']);
    const verbrauchWP = parseFloat(dependencies['Verbrauch Wärmepumpe']);
    const verbrauchHS = parseFloat(dependencies['Verbrauch Heizstab']);
    const verbrauchEV = parseFloat(dependencies['Verbrauch LadestationEV']);
    const differenz = pvProduktion - (stromverbrauch + verbrauchWP + verbrauchHS + verbrauchEV);

    let lieferungBattL = 0;

    if (differenz < 0) {
      lieferungBattL = battKap >= istBattKap ? Math.min(-differenz, istBattKap * 0.9, maxLeistung) : 0;
    }

    // Entladeeffizienz berechnen, als lineare Funktion der aktuellen zur maximalen Leistung
    const entladeEffizienz = 1.1 - 0.1 * (lieferungBattL / maxLeistung);
    const entladenBatt = lieferungBattL * entladeEffizienz;

    return {entladenBatt, lieferungBattL };
}

  useEffect(() => {
    setBerechneBatterieLeistung(() => berechneBatterieLeistung);
  }, [parsedCsv,
    batterieInfo,
    ladestationBattInfo]);

  return null;
};

export default SteuerungBatterie;
