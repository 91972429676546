import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const PersonenWaerme = () => {
  const { setBerechneWaermeEintragPersonen, parsedCsv, gebaeudeInfo } = useContext(AppContext);
  const Zeitintervall = parsedCsv.length / 8760;

  function berechneWaermeEintragPersonen(dependencies) {
    const Time = dependencies['Zeit'];
    const timeParts = Time.split(':');
    const stunde = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);

    // Definiere die prozentualen Anteile des Wärmeeintrags für jede Stunde
    const stundenProzente = [
      1.00, 1.00, 1.00, 1.00, 1.00, 1.00,
      0.60, 0.40, 0.00, 0.00, 0.00, 0.00,
      0.80, 0.40, 0.00, 0.00, 0.00, 0.40,
      0.80, 0.80, 0.80, 1.00, 1.00, 1.00,
    ];

    const anzahlPersonen = gebaeudeInfo.personen;
    const waermeabgabeProPerson = 0.07 * 0.8; // Durchschnittliche Wärmeabgabe pro Person in kW und 80% Gleichzeitigkeit

    const gesamtWaermeEintrag = anzahlPersonen * waermeabgabeProPerson;
    const stundenWaermeEintrag = (gesamtWaermeEintrag * stundenProzente[stunde]) / Zeitintervall;

    // Zufällige Verteilung des Wärmeeintrags innerhalb der Stunde
    const zufallsfaktor = Math.random();
    const waermeEintrag = stundenWaermeEintrag * zufallsfaktor * (Zeitintervall / 60);

    return stundenWaermeEintrag || 0;
  }

  useEffect(() => {
    setBerechneWaermeEintragPersonen(() => berechneWaermeEintragPersonen);
  }, [setBerechneWaermeEintragPersonen, parsedCsv, gebaeudeInfo]);

  return null;
};

export default PersonenWaerme;
