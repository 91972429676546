import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';
import { tokens } from "../global/library/theme";
import { Box, Slider, TextField, Typography, useTheme, FormControlLabel, Switch } from "@mui/material";
import InfoButton from '../GUI/InfoButton';

const PVAnlage = () => {
  const { setPvAnlageInfo, systemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pvaVorhanden, setPvaVorhanden] = useState(true);
  const [spitzenleistung, setSpitzenleistung] = useState(10);
  const [spezifischerJahresertrag, setSpezifischerJahresertrag] = useState(950);

  useEffect(() => {
    setPvAnlageInfo({
      pvaVorhanden: pvaVorhanden,
      spitzenleistung: pvaVorhanden ? parseFloat(spitzenleistung) : 0,
      spezifischerJahresertrag: parseFloat(spezifischerJahresertrag),
    });
  }, [pvaVorhanden, spitzenleistung, spezifischerJahresertrag]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
          Photovoltaik-Anlage
        </Typography>
        <FormControlLabel
          label="vorhanden"
          labelPlacement="start"
          control={
            <Switch
              checked={pvaVorhanden}
              onChange={(e) => setPvaVorhanden(e.target.checked)}
              color="thirdly"
            />
          }
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={pvaVorhanden ? colors.grey[100] : colors.grey[500]}
        p="15px"
      > 
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
          Spitzenleistung
          </Typography>
          <InfoButton textKey="pvaSpitzenleistung" />
        </Box>
        <Slider
          value={spitzenleistung}
          min={0}
          max={100}
          step={1}
          disabled={!pvaVorhanden}
          onChange={(e, newValue) => {
            setSpitzenleistung(newValue);
          }}
          style={{ color: pvaVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kWp"
          type="number"
          value={spitzenleistung}
          disabled={!pvaVorhanden}
          onChange={(e) => {
            setSpitzenleistung(Number(e.target.value));
          }}
        />
      {systemkonfigurationInfo === "erweitert" ?
      <>
        <br />
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Spezifischer Ertrag
          </Typography>
          <InfoButton textKey="pvaSpezifischerErtrag" />
        </Box>
        <Slider
          value={spezifischerJahresertrag}
          min={800}
          max={1200}
          step={10}
          disabled={!pvaVorhanden}
          onChange={(e, newValue) => {
            setSpezifischerJahresertrag(newValue);
          }}
          style={{ color: pvaVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kWh/kWp pro Jahr"
          type="number"
          value={spezifischerJahresertrag}
          disabled={!pvaVorhanden}
          onChange={(e) => {
            setSpezifischerJahresertrag(Number(e.target.value));
          }}
        />
        </>
        : null }
      </Box>
    </Box>
  );
};

export default PVAnlage;