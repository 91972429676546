import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const COP = () => {
  const { setBerechneCOP, parsedCsv, waermepumpeInfo } = useContext(AppContext);

  function berechneCOP(istPSTemp, istBWWTemp, quellenTemp) {
    const tempDiffPS = (istPSTemp - quellenTemp) > 0 ? istPSTemp - quellenTemp : 0;
    const tempDiffBWW = (istBWWTemp - quellenTemp) > 0 ? istBWWTemp - quellenTemp : 0;

    const COPPS = tempDiffPS < 70 ? 6 - ((0.035 * tempDiffPS) ** 1.8) : 1;
    const COPBWW = tempDiffBWW < 70 ? 6 - ((0.035 * tempDiffBWW) ** 1.8) : 1;
    const COPKuehlen = (-0.06 * tempDiffPS) + 6.6;

    return { COPPS, COPBWW, COPKuehlen };
}

  useEffect(() => {
    setBerechneCOP(() => berechneCOP);
  }, [setBerechneCOP, parsedCsv, waermepumpeInfo]);

  return null;
};

export default COP;