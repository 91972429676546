import React, { useContext } from 'react';
import { AppContext } from './Kontext';
import { tokens } from "../../global/library/theme";
import { useTheme, Button, Box } from "@mui/material";

const ReferenzwertManager = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    setReferenceValues,
    csvBerechnet,
    setReferenceKomponentenInfo,
    pvAnlageInfo,
    stromnetzInfo,
    gebaeudeInfo,
    batterieInfo,
    ladestationBattInfo,
    fahrzeugInfo,
    ladestationEVInfo,
    waermepumpeInfo,
    pufferspeicherInfo,
    brennstoffheizungInfo,
    brauchwarmwasserInfo,
    heizstabInfo,
    hemsVorhanden,
    verbraucherPrioritaeten,
    events
  } = useContext(AppContext);

  const saveReferenceValues = () => {
    setReferenceValues([...csvBerechnet]);

    // Speichern der KomponentenInformationen
    setReferenceKomponentenInfo({
      pvAnlageInfo,
      stromnetzInfo,
      gebaeudeInfo,
      batterieInfo,
      ladestationBattInfo,
      fahrzeugInfo,
      ladestationEVInfo,
      waermepumpeInfo,
      pufferspeicherInfo,
      brennstoffheizungInfo,
      brauchwarmwasserInfo,
      heizstabInfo,
      hemsVorhanden,
      verbraucherPrioritaeten,
      events
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "12px",
          padding: "0px 5px",
          margin: "10px"
        }}
        variant="outlined"
        onClick={saveReferenceValues}
      >
        Ergebnisse als Referenz Speichern
      </Button>
    </Box>
  );
};

export default ReferenzwertManager;
