import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const Raumtemperatur = () => {
    const {
      setBerechneMinRaumTemp,
      parsedCsv,
      gebaeudeInfo
    } = useContext(AppContext);


    const minRaumTempNormal = gebaeudeInfo.minRaumtemperatur;
    const nachtabsenkungStart = gebaeudeInfo.nachtabsenkungStart;
    const nachtabsenkungEnd = gebaeudeInfo.nachtabsenkungEnd;
    const minRaumTempNA = gebaeudeInfo.nachtabsenkungTemperatur;

    let minRaumTemp = minRaumTempNormal;

    function berechneMinRaumTemp(dependencies) {
        const Time = dependencies['Zeit'];
        const timeParts = Time.split(':');
        const stunde = parseInt(timeParts[0]);

        if ((stunde >= nachtabsenkungStart && stunde <= 24) || (stunde >= 0 && stunde < nachtabsenkungEnd)) {
            minRaumTemp = minRaumTempNA;
        } else {
            minRaumTemp = minRaumTempNormal;
        }

        return minRaumTemp;
    };

    useEffect(() => {
        setBerechneMinRaumTemp(() => berechneMinRaumTemp);
        }, [parsedCsv, gebaeudeInfo]
    );

    return null;
};

    export default Raumtemperatur;