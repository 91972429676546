export const INFO_TEXTS = {
  ueberschussSeuerung: {
    title: "Überschuss-Steuerung",
    description: [
      '"Überschuss" besteht sobald mehr Strom von der PV-Anlage produziert wird als zur gleichen Zeit im Gebäude verbraucht wird.',
      "Eine Steuerung ermöglicht es, diesen Überschuss direkt im Gebäude zu verwenden um die Energiespeicher zu befüllen anstatt den Strom ins öffentliche Netz einzuspeisen.",
      'Die Prioritäten bestimmen, in welcher Reihenfolge die Speicher bedient werden, bis kein Überschuss mehr vorhanden oder alle Speicher gefüllt sind.',
    ],
    impact:
      "Netzbezug, Netzeinspeisung, Geamtverbrauch, Eigenverbrauch, Autarkie, Energiekosten, CO2-Bilanz",
      cases: [],
  },
  pvaSpitzenleistung: {
    title: "Spitzenleistung",
    description: [
      "Dieser Wert definiert die theoretisch maximale Leistung der PV-Anlage (maximale Modulleistung mal Anzahl Module).",
      "In der Praxis wird dieser Wert nicht erreicht, gibt aber Auskunft über die maximale Stromproduktion.",
    ],
    impact: "Maximale PV-Leistung",
    cases: [],
  },
  pvaSpezifischerErtrag: {
    title: "Spezifischer Ertrag",
    description: [
      "Die Jahresproduktion geteilt durch die Spitzenleistung ergibt den spezifischen Ertrag der PV-Anlage.",
      "Dieser Wert wird unter anderem von der Lage und Ausrichtung der PV-Anlage beeinflusst.",
    ],
    impact: "Jahresproduktion",
    cases: [],
  },
  gebaeudeWohnflaeche: {
    title: "Wohnfläche",
    description: [
      "Zur einfacheren Verständlichkeit wird hier die Angabe der Wohnfläche verwendet. Wenn der Wert bekannt ist, können Sie aber auch den Weert der Energiebezugsfläche (EBF) Angeben."
    ],
    impact:
      "Wärme- und Kältebedarf, Stromverbrauch, Speicherkapazität Gebäude",
      cases: [],
  },
  gebaeudePersonen: {
    title: "Anzahl Bewohner",
    description: [""],
    impact: "Wärme- und Kältebedarf, Wasserverbrauch.",
    cases: [],
  },
  gebaeudeHuelle: {
    title: "Sanierungsjahr Gebäudehülle",
    description: [
      "Die Gebäudehülle trennt den Wohnraum von der Umwelt und weisst einen spezifischen Dämmwert auf.",
      "Der Dämmwert ist vom Aufbau und den verwendeten Dämmstoffen abhängig. Grundsätzlich sind modernere Gebäude auf Grund von strengeren Auflagen besser gedämmt.",
    ],
    impact: "Wärme- und Kältebedarf",
    cases: [],
  },
  temperaturAussenMittel: {
    title: "Jahresmitteltemperatur",
    description: [
      "Die mittlere Jahrestemperatur ist Standortabhänig."
    ],
    impact: "Wärme- und Kältebedarf",
    cases: [],
  },
  uWertKorrektur: {
    title: "Korrektur U-Wert",
    description: [
      "Verändern Sie diesen Wert um den Energiebedarf für die Raumklimatisierung an Ihr Gebäudesystem anzupassen.",
    ],
    impact: "Wärme- und Kältebedarf",
    cases: [],
  },
  stromVerbrauchKorrektur: {
    title: "Korrektur Stromverbrauch",
    description: [
      "Der Stromverbrauch für Geräte wird gemäss SIA2024 in Abhängigkeit der Wohnfläche definiert.",
      "Verändern Sie diesen Wert um dem Geräte-Stromverbrauch an Ihren tatsächlichen Verbrauch anzupassen.",
    ],
    impact: "Stromverbrauch Geräte.",
    cases: [],
  },
  wasserVerbrauchKorrektur: {
    title: "Korrektur Wasserverbrauch",
    description: [
      "Der Wasserverbruach wird gemäss SIA2024 in Abhängigkeit der Anzahl Personen im Haushalt definiert.",
      "Verändern Sie diesen Wert um dem Geräte-Stromverbrauch an Ihren tatsächlichen Verbrauch anzupassen.",
      "Ausgangswert = 50 Liter Warmwasser pro Person und Tag",
    ],
    impact: "Warmwasserverbrauch",
    cases: [],
  },
  waermeerzeugung: {
    title: "Wärmeerzeugung - Heizungssystem",
    description: [
      "Das geählte System kann im Anschluss als eigene Komponente konfiguriert werden.",
      'Zentrale Wärmepumpen, welche nicht mit der eigenen PV-Anlage verbunden sind, können als "Fernwärme" deklariert werden.'
    ],
    impact: "Energieverbrauch, CO2 Bilanz",
    cases: [],
  },
  temperaturRaum: {
    title: "Raumtemperatur",
    description: [
      "Wählen Sie hier die gewünschte Mindest- bzw. Höchsttemperatur für den Wohnraum.",
      "Das Einstellen eines Temperaturfensters ermöglicht es, das Gebäude als Energiespeicher zu verwenden.",
    ],
    impact: "Raumtemperatur, Wärmeverlust",
    cases: [],
  },
  nachtabsenkung: {
    title: "Nachtabsenkung",
    description: [
      "Stellen Sie hier eine reduzierte Raumtemperatur für die Nacht ein",
      "Durch das Absenken der Raumtemperatur sinkt auch der Wärmeverlust, jedoch muss das Gebäude anschliessend auch wieder aufgeheizt werden.",
    ],
    impact: "Raumtemperatur, Wärmeverlust",
    cases: [],
  },
  kuehlFunktion: {
    title: "Aktive Kühlung",
    description: [
      "Einige Wärmepumpen können auch zur Raumkühlung verwendet werden.",
    ],
    impact: "Raumtemperatur, Gesamtverbrauch",
    cases: [],
  },
  inverterFunktion: {
    title: "Inverter Wärmepumpe",
    description: [
      "Eine Inverter Wärmepumpe kann ihre Leistungsaufnahme stufenlos regeln. Ist dies nicht der Fall, wird die Wärmepumpe nur ein- oder ausgeschaltet.",
    ],
    impact: "Eigenverbrauch",
    cases: [],
  },
  WPMaxPel: {
    title: "Maximale Leistung",
    description: [
      "Legt die maximale Leistungsaufnahme der Wärmepumpe fest.",
    ],
    impact: "Maximale Wärmeleistung",
    cases: [],
  },
  WPMinPel: {
    title: "Minimale Einschaltleistung",
    description: [
      "Legt die minimale Leistungsaufnahme der Wärmepumpe fest.",
    ],
    impact: "Eigenverbrauch",
    cases: [],
  },
  tempBWWueWP: {
    title: "Überschuss-Temperatur",
    description: [
      "Legt die maximale (durch die Wärmepumpe bereitgestellte) Brauchwarmwasser-Temperatur bei PV-Überschuss fest.",
    ],
    impact: "Eigenverbrauch",
    cases: [],
  },
  tempBWWn: {
    title: "Normale-Temperatur",
    description: [
      "Legt die (durch die Wärmepumpe bereitgestellte) Brauchwarmwasser-Temperatur während der Niedertarif-Zeit fest.",
    ],
    impact: "Energiekosten",
    cases: [],
  },
  tempBWWr: {
    title: "Reduzierte-Temperatur",
    description: [
      "Legt die (durch die Wärmepumpe bereitgestellte) Brauchwarmwasser-Temperatur während der Hochtarif-Zeit fest.",
    ],
    impact: "Energiekosten",
    cases: [],
  },
  quellenTyp: {
    title: "Quellentyp",
    description: [
      "Legt die Wermequelle der Wärmpeumpe fest.",
    ],
    impact: "Quellentemperatur",
    cases: [],
  },
  quellenTemp: {
    title: "Quellentemperatur",
    description: [
      "Legt die Temperatur der Wärmequelle fest.",
    ],
    impact: "Jahresarbeitszahl (JAZ)",
    cases: [],
  },
  heizungssystem: {
    title: "Heizungssytem",
    description: [
      "Legt fest, welches System zur Wärmebereitstellung eingesetzt wird.",
    ],
    impact: "CO2-Bilanz",
    cases: [],
  },
  waermeabgabe: {
    title: "Wärmeabgabe",
    description: [
      "Legt fest, welches System zur Wärmeverteilung eingesetzt wird und bestimmt die entsprechende Vorlauftemperatur.",
    ],
    impact: "Vorlauftemperatur",
    cases: [
      '"Heizkörper": Vorlauftemperatur = 55°C"',
      '"Fussbodenheizung": Vorlauftemperatur = 35°C"'
    ],
  },
  volumenPS: {
    title: "Volumen",
    description: [
      "Das Volumen bestimmt die Mänge an Wasser welche sich im Speicher befindet.",
    ],
    impact: "Speicherkapazität, Wärmeverlust",
    cases: [],
  },
  tempPSr: {
    title: "Temperatur-Offset reduziert",
    description: [
      "legt die minimale Temperatur unter der normalen Vorlauftemperatur fest.",
    ],
    impact: "Speicherkapazität",
    cases: [],
  },
  tempPSue: {
    title: "Temperatur-Offset erhöht",
    description: [
      "legt die maximale Temperatur über der normalen Vorlauftemperatur fest.",
    ],
    impact: "Speicherkapazität",
    cases: [],
  },
  volumenBWW: {
    title: "Volumen",
    description: [
      "Das Volumen bestimmt die Mänge an Wasser welche sich im Speicher befindet.",
    ],
    impact: "Speicherkapazität, Wärmeverlust",
    cases: [],
  },
  legionellenSchutz: {
    title: "Legionellenschutz",
    description: [
      "Bei aktiviertem Legionellenschtutz wird sichergestellt, dass das Brauchwarmwasser mindesten einmal pro Tag eine Temperatur von über 60°C erreicht.",
    ],
    impact: "Gesamtverbrauch",
    cases: [],
  },
  tempBWWueHS: {
    title: "Zieltemperatur",
    description: [
      "Legt die maximale Tempertaur des Brauchwarmwassers fest, welche durch den Heizstab erreicht werden kann.",
    ],
    impact: "Eigenverbrauch",
    cases: [],
  },
  heizstabMaxPel: {
    title: "Maximale Leistung",
    description: [
      "Legt die maximale Leistung des Heizstabes fest.",
    ],
    impact: "Eigenverbrauch",
    cases: [],
  },
  tempHSzuschalt: {
    title: "Zuschalttemperatur Heizstab",
    description: [
      "Die Zuschalttemperatur legt fest, ab welcher Brauchwarmwasser-Temperatur der Heizstab eingeschaltet werden kann.",
      "Bis zu dieser Temperatur wird das Brauchwarmwasser ausschliesslich durch das Heizsystem erwärmt."
    ],
    impact: "Einschaltzeitpunkt Heizstab, Energieeffizienz",
    cases: [],
  },
  kapBatt: {
    title: "Batteriekapazität",
    description: [
      "Die Grösse des Batteriespeicher legt fest, wieviel Energie gespeichert und anschliessend wieder bezogen werden kann.",
    ],
    impact: "Speicherkapazität",
    cases: [],
  },
  ladestationBattMaxPel: {
    title: "Maximale Leistung",
    description: [
      "Legt die maximale Leistung der Ladestation fest.",
    ],
    impact: "Ladeleistung.",
    cases: [],
  },
  kapEV: {
    title: "Fahrzeugkapazität",
    description: [
      "Die Grösse der Fahrzeugbatterie (fahrzeugspezifisch).",
    ],
    impact: "Ladezeit, Reichweite",
    cases: [],
  },
  verbrauchEV: {
    title: "Verbrauch",
    description: [
      "Analog zum Verbrauch von Verbrennerfahrzeugen, wird der Verbrauch von Elektrofahrzeugen in KWh/100km bzw. kWh/km angegeben.",
    ],
    impact: "Verbrauch E-Mobilität, Reichweite",
    cases: [],
  },
  bidirektionalEV: {
    title: "Bidirektionale Ladestation",
    description: [
      "Eine bidirektionale Ladestation ermöglicht, dass über die Ladestation auch Strom vom Fahrzeug bezogen und in das Gebäude eingespeist werden kann.",
    ],
    impact: "Autarkie, Eigenverbrauch",
    cases: [],
  },
  ladestationEVMaxPel: {
    title: "Maximale Leistung",
    description: [
      "Legt die maximale Leistung der Ladestation fest.",
    ],
    impact: "Ladeleistung",
    cases: [],
  },
  ladestationEVMinPel: {
    title: "Minimale Einschaltleistung",
    description: [
      "Legt die minimale Leistung der Ladestation fest.",
    ],
    impact: "Ladeeffizienz",
    cases: [],
  },
  ladestrategieEV: {
    title: "Ladestrategie E-Mobilität",
    description: [
      "Ermöglicht die Einstellung von Zeitbereichen, in denen ein angeschlossenes Fahrezug geladen wird.",
    ],
    impact: "Ladezeit, Ladekosten, Ladestand",
    cases: [],
  },
};
