import React, { useContext, useEffect } from 'react';
import { AppContext } from '../global/tools/Kontext';

const Strompreis = () => {
  const { setBerechneStrompreis, stromnetzInfo, parsedCsv, waermepumpeInfo } = useContext(AppContext);

  const minBWWTempNormal = waermepumpeInfo.minBWWTempNormal;
  const minBWWTempReduziert = waermepumpeInfo.minBWWTempReduziert;

  let minBWWTemp = minBWWTempNormal;
  let hochtarifAktiv = false;

  function berechneStrompreis(dependencies) {
        const Time = dependencies['Zeit'];
        const timeParts = Time.split(':');
        const stunde = parseInt(timeParts[0]);
    
        const hochtarifStart = stromnetzInfo.start;
        const hochtarifEnd = stromnetzInfo.end;
        const niedertarif = stromnetzInfo.niedertarif;
        const hochtarif = stromnetzInfo.hochtarif;
        const einspeiseverguetung = stromnetzInfo.einspeiseverguetung;

        let preis = 0;
    
        if (stunde >= hochtarifStart && stunde < hochtarifEnd) {
            preis = hochtarif;
            minBWWTemp = minBWWTempReduziert;
            hochtarifAktiv = true;
        } else {
            preis = niedertarif;
            minBWWTemp = minBWWTempNormal;
            hochtarifAktiv = false;
        }
        
        const verkaufspreis = einspeiseverguetung;

        return { preis, verkaufspreis, minBWWTemp, hochtarifAktiv };
  }

  useEffect(() => {
    setBerechneStrompreis(() => berechneStrompreis);
  }, [stromnetzInfo, setBerechneStrompreis, parsedCsv, waermepumpeInfo]);

  return null;
};

export default Strompreis;