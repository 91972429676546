import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../global/library/theme";

const ConfigBox = ({ children }) => {
  // add children prop
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box
      backgroundColor={colors.primary[400]}
      borderRadius="15px"
      p="15px"
      >
        {children} {/* render children here */}
      </Box>
      <br/>
    </Box>
  );
};

export default ConfigBox;
