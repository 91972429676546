import React, { useContext, useState, useEffect } from "react";
import { AppContext } from '../../global/tools/Kontext';
import { Box, Slider, TextField, Typography, Switch, FormControlLabel, useTheme } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { tokens } from "../../global/library/theme";

const Brennstoffheizung = () => {
  const { setBrennstoffheizungInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [energieTraeger, setenergieTraeger] = useState("Holz");
  const [preisProEinheit, setPreisProEinheit] = useState(1);

  const brennstoff = (energieTraeger) => {
    switch (energieTraeger) {
      case "Holz":
        return {belastungCO2: 0, heizwert: 4.2}; // kg/kWh , kWh/kg
      case "Gas":
        return {belastungCO2: 0.246, heizwert: 10}; // kg/kWh , kWh/m³
      case "Heizoel":
        return {belastungCO2: 0.317, heizwert: 10}; // kg/kWh , kWh/l
      case "Fernwaerme":
        return {belastungCO2: 0.246, heizwert: 1}; // kg/kWh , kWh
        case "Fernwaerme_WP":
          return {belastungCO2: 0.128, heizwert: 1}; // kg/kWh , kWh
      default:
        return {belastungCO2: 0, heizwert: 0};
    }
  };

  useEffect(() => {
    const brennstoffInfo = brennstoff(energieTraeger);
    setBrennstoffheizungInfo({
      preisProEinheit: preisProEinheit,
      energieTraeger: energieTraeger,
      belastungCO2: brennstoffInfo.belastungCO2,
      heizwert: brennstoffInfo.heizwert
    });
  }, [energieTraeger, preisProEinheit]);

  return (
    <Box>
      <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
        Brennstoffheizung
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={colors.grey[100]}
        p="15px"
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Energieträger
        </Typography>
        <br />
        <FormControl fullWidth>
            <InputLabel id="energieTraeger-label"></InputLabel>
            <Select
            labelId="energieTraeger-label"
            id="energieTraeger-select"
            value={energieTraeger}
            onChange={(e) => {
                setenergieTraeger(e.target.value);
            }}
            >
            <MenuItem value="Holz">Holz / Pellets</MenuItem>
            <MenuItem value="Gas">Gas</MenuItem>
            <MenuItem value="Heizoel">Heizöl</MenuItem>
            <MenuItem value="Fernwaerme">Fernwärme</MenuItem>
            <MenuItem value="Fernwaerme_WP">Fernwärme (zentrale Wärmepumpe)</MenuItem>
            </Select>
        </FormControl>
        <br />
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Kosten
        </Typography>
        <Slider
          value={preisProEinheit}
          min={0}
          max={5}
          step={0.01}
          onChange={(e, newValue) => {
            setPreisProEinheit(newValue);
          }}
          style={{ color: 'blue' }} 
        />
        <TextField
          size="small"
          label={"CHF / " + (energieTraeger === 'Holz' ? 'kg' : (energieTraeger === 'Fernwaerme' || energieTraeger === 'Fernwaerme_WP') ? 'kWh' : energieTraeger === 'Gas' ? 'm³' : 'Liter')}
          type="number"
          step={0.01}
          value={preisProEinheit}
          onChange={(e) => {
            setPreisProEinheit(Number(e.target.value));
          }}
        />
      </Box>
    </Box>
  );
};

export default Brennstoffheizung;
