import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../global/tools/Kontext';
import { tokens } from "../../global/library/theme";
import { TextField, Slider, Box, Typography, useTheme } from '@mui/material';
import InfoButton from "../../GUI/InfoButton";

const LadestationBatt = () => {
  const { setLadestationBattInfo, batterieInfo, systemkonfigurationInfo } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leistung, setLeistung] = useState(5);

  const batterieVorhanden = batterieInfo && batterieInfo.batterieVorhanden;

  useEffect(() => {
    setLadestationBattInfo({
        leistung: parseFloat(leistung)
    });
  }, [leistung]);

  return (
    <>
    {batterieVorhanden && systemkonfigurationInfo === "erweitert" ?
    <Box>
      <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
        Ladestation Batterie
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        color={batterieVorhanden ? colors.grey[100] : colors.grey[500]}
        p="15px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
          <Typography color={colors.grey[100]} variant='h6' style={{ textAlign: 'center', flex: 1, marginLeft: '30px' }}>
            Ladelseitung
          </Typography>
          <InfoButton textKey="ladestationBattMaxPel" />
        </Box>
        <Slider
          value={leistung}
          min={0}
          max={50}
          step={0.1}
          disabled={!batterieVorhanden}
          onChange={(e, newValue) => {
            setLeistung(newValue);
          }}
          style={{ color: batterieVorhanden ? 'blue' : colors.grey[500] }} 
        />
        <TextField
          size="small"
          label="kW"
          type="number"
          value={leistung}
          disabled={!batterieVorhanden}
          onChange={(e) => {
            setLeistung(Number(e.target.value));
          }}
        />
      </Box>
    </Box>
    : null}
    </>
  );
};

export default LadestationBatt;
