import { useContext } from 'react';
import { AppContext } from '../tools/Kontext';

export const useComponents = () => {
    const context = useContext(AppContext); // Zugriff auf den Kontext

    if (!context.referenceKomponentenInfo.stromnetzInfo) {
      return []; // Oder geben Sie hier einen Standardwert zurück
    }

    return [
      {
        title: 'ÜS-Steuerung',
        fields: [
          {
            label: 'Aktiv',
            refValue: context?.referenceKomponentenInfo?.hemsVorhanden?.HEMS,
            currentValue: context?.hemsVorhanden?.HEMS,
            unit: '-'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'PV-Anlage',
        fields: [
          {
            label: 'Spitzenleistung',
            refValue: context?.referenceKomponentenInfo?.pvAnlageInfo?.spitzenleistung,
            currentValue: context?.pvAnlageInfo?.spitzenleistung,
            unit: 'kWp'
          },
          {
            label: 'Spezifischer Jahresertrag',
            refValue: context?.referenceKomponentenInfo?.pvAnlageInfo?.spezifischerJahresertrag,
            currentValue: context?.pvAnlageInfo?.spezifischerJahresertrag,
            unit: 'kWh/kWp'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Stromnetz',
        fields: [
          {
            label: 'Hochtarif',
            refValue: (context?.referenceKomponentenInfo?.stromnetzInfo?.hochtarif * 100).toFixed(2),
            currentValue: (context?.stromnetzInfo?.hochtarif * 100).toFixed(2),
            unit: 'Rp.'
          },
          {
            label: 'Niedertarif',
            refValue: (context?.referenceKomponentenInfo?.stromnetzInfo?.niedertarif * 100).toFixed(2),
            currentValue: (context?.stromnetzInfo?.niedertarif * 100).toFixed(2),
            unit: 'Rp.'
          },
          {
            label: 'Einspeisevergütung',
            refValue: (context?.referenceKomponentenInfo?.stromnetzInfo?.einspeiseverguetung * 100).toFixed(2),
            currentValue: (context?.stromnetzInfo?.einspeiseverguetung * 100).toFixed(2),
            unit: 'Rp.'
          },
          {
            label: 'Hochtarif Beginn',
            refValue: context?.referenceKomponentenInfo?.stromnetzInfo?.start,
            currentValue: context?.stromnetzInfo?.start,
            unit: 'Stunde'
          },
          {
            label: 'Hochtarif Ende',
            refValue: context?.referenceKomponentenInfo?.stromnetzInfo?.end,
            currentValue: context?.stromnetzInfo?.end,
            unit: 'Stunde'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Geäude',
        fields: [
          {
            label: 'Personen im Haushalt',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.personen,
            currentValue: context?.gebaeudeInfo?.personen,
            unit: 'Anzahl'
          },
          {
            label: 'Wohnfläche',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.wohnflaeche,
            currentValue: context?.gebaeudeInfo?.wohnflaeche,
            unit: 'm²'
          },
          {
            label: 'U-Wert',
            refValue: (context?.referenceKomponentenInfo?.gebaeudeInfo?.uWert * 1000).toFixed(3),
            currentValue: (context?.gebaeudeInfo?.uWert * 1000).toFixed(3),
            unit: 'W/(m²*K)'
          },
          {
            label: 'Mittlere Aussentemperatur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.aussenTemp,
            currentValue: context?.gebaeudeInfo?.aussenTemp,
            unit: '°C'
          },
          {
            label: 'Stromverbrauch Korrektur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.stromverbrauchKorrekturProzent,
            currentValue: context?.gebaeudeInfo?.stromverbrauchKorrekturProzent,
            unit: '%'
          },
          {
            label: 'Wasserverbrauch Korrektur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.wasserverbrauchKorrekturProzent,
            currentValue: context?.gebaeudeInfo?.wasserverbrauchKorrekturProzent,
            unit: '%'
          },
          {
            label: 'Wärmeerzeugung',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.heizungTyp,
            currentValue: context?.gebaeudeInfo?.heizungTyp,
            unit: '-'
          },
          {
            label: 'Minimale-Raumtemperatur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.minRaumtemperatur,
            currentValue: context?.gebaeudeInfo?.minRaumtemperatur,
            unit: '°C'
          },
          {
            label: 'Maximale-Raumtemperatur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.maxRaumtemperatur,
            currentValue: context?.gebaeudeInfo?.maxRaumtemperatur,
            unit: '°C'
          },
          {
            label: 'Nachtabsenkung',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.nachtabsenkungAktiv,
            currentValue: context?.gebaeudeInfo?.nachtabsenkungAktiv,
            unit: '-'
          },
          {
            label: 'Nachtabsenkung Temperatur',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.nachtabsenkungTemperatur,
            currentValue: context?.gebaeudeInfo?.nachtabsenkungTemperatur,
            unit: '°C'
          },
          {
            label: 'Nachtabsenkung Beginn',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.nachtabsenkungStart,
            currentValue: context?.gebaeudeInfo?.nachtabsenkungStart,
            unit: 'Stunde'
          },
          {
            label: 'Nachtabsenkung Ende',
            refValue: context?.referenceKomponentenInfo?.gebaeudeInfo?.nachtabsenkungEnd,
            currentValue: context?.gebaeudeInfo?.nachtabsenkungEnd,
            unit: 'Stunde'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Wärmepumpe',
        fields: [
          {
            label: 'Quellentyp',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.quellenTyp,
            currentValue: context?.waermepumpeInfo?.quellenTyp,
            unit: '-'
          },
          {
            label: 'Quellentemperatur',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.quellenTemp,
            currentValue: context?.waermepumpeInfo?.quellenTemp,
            unit: '°C'
          },
          {
            label: 'Maximale elektrische Leistung',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.maxElektrischeLeistung,
            currentValue: context?.waermepumpeInfo?.maxElektrischeLeistung,
            unit: 'kW'
          },
          {
            label: 'Inverter Wärmepumpe',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.inverterFunktion,
            currentValue: context?.waermepumpeInfo?.inverterFunktion,
            unit: '-'
          },
          {
            label: 'Minimale Einschaltleistung',
            refValue: (context?.referenceKomponentenInfo?.waermepumpeInfo?.minEinschaltLeistung).toFixed(2),
            currentValue: (context?.waermepumpeInfo?.minEinschaltLeistung).toFixed(2),
            unit: 'kW'
          },
          {
            label: 'Aktive Kühlung',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.kuehlenaktiv,
            currentValue: context?.waermepumpeInfo?.kuehlenaktiv,
            unit: '-'
          },
          {
            label: 'Intelligentes Kühlen',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.intelligentesKuehlen,
            currentValue: context?.waermepumpeInfo?.intelligentesKuehlen,
            unit: '-'
          },
          {
            label: 'BWW-Temp. Überschuss',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.maxBBWTempUeberschuss,
            currentValue: context?.waermepumpeInfo?.maxBBWTempUeberschuss,
            unit: '°C'
          },
          {
            label: 'BWW-Temp. Normal',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.minBWWTempNormal,
            currentValue: context?.waermepumpeInfo?.minBWWTempNormal,
            unit: '°C'
          },
          {
            label: 'BWW-Temp. Reduziert',
            refValue: context?.referenceKomponentenInfo?.waermepumpeInfo?.minBWWTempReduziert,
            currentValue: context?.waermepumpeInfo?.minBWWTempReduziert,
            unit: '°C'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Brennstoffheizung',
        fields: [
          {
            label: 'Energieträger',
            refValue: context?.referenceKomponentenInfo?.brennstoffheizungInfo?.energieTraeger,
            currentValue: context?.brennstoffheizungInfo?.energieTraeger,
            unit: '-'
          },
          {
            label: 'Kosten',
            refValue: context?.referenceKomponentenInfo?.brennstoffheizungInfo?.preisProEinheit,
            currentValue: context?.brennstoffheizungInfo?.preisProEinheit,
            unit: 'CHF'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Pufferspeicher',
        fields: [
          {
            label: 'Wärmeabgabe',
            refValue: context?.referenceKomponentenInfo?.pufferspeicherInfo?.heizungssystem,
            currentValue: context?.pufferspeicherInfo?.heizungssystem,
            unit: '-'
          },
          {
            label: 'Volumen',
            refValue: context?.referenceKomponentenInfo?.pufferspeicherInfo?.volumen,
            currentValue: context?.pufferspeicherInfo?.volumen,
            unit: 'Liter'
          },
          {
            label: 'Minimale Vorlauftemperatur',
            refValue: context?.referenceKomponentenInfo?.pufferspeicherInfo?.vorlaufTemp,
            currentValue: context?.pufferspeicherInfo?.vorlaufTemp,
            unit: '°C'
          },
          {
            label: 'Maximale Vorlauftemperatur',
            refValue: context?.referenceKomponentenInfo?.pufferspeicherInfo?.maxTemperatur,
            currentValue: context?.pufferspeicherInfo?.maxTemperatur,
            unit: '°C'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Brauchwarmwasser',
        fields: [
          {
            label: 'Volumen',
            refValue: context?.referenceKomponentenInfo?.brauchwarmwasserInfo?.volumen,
            currentValue: context?.brauchwarmwasserInfo?.volumen,
            unit: 'Liter'
          },
          {
            label: 'Legionellenschutz',
            refValue: context?.referenceKomponentenInfo?.brauchwarmwasserInfo?.legionellenSchutz,
            currentValue: context?.brauchwarmwasserInfo?.legionellenSchutz,
            unit: 'Liter'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Heizstab',
        fields: [
          {
            label: 'ÜS-Steuerung mit Heizstab',
            refValue: context?.referenceKomponentenInfo?.heizstabInfo?.heizstabUeberschuss,
            currentValue: context?.heizstabInfo?.heizstabUeberschuss,
            unit: '-'
          },
          {
            label: 'Leistung',
            refValue: context?.referenceKomponentenInfo?.heizstabInfo?.leistung,
            currentValue: context?.heizstabInfo?.leistung,
            unit: 'kW'
          },
          {
            label: 'Zuschalttemperatur',
            refValue: context?.referenceKomponentenInfo?.heizstabInfo?.einschalttemperatur,
            currentValue: context?.heizstabInfo?.einschalttemperatur,
            unit: '°C'
          },
          {
            label: 'Zieltemperatur bei Überschuss',
            refValue: context?.referenceKomponentenInfo?.heizstabInfo?.maxBWWTempUeberschuss,
            currentValue: context?.heizstabInfo?.maxBWWTempUeberschuss,
            unit: '°C'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Batteriespeicher',
        fields: [
          {
            label: 'Vorhanden',
            refValue: context?.referenceKomponentenInfo?.batterieInfo?.batterieVorhanden,
            currentValue: context?.batterieInfo?.batterieVorhanden,
            unit: '-'
          },
          {
            label: 'Kapazität',
            refValue: context?.referenceKomponentenInfo?.batterieInfo?.kapazitaet,
            currentValue: context?.batterieInfo?.kapazitaet,
            unit: 'kWh'
          },
          {
            label: 'Ladelseitung',
            refValue: context?.referenceKomponentenInfo?.ladestationBattInfo?.leistung,
            currentValue: context?.ladestationBattInfo?.leistung,
            unit: 'kW'
          },
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      {
        title: 'Elektrofahrzeug',
        fields: [
          {
            label: 'Vorhanden',
            refValue: context?.referenceKomponentenInfo?.fahrzeugInfo?.fahrzeugVorhanden,
            currentValue: context?.fahrzeugInfo?.fahrzeugVorhanden,
            unit: '-'
          },
          {
            label: 'Kapazität',
            refValue: context?.referenceKomponentenInfo?.fahrzeugInfo?.kapazitaet,
            currentValue: context?.fahrzeugInfo?.kapazitaet,
            unit: 'kWh'
          },
          {
            label: 'Verbrauch',
            refValue: context?.referenceKomponentenInfo?.fahrzeugInfo?.verbrauch,
            currentValue: context?.fahrzeugInfo?.verbrauch,
            unit: 'kWh/km'
          },
          {
            label: 'Bidirektionale Ladestation',
            refValue: context?.referenceKomponentenInfo?.ladestationEVInfo?.bidirektional,
            currentValue: context?.ladestationEVInfo?.bidirektional,
            unit: '-'
          },
          {
            label: 'Maximale Ladeleistung',
            refValue: context?.referenceKomponentenInfo?.ladestationEVInfo?.leistung,
            currentValue: context?.ladestationEVInfo?.leistung,
            unit: 'kW'
          },
          {
            label: 'Minimale Ladeleistung',
            refValue: (context?.referenceKomponentenInfo?.ladestationEVInfo?.minEinschaltleistung).toFixed(2),
            currentValue: (context?.ladestationEVInfo?.minEinschaltleistung).toFixed(2),
            unit: 'kW'
          },
          {
            label: 'Ladestrategie',
            refValue: context?.referenceKomponentenInfo?.ladestationEVInfo?.ladestrategie,
            currentValue: context?.ladestationEVInfo?.ladestrategie,
            unit: '-'
          },
          /* {
            label: 'Mobilitätsprofil',
            refValue: context?.referenceKomponentenInfo?.ladestationEVInfo?.ladestrategie,
            currentValue: context?.events?.event,
            unit: '-'
          }, */
          // Fügen Sie hier weitere Felder hinzu...
        ],
        differenzWert: (context?.differenzWerte?.pvProduktionDiff || 0) + " kWh pro Jahr"
      },
      // Fügen Sie hier weitere Komponenten hinzu...
    ];
};


export const useKPIs = () => {
  const context = useContext(AppContext); // Zugriff auf den Kontext

  return [
    {
      title: 'KPIs',
      fields: [
        {
          label: 'PV-Produktion',
          improvement: "+",
          refValue: context?.differenzWerte?.pvProduktionSummeRef,
          currentValue: context?.differenzWerte?.pvProduktionSumme,
          unit: 'KWh'
        },
        {
          label: 'Gesamtverbrauch',
          improvement: "-",
          refValue: context?.differenzWerte?.gesamtverbrauchSummeRef,
          currentValue: context?.differenzWerte?.gesamtverbrauchSumme,
          unit: 'kWh'
        },
        {
          label: 'Einspeisung',
          improvement: "-",
          refValue: context?.differenzWerte?.einspeisungSummeRef,
          currentValue: context?.differenzWerte?.einspeisungSumme,
          unit: 'kWh'
        },
        {
          label: 'Netzbezug',
          improvement: "-",
          refValue: context?.differenzWerte?.netzbezugSummeRef,
          currentValue: context?.differenzWerte?.netzbezugSumme,
          unit: 'kWh'
        },
        {
          label: 'JAZ Wärmepumpe',
          improvement: "+",
          refValue: context?.differenzWerte?.JAZRef,
          currentValue: context?.differenzWerte?.JAZ,
          unit: '-'
        },
        {
          label: 'Kosten Strom',
          improvement: "-",
          refValue: context?.differenzWerte?.stromkostenSummeRef,
          currentValue: context?.differenzWerte?.stromkostenSumme,
          unit: 'CHF'
        },
        {
          label: 'Kosten fossile Brennstoffe',
          improvement: "-",
          refValue: context?.differenzWerte?.brennstoffKostenRef,
          currentValue: context?.differenzWerte?.brennstoffKosten,
          unit: 'CHF'
        },
        /* {
          label: 'CO2 Bilanz',
          improvement: "-",
          refValue: context?.differenzWerte?.CO2Ref,
          currentValue: context?.differenzWerte?.CO2,
          unit: 'kg'
        }, */
        {
          label: 'Energiebedarf Raumwärme',
          improvement: "-",
          refValue: context?.differenzWerte?.waermeRaumWPRef,
          currentValue: context?.differenzWerte?.waermeRaumWP,
          unit: 'kWh'
        },
        {
          label: 'Energiebedarf Raumkälte',
          improvement: "-",
          refValue: context?.differenzWerte?.kaelteRaumRef,
          currentValue: context?.differenzWerte?.kaelteRaum,
          unit: 'kWh'
        },
        {
          label: 'Energiebedarf Warmwasser',
          improvement: "-",
          refValue: context?.differenzWerte?.waermeWasserWPRef,
          currentValue: context?.differenzWerte?.waermeWasserWP,
          unit: 'kWh'
        },
        {
          label: 'Warmwasserverbrauch',
          improvement: "-",
          refValue: context?.differenzWerte?.wasserVerbrauchRef,
          currentValue: context?.differenzWerte?.wasserVerbrauch,
          unit: 'Liter'
        },
        {
          label: 'Min. Temperatur Raum',
          improvement: "+",
          refValue: context?.differenzWerte?.raumTempMinRef,
          currentValue: context?.differenzWerte?.raumTempMin,
          unit: '°C'
        },
        {
          label: 'Max. Temperatur Raum',
          improvement: "-",
          refValue: context?.differenzWerte?.raumTempMaxRef,
          currentValue: context?.differenzWerte?.raumTempMax,
          unit: '°C'
        },
        {
          label: 'Mittel Temperatur Raum',
          improvement: "+",
          refValue: context?.differenzWerte?.raumTempMittelRef,
          currentValue: context?.differenzWerte?.raumTempMittel,
          unit: '°C'
        },
        {
          label: 'Min. Temperatur Warmwasser',
          improvement: "+",
          refValue: context?.differenzWerte?.minBBWTempRef,
          currentValue: context?.differenzWerte?.minBBWTemp,
          unit: '°C'
        },
        {
          label: 'Max. Temperatur Warmwasser',
          improvement: "+",
          refValue: context?.differenzWerte?.maxBBWTempRef,
          currentValue: context?.differenzWerte?.maxBBWTemp,
          unit: '°C'
        },
        {
          label: 'Mittel Temperatur Warmwasser',
          improvement: "+",
          refValue: context?.differenzWerte?.BWWMittelRef,
          currentValue: context?.differenzWerte?.BWWTempMittel,
          unit: '°C'
        },
        {
          label: 'Verbrauch Wärmepumpe',
          improvement: "-",
          refValue: context?.differenzWerte?.verbrauchWPSummeRef,
          currentValue: context?.differenzWerte?.verbrauchWPSumme,
          unit: 'kWh'
        },
        {
          label: 'Verbrauch Heizstab',
          improvement: "-",
          refValue: context?.differenzWerte?.verbrauchHSSummeRef,
          currentValue: context?.differenzWerte?.verbrauchHSSumme,
          unit: 'kWh'
        },
        {
          label: 'Verbrauch el. Geräte',
          improvement: "-",
          refValue: context?.differenzWerte?.verbrauchGeraeteSummeRef,
          currentValue: context?.differenzWerte?.verbrauchGeraeteSumme,
          unit: 'kWh'
        },
        {
          label: 'Verbrauch Brennstoffheizung',
          improvement: "-",
          refValue: context?.differenzWerte?.brennstoffVerbrauchSummeRef,
          currentValue: context?.differenzWerte?.brennstoffVerbrauchSumme,
          unit: 'kg/l/m³/kWh'
        },
        {
          label: 'Verbrauch Ladung E-Mobilität',
          improvement: "-",
          refValue: context?.differenzWerte?.verbrauchLadestationEVRef,
          currentValue: context?.differenzWerte?.verbrauchLadestationEV,
          unit: 'kWh'
        },
        {
          label: 'Ladung E-Mobilität',
          improvement: "+",
          refValue: context?.differenzWerte?.ladungEVRef,
          currentValue: context?.differenzWerte?.ladungEV,
          unit: 'kWh'
        },
        {
          label: 'Ladeeffizient E-Mobilität',
          improvement: "+",
          refValue: context?.differenzWerte?.ladeEffizienzEVRef * 100,
          currentValue: context?.differenzWerte?.ladeEffizienzEV * 100,
          unit: '%'
        },
        {
          label: 'Ladung E-Mobilität extern',
          improvement: "-",
          refValue: context?.differenzWerte?.ladeEVExternRef,
          currentValue: context?.differenzWerte?.ladeEVExtern,
          unit: 'kWh'
        },
        {
          label: 'Fahrleistung E-Mobilität',
          improvement: "",
          refValue: context?.differenzWerte?.streckeEVRef,
          currentValue: context?.differenzWerte?.streckeEV,
          unit: 'km'
        },
        // Fügen Sie hier weitere Felder hinzu...
      ],
    },
  ];
};