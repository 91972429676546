import React, { useContext, useState } from 'react';
import { AppContext } from './Kontext';
import { useTheme, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { tokens } from "../../global/library/theme";

const Rechner = () => {
  const { parsedCsv, setCsvBerechnet, pvAnlageInfo, stromnetzInfo, gebaeudeInfo, pufferspeicherInfo, batterieInfo, fahrzeugInfo, brauchwarmwasserInfo, berechneWasserverbrauch, berechneMinRaumTemp, berechneElektrischenVerbrauch, berechneWaermeEintragPersonen, berechneExternerWaermeeintrag, berechneWaermepumpeLeistung, berechneHeizstabLeistung, berechneBatterieLeistung, berechneLadenEV, berechneEntladenEV, berechneMobilitaetsProfil, berechneStrompreis, berechneUeberschussVerbrauch } = useContext(AppContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Global //
  const Zeitintervall = parsedCsv.length / 8760; // Anzahl Zeilen / Stunden pro Jahr
  const spezSpeicherKapWasser = 0.001163889; // kWh/(kg*K)
  const mittlereAussentemp = (parsedCsv.slice(1).reduce((acc, curr) => acc + parseFloat(curr['Aussentemp']), 0)) / parsedCsv.length;
  const korrekturFaktorTemp = mittlereAussentemp - gebaeudeInfo.aussenTemp;
  // PV-Anlage //
  const spezifischerJahresertrag = pvAnlageInfo.spezifischerJahresertrag;
  const spitzenleistung = pvAnlageInfo.spitzenleistung;
  const produktionSumme = parsedCsv.slice(1).reduce((acc, curr) => acc + parseFloat(curr['Produktion']), 0);
  const korrekturFaktorPV = (spitzenleistung * spezifischerJahresertrag) / produktionSumme;
  // Gebäude //
  const heizgrenze = gebaeudeInfo.heizgrenze;
  const WPHeizung = gebaeudeInfo.heizungTyp === "Waermepumpe" ? true : false;
  const minRaumTempNormal = gebaeudeInfo.minRaumtemperatur;
  const maxRaumTemp = gebaeudeInfo.maxRaumtemperatur;
  const speicherVerGeb = gebaeudeInfo.speicherVerlust / Zeitintervall;
  const speicherKapGeb = gebaeudeInfo.speicherKapazitaet;
  // Pufferspeicher //
  const minPSTemp = pufferspeicherInfo.vorlaufTemp;
  const maxPSTemp = pufferspeicherInfo.maxTemperatur;
  const speicherVerPS = pufferspeicherInfo.speicherVerlust / Zeitintervall;
  const speicherKapPS = pufferspeicherInfo.volumen * spezSpeicherKapWasser;
  // Brauchwarmwasser //
  const minBWWTempNormal = brauchwarmwasserInfo.minTemperatur;
  const BWWvonWP = brauchwarmwasserInfo.waermepumpeBereitgestellt;
  const maxBWWTemp = brauchwarmwasserInfo.maxTemperatur;
  const speicherVerBWW = brauchwarmwasserInfo.speicherVerlust / Zeitintervall;
  const speicherKapBWW = brauchwarmwasserInfo.volumen * spezSpeicherKapWasser;
  // Batteriespeicher //
  const speicherKapBatt = batterieInfo.kapazitaet;
  // E-Fahrzeug //
  const speicherKapEV = fahrzeugInfo.kapazitaet;
  
  let minRaumTemp = minRaumTempNormal;
  let istRaumKap = speicherKapGeb * (minRaumTempNormal + 273);
  let istPSKap = speicherKapPS * (minPSTemp + 273);
  let minBWWTemp = minBWWTempNormal;
  let istBWWKap = speicherKapBWW * (minBWWTempNormal + 273);
  let istBattKap = speicherKapBatt * 0.5;
  let istEVKap = speicherKapEV * 0.5;

  let counter = 0;

  const calculations = [

    // --Profile---------------------------------------------------------------- //
   
    {
      columnName: 'Counter',
      formula: (dependencies) => {
        return counter;
      },
      dependencies: [],
    },
    {
      columnName: 'PV-Produktion',
      formula: (dependencies) => parseFloat(dependencies['Produktion']) * korrekturFaktorPV,
      dependencies: ['Produktion'],
    },
    {
      columnName: 'Aussentemperatur',
      formula: (dependencies) => parseFloat(dependencies['Aussentemp']) - korrekturFaktorTemp,
      dependencies: ['Aussentemp'],
    },
    {
      columnName: 'Hochtarif',
      formula: (dependencies) => {
        const hochtarif = berechneStrompreis(dependencies);
        return hochtarif.hochtarifAktiv;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Strompreis',
      formula: (dependencies) => {
        const strompreis = berechneStrompreis(dependencies);
        return strompreis.preis;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Minimale Raumtemperatur',
      formula: (dependencies) => {
        const minRaumTempneu = berechneMinRaumTemp(dependencies);
        minRaumTemp = minRaumTempneu
        return minRaumTempneu;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Minimale Warmwassertemperatur',
      formula: (dependencies) => {
        const minBWWTempNeu = berechneStrompreis(dependencies);
        minBWWTemp = minBWWTempNeu.minBWWTemp;
        return minBWWTemp;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Warmwasserverbrauch',
      formula: (dependencies) => {
        const warmwasserverbrauch = berechneWasserverbrauch(dependencies);
        return warmwasserverbrauch;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Elektrische Verbaucher',
      formula: (dependencies) => {
        const stromverbrauch = berechneElektrischenVerbrauch(dependencies);
        return stromverbrauch;
      },
      dependencies: ['Zeit'],
    },
    {
      columnName: 'Personen Wärmeeintrag',
      formula: (dependencies) => {
        const personenWaerme = berechneWaermeEintragPersonen(dependencies);
        return personenWaerme;
      },
      dependencies: ['Zeit'],
    },
        {
      columnName: 'Externer Wärmeeintrag',
      formula: (dependencies) => {
        const externerWaermeeintrag = berechneExternerWaermeeintrag(dependencies);
        return externerWaermeeintrag;
      },
      dependencies: ['Produktion'],
    },
    {
      columnName: 'Wärmeeintrag',
      formula: (dependencies) => {
        const geraeteWaerme = parseFloat(dependencies['Elektrische Verbaucher']) * 0.5;
        const personenWaerme = parseFloat(dependencies['Personen Wärmeeintrag']);
        const externerWaermeeintrag = parseFloat(dependencies['Externer Wärmeeintrag']);
        const waermeeintrag = geraeteWaerme + personenWaerme + externerWaermeeintrag;
        return waermeeintrag;
      },
      dependencies: ['Elektrische Verbaucher', 'Personen Wärmeeintrag', 'Externer Wärmeeintrag'],
    },
    {
      columnName: 'EV an Ladestation',
      formula: (dependencies) => {
        const anLadestation = berechneMobilitaetsProfil(dependencies.Datum, dependencies.Zeit, ++counter);
        //console.log(anLadestation);
        return anLadestation.anLadestation;
      },
      dependencies: ['Datum', 'Zeit'],
    },
    {
      columnName: 'Fahrleistung E-Mobilität',
      formula: (dependencies) => {
        const fahrleistung = berechneMobilitaetsProfil(dependencies.Datum, dependencies.Zeit, counter);
        return fahrleistung.fahrleistung;
      },
      dependencies: ['Datum', 'Zeit'],
    },
    {
      columnName: 'Verbrauch E-Mobilität',
      formula: (dependencies) => {
        const verbrauch = berechneMobilitaetsProfil(dependencies.Datum, dependencies.Zeit, counter);
        return verbrauch.verbrauch;
      },
      dependencies: ['Datum', 'Zeit'],
    },
    {
      columnName: 'Min-Ladestand E-Mobilität',
      formula: (dependencies) => {
        const minLadestand = berechneMobilitaetsProfil(dependencies.Datum, dependencies.Zeit, counter);
        return minLadestand.bedarfLadestand ? minLadestand.bedarfLadestand : 0;
      },
      dependencies: ['Datum', 'Zeit'],
    },
    {
      columnName: 'Zeit bis Event E-Mobilität',
      formula: (dependencies) => {
        const zeit = berechneMobilitaetsProfil(dependencies.Datum, dependencies.Zeit, counter);
        return zeit.zeitBisNaechstesEreignis;
      },
      dependencies: ['Datum', 'Zeit'],
    },
    
    

    // --Speicherzustände---------------------------------------------------------------- //
    {
      columnName: 'Kapazität Gebäude',
      formula: (dependencies) => {
        const neueRaumKap = istRaumKap;
        return neueRaumKap;
      },
      dependencies: [],
    },
    {
      columnName: 'Kapazität Pufferspeicher',
      formula: (dependencies) => {
        const neuePSKap = istPSKap;
        return neuePSKap;
      },
      dependencies: [],
    },
    {
      columnName: 'Kapazität Brauchwarmwasser',
      formula: (dependencies) => {
        const neueBWWKap = istBWWKap;
        return neueBWWKap;
      },
      dependencies: [],
    },
    {
      columnName: 'Kapazität Batteriespeicher',
      formula: (dependencies) => {
        const neueBattKap = istBattKap;
        return neueBattKap;
      },
      dependencies: [],
    },
    {
      columnName: 'Kapazität E-Fahrzeug',
      formula: (dependencies) => {
        const neueEVKap = istEVKap;
        return neueEVKap;
      },
      dependencies: [],
    },
    {
      columnName: 'Ladung E-Fahrzeug extern',
      formula: (dependencies) => {
        const verbrauchEV = parseFloat(dependencies['Verbrauch E-Mobilität']);
        const ladungExtern = istEVKap <= 0 ? verbrauchEV : 0;
        return ladungExtern;
      },
      dependencies: ['Verbrauch E-Mobilität'],
    },

    // --Energieverluste---------------------------------------------------------------- //
    {
      columnName: 'Wärmeverlust Gebäude',
      formula: (dependencies) => {
        const aussenTemp = parseFloat(dependencies['Aussentemperatur']);
        const istRaumTemp = (parseFloat(dependencies['Kapazität Gebäude']) / speicherKapGeb) - 273;
        const waermeVerlust = (istRaumTemp - aussenTemp) * speicherVerGeb;
        //console.log('Aussentemp:',aussenTemp);
        //console.log('Verlust:',waermeVerlust);
        return waermeVerlust;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Gebäude'], 
    },
    {
      columnName: 'Wärmeverlust Pufferspeicher',
      formula: (dependencies) => {
        const RaumTemp = (parseFloat(dependencies['Kapazität Gebäude']) / speicherKapGeb) - 273;
        const istPSTemp = (parseFloat(dependencies['Kapazität Pufferspeicher']) / speicherKapPS) - 273;
        const waermeVerlust = ((istPSTemp - RaumTemp) * speicherVerPS);
        //console.log('VerlustPS:', waermeVerlust)
        return waermeVerlust;
      },
      dependencies: ['Kapazität Gebäude', 'Kapazität Pufferspeicher'],
    },
    {
      columnName: 'Wärmeverlust Brauchwarmwasser',
      formula: (dependencies) => {
        const RaumTemp = (parseFloat(dependencies['Kapazität Gebäude']) / speicherKapGeb) - 273;
        const istBWWTemp = (parseFloat(dependencies['Kapazität Brauchwarmwasser']) / speicherKapBWW) - 273;
        const waermeVerlust = (istBWWTemp - RaumTemp) * speicherVerBWW;
        return waermeVerlust;
      },
      dependencies: ['Kapazität Gebäude', 'Kapazität Brauchwarmwasser'],
    },

    // --Energiebedarf---------------------------------------------------------------- //
    {
      columnName: 'Wärmebedarf Gebäude',
      formula: (dependencies) => {
        const speicherDiff = ((speicherKapGeb * (minRaumTemp + 273)) - parseFloat(dependencies['Kapazität Gebäude']));
        const waermeVerGeb = parseFloat(dependencies['Wärmeverlust Gebäude']);
        const waermeEintraege = parseFloat(dependencies['Wärmeeintrag']);
        const waermeVerPS = parseFloat(dependencies['Wärmeverlust Pufferspeicher']);
        const waermeVerBWW = parseFloat(dependencies['Wärmeverlust Brauchwarmwasser']);
        const waermeBedarf = speicherDiff + waermeVerGeb - waermeEintraege - waermeVerPS - waermeVerBWW;
        return waermeBedarf > 0 ? waermeBedarf : 0;
      },
      dependencies: ['Kapazität Gebäude', 'Wärmeverlust Gebäude', 'Wärmeeintrag', 'Wärmeverlust Pufferspeicher', 'Wärmeverlust Brauchwarmwasser'],
    },
    {
      columnName: 'Kältebedarf Gebäude',
      formula: (dependencies) => {
        const speicherDiff = ((speicherKapGeb * (maxRaumTemp + 273)) - parseFloat(dependencies['Kapazität Gebäude']));
        const waermeVerGeb = parseFloat(dependencies['Wärmeverlust Gebäude']);
        const waermeEintraege = parseFloat(dependencies['Wärmeeintrag']);
        const waermeVerPS = parseFloat(dependencies['Wärmeverlust Pufferspeicher']);
        const waermeVerBWW = parseFloat(dependencies['Wärmeverlust Brauchwarmwasser']);
        const waermeBedarf = speicherDiff + waermeVerGeb - waermeEintraege - waermeVerPS - waermeVerBWW;
        return -waermeBedarf > 0 ? -waermeBedarf : 0;
      },
      dependencies: ['Kapazität Gebäude', 'Wärmeverlust Gebäude', 'Wärmeeintrag', 'Wärmeverlust Pufferspeicher', 'Wärmeverlust Brauchwarmwasser'],
    },
    {
      columnName: 'Wärmebedarf Pufferspeicher',
      formula: (dependencies) => {
        const speicherDiff = ((speicherKapPS * (minPSTemp + 273)) - parseFloat(dependencies['Kapazität Pufferspeicher']));
        const waermeVerPS = dependencies['Wärmeverlust Pufferspeicher'];
        const waermeBedarfGeb = dependencies['Wärmebedarf Gebäude'];
        const waermeBedarf = speicherDiff + waermeVerPS + (waermeBedarfGeb > 0 ? waermeBedarfGeb : 0);
        //console.log('PS Bedarf:', waermeBedarf);
        return waermeBedarf;
      },
      dependencies: ['Kapazität Pufferspeicher', 'Wärmeverlust Pufferspeicher', 'Wärmebedarf Gebäude', 'Mittlere Aussentemperatur'],
    },
    {
      columnName: 'Wärmebedarf Brauchwarmwasser',
      formula: (dependencies) => {
        const speicherDiff = ((speicherKapBWW * (minBWWTemp + 273)) - parseFloat(dependencies['Kapazität Brauchwarmwasser']));
        const istBWWTemp = (istBWWKap / speicherKapBWW) - 273;
        const waermeVerBWW = dependencies['Wärmeverlust Brauchwarmwasser'];
        const wasserVerbrauch = dependencies['Warmwasserverbrauch'] * spezSpeicherKapWasser * (istBWWTemp - 10);
        const waermeBedarf = speicherDiff + waermeVerBWW + wasserVerbrauch;
        return waermeBedarf;
      },
      dependencies: ['Kapazität Brauchwarmwasser', 'Wärmeverlust Brauchwarmwasser', 'Warmwasserverbrauch'],
    },
    // --Speicherversorgung---------------------------------------------------------------- //
    {
      columnName: 'PS Wärme für Gebäude',
      formula: (dependencies) => {
        const waermeVonPS = dependencies['Wärmebedarf Gebäude'];
        const mittlereAussenTemp = dependencies['Mittlere Aussentemperatur'];
        const heizgrenzeErreicht = mittlereAussenTemp < heizgrenze ? 1 : 0;
        return waermeVonPS * heizgrenzeErreicht;
      },
      dependencies: ['Wärmebedarf Gebäude', 'Mittlere Aussentemperatur'],
    },
    {
      columnName: 'WP Wärme für Pufferspeicher',
      formula: (dependencies) => {
        const waermepumpeLeistung = berechneWaermepumpeLeistung(dependencies);
        //console.log('Wärme für PS:', waermepumpeLeistung.waermePS);
        return waermepumpeLeistung.waermePS;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Brauchwarmwasser', 'Kapazität Pufferspeicher', 'Wärmebedarf Brauchwarmwasser','Wärmebedarf Pufferspeicher', 'Kältebedarf Gebäude', 'Minimale Raumtemperatur', 'Mittlere Aussentemperatur', 'Minimale Warmwassertemperatur', 'Kapazität Gebäude'],
    },
    {
      columnName: 'WP Wärme für Brauchwarmwasser',
      formula: (dependencies) => {
        const waermepumpeLeistung = berechneWaermepumpeLeistung(dependencies);
        return waermepumpeLeistung.waermeBWW;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Brauchwarmwasser', 'Kapazität Pufferspeicher', 'Wärmebedarf Brauchwarmwasser','Wärmebedarf Pufferspeicher', 'Kältebedarf Gebäude', 'Minimale Raumtemperatur', 'Mittlere Aussentemperatur', 'Minimale Warmwassertemperatur', 'Kapazität Gebäude'],
    },
    {
      columnName: 'WP Kälte für Raum',
      formula: (dependencies) => {
        const waermepumpeLeistung = berechneWaermepumpeLeistung(dependencies);
        return waermepumpeLeistung.kaelteRaum;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Brauchwarmwasser', 'Kapazität Pufferspeicher', 'Wärmebedarf Brauchwarmwasser','Wärmebedarf Pufferspeicher', 'Kältebedarf Gebäude', 'Minimale Raumtemperatur', 'Mittlere Aussentemperatur', 'Minimale Warmwassertemperatur', 'Kapazität Gebäude'],
    },
    {
      columnName: 'Ladung E-Fahrzeug',
      formula: (dependencies) => {
        const ladung = berechneLadenEV(dependencies);
        return ladung.ladenEV;
      },
      dependencies: ['Hochtarif', 'EV an Ladestation', 'Kapazität E-Fahrzeug', 'Min-Ladestand E-Mobilität', 'Zeit bis Event E-Mobilität'],
    },


    // --Verbraucher Grundversorung---------------------------------------------------------------- //
    {
      columnName: 'Verbrauch fossiler Brennstoffe',
      formula: (dependencies) => {
        const verbrauchFossil = berechneWaermepumpeLeistung(dependencies);
        return verbrauchFossil.verbrauchFossil || 0;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Brauchwarmwasser', 'Kapazität Pufferspeicher', 'Wärmebedarf Brauchwarmwasser','Wärmebedarf Pufferspeicher', 'Kältebedarf Gebäude', 'Minimale Raumtemperatur', 'Mittlere Aussentemperatur', 'Minimale Warmwassertemperatur', 'Kapazität Gebäude'],
    },
    {
      columnName: 'Verbrauch Wärmepumpe',
      formula: (dependencies) => {
        const waermepumpeLeistung = berechneWaermepumpeLeistung(dependencies);
        return waermepumpeLeistung.elektrischeLeistung || 0;
      },
      dependencies: ['Aussentemperatur', 'Kapazität Brauchwarmwasser', 'Kapazität Pufferspeicher', 'Wärmebedarf Brauchwarmwasser','Wärmebedarf Pufferspeicher', 'Kältebedarf Gebäude', 'Minimale Raumtemperatur', 'Mittlere Aussentemperatur', 'Minimale Warmwassertemperatur', 'Kapazität Gebäude'],
    },
    {
      columnName: 'Verbrauch Elektrische Verbaucher',
      formula: (dependencies) => parseFloat(dependencies['Elektrische Verbaucher']),
      dependencies: ['Elektrische Verbaucher'],
    },
    {
      columnName: 'Verbrauch Heizstab',
      formula: (dependencies) => {
        const heizstabLeistung = berechneHeizstabLeistung(dependencies);
        return heizstabLeistung.elektrischeLeistung;
      },
      dependencies: ['Zeit', 'Wärmebedarf Brauchwarmwasser', 'WP Wärme für Brauchwarmwasser', 'Kapazität Brauchwarmwasser', 'Minimale Warmwassertemperatur'],
    },
    {
      columnName: 'Verbrauch LadestationEV',
      formula: (dependencies) => {
        const verbrauchEVL = berechneLadenEV(dependencies);
        return verbrauchEVL.verbrauchEVL;
      },
      dependencies: ['Hochtarif', 'EV an Ladestation', 'Kapazität E-Fahrzeug', 'Min-Ladestand E-Mobilität', 'Zeit bis Event E-Mobilität'],
    },
    {
      columnName: 'Rückspeisung Batterie',
      formula: (dependencies) => {
        const entladenBatt = berechneBatterieLeistung(dependencies);
        return entladenBatt.entladenBatt;
      },
      dependencies: ['PV-Produktion', 'Kapazität Batteriespeicher', 'Verbrauch Wärmepumpe', 'Elektrische Verbaucher', 'Verbrauch Heizstab', 'Verbrauch LadestationEV',],
    },
    {
      columnName: 'Rückspeisung LadestationBatt',
      formula: (dependencies) => {
        const lieferungBattL = berechneBatterieLeistung(dependencies);
        return lieferungBattL.lieferungBattL;
      },
      dependencies: ['PV-Produktion', 'Kapazität Batteriespeicher', 'Verbrauch Wärmepumpe', 'Elektrische Verbaucher', 'Verbrauch Heizstab', 'Verbrauch LadestationEV',],
    },
    {
      columnName: 'Rückspeisung E-Fahrzeug',
      formula: (dependencies) => {
        const entladenEV = berechneEntladenEV(dependencies);
        return entladenEV.entladenEV;
      },
      dependencies: ['EV an Ladestation', 'Kapazität E-Fahrzeug', 'Min-Ladestand E-Mobilität', 'PV-Produktion', 'Elektrische Verbaucher', 'Verbrauch Wärmepumpe', 'Verbrauch Heizstab', 'Rückspeisung LadestationBatt', 'Verbrauch LadestationBatt', 'Verbrauch LadestationEV'],
    },
    {
      columnName: 'Rückspeisung LadestationEV',
      formula: (dependencies) => {
        const batterieLeistung = berechneEntladenEV(dependencies);
        return batterieLeistung.lieferungEVL;
      },
      dependencies: ['EV an Ladestation', 'Kapazität E-Fahrzeug', 'Min-Ladestand E-Mobilität', 'PV-Produktion', 'Elektrische Verbaucher', 'Verbrauch Wärmepumpe', 'Verbrauch Heizstab', 'Rückspeisung LadestationBatt', 'Verbrauch LadestationBatt', 'Verbrauch LadestationEV'],
    },
    {
      columnName: 'Verbrauch Grundversorgung',
      formula: (dependencies) => {
        const verbauchWP = parseFloat(dependencies['Verbrauch Wärmepumpe']);
        const verbrauchEL = parseFloat(dependencies['Verbrauch Elektrische Verbaucher']);
        const verbrauchHS = parseFloat(dependencies['Verbrauch Heizstab']);
        const verbrauchEV = parseFloat(dependencies['Verbrauch LadestationEV']);
        const rueckspeisungBatt = parseFloat(dependencies['Rückspeisung LadestationBatt']);
        const rueckspeisungEV = parseFloat(dependencies['Rückspeisung LadestationEV']);
        const verbrauchGrundversorgung = verbauchWP + verbrauchEL + verbrauchHS + verbrauchEV - rueckspeisungBatt - rueckspeisungEV;
        return verbrauchGrundversorgung;
      },
      dependencies: ['Verbrauch Wärmepumpe', 'Verbrauch Elektrische Verbaucher', 'Verbrauch Heizstab', 'Verbrauch LadestationEV', 'Rückspeisung LadestationBatt', 'Rückspeisung LadestationEV'],
    },

    // --Überschuss-Steuerung---------------------------------------------------------------- //
    
    {
      columnName: 'Überschuss Verbrauch LadestationBatt',
      formula: (dependencies) => {
        const verbrauchBattL = berechneUeberschussVerbrauch(dependencies);
        return verbrauchBattL.verbrauchBattL;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung Battatterie',
      formula: (dependencies) => {
        const ladenBatt = berechneUeberschussVerbrauch(dependencies);
        return ladenBatt.ladenBatt;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Verbrauch LadestationEV',
      formula: (dependencies) => {
        const verbrauchEVL = berechneUeberschussVerbrauch(dependencies);
        return verbrauchEVL.verbrauchEVL;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung E-Fahrzeug',
      formula: (dependencies) => {
        const ladenEV = berechneUeberschussVerbrauch(dependencies);
        return ladenEV.ladenEV;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Verbrauch Wärmepumpe',
      formula: (dependencies) => {
        const verbrauchWP = berechneUeberschussVerbrauch(dependencies);
        return verbrauchWP.verbrauchWP;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung Pufferspeicher',
      formula: (dependencies) => {
        const ladenPuffer = berechneUeberschussVerbrauch(dependencies);
        return ladenPuffer.ladenPuffer;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung Boiler',
      formula: (dependencies) => {
        const ladenBoiler = berechneUeberschussVerbrauch(dependencies);
        return ladenBoiler.ladenBoiler;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung Gebäude',
      formula: (dependencies) => {
        const ladenGebaeude = berechneUeberschussVerbrauch(dependencies);
        return ladenGebaeude.ladenGebaeude;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Kühlen Gebäude',
      formula: (dependencies) => {
        const ladenGebaeude = berechneUeberschussVerbrauch(dependencies);
        return ladenGebaeude.kuehlenGebaeude;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Verbrauch Heizstab',
      formula: (dependencies) => {
        const verbrauchHS = berechneUeberschussVerbrauch(dependencies);
        return verbrauchHS.verbrauchHS;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },
    {
      columnName: 'Überschuss Ladung Warmwasser',
      formula: (dependencies) => {
        const ladenWarmwasser = berechneUeberschussVerbrauch(dependencies);
        return ladenWarmwasser.ladenWarmwasser;
      },
      dependencies: ['PV-Produktion', 'Verbrauch Grundversorgung', 'Mittlere Aussentemperatur','Aussentemperatur', 'Verbrauch Wärmepumpe', 'Kapazität Batteriespeicher', 'Kapazität E-Fahrzeug', 'Verbrauch LadestationEV', 'Ladung E-Fahrzeug', 'EV an Ladestation', 'Kapazität Gebäude', 'Kapazität Pufferspeicher', 'Kapazität Brauchwarmwasser', 'PS Wärme für Gebäude', 'WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab'],
    },

    // --Energiebilanzen und Temperaturen---------------------------------------------------------------- //
    {
      columnName: 'Raumtemperatur',
      formula: (dependencies) => {
        const aktuelleRaumKap = parseFloat(dependencies['Kapazität Gebäude']);
          const waermeVerGeb = parseFloat(dependencies['Wärmeverlust Gebäude']);
          const waermeEintraege = parseFloat(dependencies['Wärmeeintrag']);
          const waermeVerPS = parseFloat(dependencies['Wärmeverlust Pufferspeicher']);
          const waermeVerBWW = parseFloat(dependencies['Wärmeverlust Brauchwarmwasser']);
        const waermeAbgabe = waermeVerGeb - waermeEintraege - waermeVerPS - waermeVerBWW;
        const waermeVonPS = parseFloat(dependencies['PS Wärme für Gebäude']);
        const raumKaelte = parseFloat(dependencies['WP Kälte für Raum']);
        const ueberschussGeb = parseFloat(dependencies['Überschuss Ladung Gebäude']);
        const ueberschussKaelte = parseFloat(dependencies['Überschuss Kühlen Gebäude']);
        const aktualisierteRaumKap = aktuelleRaumKap - waermeAbgabe + waermeVonPS + ueberschussGeb - raumKaelte - ueberschussKaelte;
        const neueRaumTemp = (aktualisierteRaumKap / speicherKapGeb) - 273;
        istRaumKap = aktualisierteRaumKap; // Aktualisiere istRaumKap für nachfolgende Berechnungen
        return neueRaumTemp;
      },
      dependencies: ['Kapazität Gebäude', 'Wärmeverlust Gebäude', 'Wärmeeintrag', 'Wärmeverlust Pufferspeicher', 'Wärmeverlust Brauchwarmwasser', 'PS Wärme für Gebäude', 'Überschuss Ladung Gebäude', 'WP Kälte für Raum', 'Überschuss Kühlen Gebäude'],
    },
    {
      columnName: 'Temperatur Pufferspeicher',
      formula: (dependencies) => {
        const aktuellePSKap = parseFloat(dependencies['Kapazität Pufferspeicher']);
          const waermeVerPS = dependencies['Wärmeverlust Pufferspeicher'];
          const waermeBedarfGeb = dependencies['Wärmebedarf Gebäude'];
          const mittlereAussenTemp = dependencies['Mittlere Aussentemperatur'];
        const waermeAbgabe = waermeVerPS + (waermeBedarfGeb > 0 && mittlereAussenTemp < heizgrenze ? waermeBedarfGeb : 0);
        const waermeVonWP = parseFloat(dependencies['WP Wärme für Pufferspeicher']);
        const ueberschussPS = parseFloat(dependencies['Überschuss Ladung Pufferspeicher']);
        const aktualisiertePSKap = aktuellePSKap - waermeAbgabe + waermeVonWP + ueberschussPS;
        const neuePSTemp = (aktualisiertePSKap / speicherKapPS) - 273;
        //console.log('PS Abgabe:', waermeAbgabe);
        //console.log('PS Temp:', neuePSTemp);
        istPSKap = aktualisiertePSKap;

        return neuePSTemp;
      },
      dependencies: ['Kapazität Pufferspeicher', 'Wärmeverlust Pufferspeicher', 'Wärmebedarf Gebäude', 'Mittlere Aussentemperatur', 'WP Wärme für Pufferspeicher', 'Überschuss Ladung Pufferspeicher'],
    },
    {
      columnName: 'Temperatur Brauchwarmwasser',
      formula: (dependencies) => {
        const aktuelleBWWKap = parseFloat(dependencies['Kapazität Brauchwarmwasser']);
        const waermeAbgabe = parseFloat(dependencies['Wärmebedarf Brauchwarmwasser']) - ((speicherKapBWW * (minBWWTemp + 273)) - aktuelleBWWKap);
        const waermeVonWP = parseFloat(dependencies['WP Wärme für Brauchwarmwasser']);
        const waermeVonHS = parseFloat(dependencies['Verbrauch Heizstab']);
        const ueberschussWP = parseFloat(dependencies['Überschuss Ladung Boiler']);
        const ueberschussHS = parseFloat(dependencies['Überschuss Ladung Warmwasser']);
        const aktualisierteBWWKap = aktuelleBWWKap - waermeAbgabe + waermeVonWP + waermeVonHS + ueberschussWP + ueberschussHS;
        const neueBWWTemp = (aktualisierteBWWKap / speicherKapBWW) - 273;
        istBWWKap = aktualisierteBWWKap;
        return neueBWWTemp;
        
      },
      dependencies: ['Kapazität Brauchwarmwasser', 'Wärmebedarf Brauchwarmwasser', 'WP Wärme für Brauchwarmwasser', 'Verbrauch Heizstab', 'Überschuss Ladung Boiler', 'Überschuss Ladung Warmwasser' ],
    },
    {
      columnName: 'SOC Batteriespeicher',
      formula: (dependencies) => {
        const aktuelleBattKap = parseFloat(dependencies['Kapazität Batteriespeicher']);
        const battEntladen = parseFloat(dependencies['Rückspeisung Batterie']);
        const ueberschussLaden = parseFloat(dependencies['Überschuss Ladung Battatterie']);
        const aktualisierteBattKap = aktuelleBattKap - battEntladen + ueberschussLaden;
        const SOC = (aktualisierteBattKap / speicherKapBatt);
        istBattKap = aktualisierteBattKap;
        return SOC > 1 ? 1 : SOC;
        
      },
      dependencies: ['Kapazität Batteriespeicher', 'Rückspeisung Batterie', 'Überschuss Ladung Battatterie'],
    },
    {
      columnName: 'SOC E-Fahrzeug',
      formula: (dependencies) => {
        const aktuelleEVKap = parseFloat(dependencies['Kapazität E-Fahrzeug']);
        const EVEntladen = parseFloat(dependencies['Rückspeisung E-Fahrzeug']);
        const verbrauch = parseFloat(dependencies['Verbrauch E-Mobilität']);
        const EVLaden = parseFloat(dependencies['Ladung E-Fahrzeug']);
        const ueberschussLaden = parseFloat(dependencies['Überschuss Ladung E-Fahrzeug']);
        const EVLadenExtern = parseFloat(dependencies['Ladung E-Fahrzeug extern']);
        const aktualisierteEVKap = aktuelleEVKap - EVEntladen - verbrauch + EVLaden + ueberschussLaden + EVLadenExtern;
        const SOC = (aktualisierteEVKap / speicherKapEV);
        istEVKap = aktualisierteEVKap;
        //console.log(verbrauch);
        return SOC > 1 ? 1 : SOC;
        
      },
      dependencies: ['Kapazität E-Fahrzeug', 'Ladung E-Fahrzeug', 'Rückspeisung E-Fahrzeug', 'Verbrauch E-Mobilität', 'Überschuss Ladung E-Fahrzeug', 'Ladung E-Fahrzeug extern'],
    },

    // --Auswertung---------------------------------------------------------------- //
    {
      columnName: 'Wärmelast Gebäude',
      formula: (dependencies) => {
        const waermeVerGeb = parseFloat(dependencies['Wärmeverlust Gebäude']);
        const waermeEintraege = parseFloat(dependencies['Wärmeeintrag']);
        const waermeVerPS = parseFloat(dependencies['Wärmeverlust Pufferspeicher']) * 0;
        const waermeVerBWW = parseFloat(dependencies['Wärmeverlust Brauchwarmwasser'] * 0);
        const waermeBedarf = waermeVerGeb - waermeEintraege - waermeVerPS - waermeVerBWW;
        return waermeBedarf;
      },
      dependencies: ['Wärmeverlust Gebäude', 'Wärmeeintrag', 'Wärmeverlust Pufferspeicher', 'Wärmeverlust Brauchwarmwasser'],
    },
    {
      columnName: 'Gesamtladung E-Fahrzeug',
      formula: (dependencies) => {
        const grundVersorgungEV = parseFloat(dependencies['Ladung E-Fahrzeug']);
        const ueberschussEV = parseFloat(dependencies['Überschuss Ladung E-Fahrzeug']);
        const gesamtverbrauchEV = grundVersorgungEV + ueberschussEV;
        return gesamtverbrauchEV;
      },
      dependencies: ['Ladung E-Fahrzeug', 'Überschuss Ladung E-Fahrzeug'],
    },
    {
      columnName: 'Gesamtverbrauch LadestationEV',
      formula: (dependencies) => {
        const grundVersorgungEV = parseFloat(dependencies['Verbrauch LadestationEV']);
        const ueberschussEV = parseFloat(dependencies['Überschuss Verbrauch LadestationEV']);
        const gesamtverbrauchEV = grundVersorgungEV + ueberschussEV;
        return gesamtverbrauchEV;
      },
      dependencies: ['Verbrauch LadestationEV', 'Überschuss Verbrauch LadestationEV'],
    },
    {
      columnName: 'Gesamtverbrauch Heizstab',
      formula: (dependencies) => {
        const grundVersorgungHS = parseFloat(dependencies['Verbrauch Heizstab']);
        const ueberschussHS = parseFloat(dependencies['Überschuss Verbrauch Heizstab']);
        const gesamtverbrauchHS = grundVersorgungHS + ueberschussHS;
        return gesamtverbrauchHS;
      },
      dependencies: ['Verbrauch Heizstab', 'Überschuss Verbrauch Heizstab'],
    },
    {
      columnName: 'Gesamtverbrauch Wärmepumpe',
      formula: (dependencies) => {
        const grundVersorgungWP = parseFloat(dependencies['Verbrauch Wärmepumpe']);
        const ueberschussWP = parseFloat(dependencies['Überschuss Verbrauch Wärmepumpe']);
        const gesamtverbrauchWP = grundVersorgungWP + ueberschussWP;
        return gesamtverbrauchWP;
      },
      dependencies: ['Verbrauch Wärmepumpe', 'Überschuss Verbrauch Wärmepumpe'],
    },
    {
      columnName: 'Wärmeerzeugung Wärmepumpe',
      formula: (dependencies) => {
        const grundVersorgungPS = WPHeizung ? parseFloat(dependencies['WP Wärme für Pufferspeicher']) : 0;
        const grundVersorgungBWW = parseFloat(dependencies['WP Wärme für Brauchwarmwasser']);
        const ueberschussPS = parseFloat(dependencies['Überschuss Ladung Pufferspeicher']);
        const ueberschussBWW = parseFloat(dependencies['Überschuss Ladung Boiler']);
        const ueberschussGeb = parseFloat(dependencies['Überschuss Ladung Gebäude']);
        const raumKaelte = parseFloat(dependencies['WP Kälte für Raum']);
        const ueberschussKaelte = parseFloat(dependencies['Überschuss Kühlen Gebäude']);
        const gesamtwaermeWP = grundVersorgungPS + grundVersorgungBWW + ueberschussPS + ueberschussBWW + ueberschussGeb + raumKaelte + ueberschussKaelte;
        return gesamtwaermeWP;
      },
      dependencies: ['WP Wärme für Pufferspeicher', 'WP Wärme für Brauchwarmwasser', 'Überschuss Ladung Pufferspeicher', 'Überschuss Ladung Boiler', 'Überschuss Ladung Gebäude', 'WP Kälte für Raum', 'Überschuss Kühlen Gebäude'],
    },
    {
      columnName: 'Raumwärme Wärmepumpe',
      formula: (dependencies) => {
        const grundVersorgungPS = parseFloat(dependencies['WP Wärme für Pufferspeicher']);
        const ueberschussPS = parseFloat(dependencies['Überschuss Ladung Pufferspeicher']);
        const ueberschussGeb = parseFloat(dependencies['Überschuss Ladung Gebäude']);
        const waermeRaumWP = grundVersorgungPS + ueberschussPS +  ueberschussGeb;
        return WPHeizung ? waermeRaumWP : 0;
      },
      dependencies: ['WP Wärme für Pufferspeicher', 'Überschuss Ladung Pufferspeicher', 'Überschuss Ladung Gebäude'],
    },
    {
      columnName: 'Brauchwasser Wärme Wärmepumpe',
      formula: (dependencies) => {
        const grundVersorgungBWW = parseFloat(dependencies['WP Wärme für Brauchwarmwasser']);
        const ueberschussBWW = parseFloat(dependencies['Überschuss Ladung Boiler']);
        const waermeWasserWP = grundVersorgungBWW + ueberschussBWW;
        return WPHeizung || BWWvonWP ? waermeWasserWP : 0;
      },
      dependencies: ['WP Wärme für Brauchwarmwasser', 'Überschuss Ladung Boiler'],
    },
    {
      columnName: 'Raumkälte Wärmepumpe',
      formula: (dependencies) => {
        const raumKaelte = parseFloat(dependencies['WP Kälte für Raum']);
        const ueberschussKaelte = parseFloat(dependencies['Überschuss Kühlen Gebäude']);
        const kaelteRaum = raumKaelte + ueberschussKaelte;
        return kaelteRaum;
      },
      dependencies: ['WP Kälte für Raum', 'Überschuss Kühlen Gebäude'],
    },
    {
      columnName: 'Gesamtverbrauch',
      formula: (dependencies) => {
        const grundVersorgung = parseFloat(dependencies['Verbrauch Grundversorgung']);
        const ueberschussBatt = parseFloat(dependencies['Überschuss Verbrauch LadestationBatt']);
        const ueberschussEV = parseFloat(dependencies['Überschuss Verbrauch LadestationEV']);
        const ueberschussWp = parseFloat(dependencies['Überschuss Verbrauch Wärmepumpe']);
        const ueberschussHS = parseFloat(dependencies['Überschuss Verbrauch Heizstab']);
        const gesamtverbrauch = grundVersorgung + ueberschussBatt + ueberschussEV + ueberschussWp + ueberschussHS;
        return gesamtverbrauch;
      },
      dependencies: ['Verbrauch Grundversorgung', 'Überschuss Verbrauch LadestationBatt', 'Überschuss Verbrauch LadestationEV', 'Überschuss Verbrauch Wärmepumpe', 'Überschuss Verbrauch Heizstab'],
    },
    {
      columnName: 'Einspeisung',
      formula: (dependencies) => {
        const produktion = parseFloat(dependencies['PV-Produktion']); 
        const verbrauch = parseFloat(dependencies['Gesamtverbrauch']);
        const differenz = produktion - verbrauch;
        return differenz > 0 ? differenz : 0;
        },
      dependencies: ['PV-Produktion', 'Gesamtverbrauch'],
    },
    {
      columnName: 'Netzbezug',
      formula: (dependencies) => {
        const produktion = parseFloat(dependencies['PV-Produktion']); 
        const verbrauch = parseFloat(dependencies['Gesamtverbrauch']);
        const differenz = verbrauch - produktion;
        return differenz > 0 ? differenz : 0;
        },
      dependencies: ['PV-Produktion', 'Gesamtverbrauch'],
    },
    {
      columnName: 'Stromkosten',
      formula: (dependencies) => {
        const strompreis = parseFloat(dependencies['Strompreis']); 
        const vergütung = stromnetzInfo.einspeiseverguetung;
        const netzbezug = parseFloat(dependencies['Netzbezug']);
        const einspeisung = parseFloat(dependencies['Einspeisung']);
        const stromkosten = (strompreis * netzbezug) - (vergütung * einspeisung);
        return stromkosten;
        },
      dependencies: ['Strompreis', 'Netzbezug', 'Einspeisung'],
    },
    
  ];

  // --Berechnungen abgeschlossen---------------------------------------------------------------- //
  // --Berechnungen abgeschlossen---------------------------------------------------------------- //


  // Neue useState-Hooks zum Steuern des Speichern-Buttons
  const [isCalculating, setIsCalculating] = useState(false);

  const handleSaveAndCalculate = async () => {
    setIsCalculating(true);
    await performCalculations();
    setIsCalculating(false);
  };

  const performCalculations = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (parsedCsv.length > 0) {
          const headerRow = parsedCsv[0];
          const newHeaderRow = { ...headerRow };
          const dataRows = parsedCsv.slice(1);
  
          const csvBerechnet = dataRows.map((row) => {
            const newRow = { ...row };
          
            calculations.forEach(({ columnName, formula, dependencies }) => {
              const rowDependencies = dependencies.reduce((acc, dep) => ({ ...acc, [dep]: newRow[dep] }), {});
              if (!headerRow.hasOwnProperty(columnName)) {
                newHeaderRow[columnName] = columnName;
              }
              newRow[columnName] = formula(rowDependencies);
            });
            
            return newRow;
          });
  
          setCsvBerechnet([newHeaderRow, ...csvBerechnet]);
        }
        resolve();
      }, 100); // Verzögerung von 100 Millisekunden (0.1 Sekunden)
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <LoadingButton
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: '14px',
          fontWeight: 'bold',
          padding: '10px 20px',
          margin: '10px',
        }}
        loading={isCalculating}
        onClick={handleSaveAndCalculate}
        variant='outlined'
      >
        Speichern und Berechnen
      </LoadingButton>
      {isCalculating && <p>Simulation läuft...</p>}
    </Box>
);
}

export default Rechner;